import types from '../../mutation-types';
import Vue from 'vue';

export const mutations = {
  [types.SET_SUBCRIPTION_INFO_PLAN](state, data) {
    Vue.set(state, 'plan', data.plan_subscription_info);
  },

  [types.SET_SUBCRIPTION_INFO](state, data) {
    Vue.set(state, 'info', data);
  },

  [types.SET_BILLING_INFO](state, data) {
    Vue.set(state, 'billingInfo', data);
  },

  [types.SET_SUBCRIPTION_UI_FLAG](state, data) {
    Vue.set(state, 'uiFlags', data);
  },
};
