<template>
  <div class="comments-container">
    <div class="comments-list-wrapper">
      <comment-list
        v-for="comment in comments"
        :key="comment.id"
        :data="comment"
        @get-comment="getComment"
      />
    </div>
    <template v-if="commentId">
      <div class="comments-content">
        {{ `${$t('INBOX_MGMT.PRIVATE_REPLY.REPLY_TO')} "${content}"` }}
      </div>
      <comment-reply class="comments-footer" :in-reply-to="commentId" />
    </template>
  </div>
</template>

<script>
import CommentList from './CommentList';
import CommentReply from './CommentReply';
import ConversationApi from 'dashboard/api/inbox/conversation';

export default {
  components: {
    CommentList,
    CommentReply,
  },
  props: {
    conversationId: {
      type: [Number],
      required: true,
    },
  },
  data() {
    return {
      commentId: null,
      content: null,
      comments: [],
      isLoading: false,
      beforeId: null,
    };
  },
  mounted() {
    const listElm = this.$el.querySelector('.comments-list-wrapper');
    if (!this.isLoading) {
      listElm.addEventListener('scroll', () => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          this.fetchComments(this.conversationId, this.beforeId);
        }
      });
      this.fetchComments(this.conversationId, this.beforeId);
    }
  },
  methods: {
    async fetchComments(conversationId, beforeId) {
      this.isLoading = true;
      const {
        data: { payload },
      } = await ConversationApi.getCommentsConversation(
        conversationId,
        beforeId,
      );
      if (payload.length > 0) {
        this.comments = [...this.comments, ...payload];
        const [lastItem] = payload.slice(-1);
        this.beforeId = lastItem.id;
      }
      this.isLoading = false;
    },
    getComment({ id, content }) {
      this.commentId = id;
      this.content = content;
    },
  },
};
</script>

<style scoped lang="scss">
.comments {
  &-container {
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 145px);
    padding-bottom: 0;
    overflow-y: unset;
  }

  &-list {
    &-wrapper {
      display: flex;
      flex: 1 1;
      margin: 0;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      padding: 1.5rem;
      position: relative;
    }
  }

  &-content {
    background: var(--b-100);
    padding: 10px;
    font-size: 1.3rem;
    border-top: 1px solid var(--color-border);
  }

  &-footer {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>
