/* eslint arrow-body-style: ["error", "always"] */

const endPoints = {
  resetPassword: {
    url: 'auth/password',
  },
  register: {
    url: 'api/v1/accounts/onboarding_signups',
  },
  validityCheck: {
    url: 'auth/validate_token',
  },
  profileUpdate: {
    url: 'api/v1/profile',
  },
  logout: {
    url: 'auth/sign_out',
  },

  me: {
    url: 'api/v1/conversations.json',
    params: { type: 0, page: 1 },
  },

  getInbox: {
    url: 'api/v1/conversations.json',
    params: { inbox_id: null },
  },

  fetchFacebookPages: {
    url(accountId) {
      return `api/v1/accounts/${accountId}/callbacks/facebook_pages.json`;
    },
    params: { omniauth_token: '' },
  },

  fetchInstagramPages: {
    url(accountId) {
      return `api/v1/accounts/${accountId}/callbacks/instagram_pages.json`;
    },
    params: { omniauth_token: '' },
  },

  onboarding: {
    url: 'api/v1/accounts/onboarding_signups/complete_onboarding',
  },

  onboardingQuestion: {
    url: 'api/v1/accounts/onboarding_questions',
    params: { question_type: '' },
  },

  onboardingCreateHubspot: {
    url: 'api/v1/profile/create_hubspot_contact',
    params: { email: '' },
  },

  onboardingUpdateHubspot: {
    url: '/api/v1/profile/update_hubspot_contact',
    params: { id: '', properties: {} },
  },

  checkInstagramEligibility: {
    url(accountId) {
      return `api/v1/accounts/${accountId}/callbacks/check_instagram_eligibility`;
    },
    params: { omniauth_token: '' },
  },

  sendEmailConfirmation: {
    url: 'api/v1/profile/resend_confirmation_email',
  },

  setInstagramUsername: {
    url(accountId, conversationId) {
      return `api/v1/accounts/${accountId}/contacts/set_instagram_username?conversation_id=${conversationId}&account_id=${accountId}`;
    },
  },

  setWhatsappNumber: {
    url(accountId, conversationId) {
      return `api/v1/accounts/${accountId}/contacts/set_whatsapp_number?conversation_id=${conversationId}&account_id=${accountId}`;
    },
  },

  getAgentBot: {
    url(accountId) {
      return `api/v1/accounts/${accountId}/agent_bots`;
    },
    params: { per_page: '', page: '' },
  },

  createAgentBot: {
    url(accountId) {
      return `api/v1/accounts/${accountId}/agent_bots`;
    },
  },

  updateAgentBot: {
    url(accountId, agentBotId) {
      return `api/v1/accounts/${accountId}/agent_bots/${agentBotId}`;
    },
  },

  agentBotVerifyWebhook: {
    url(accountId) {
      return `api/v1/accounts/${accountId}/agent_bots/verify_webhook_url`;
    },
  },

  setAgentBotInboxes: {
    url(accountId, agentBotId) {
      return `api/v1/accounts/${accountId}/agent_bots/${agentBotId}/set_inboxes`;
    },
  },

  setAgentBotStatus: {
    url(accountId, agentBotId) {
      return `api/v1/accounts/${accountId}/agent_bots/${agentBotId}/set_status`;
    },
  },

  viewAgentBot: {
    url(accountId, agentBotId) {
      return `api/v1/accounts/${accountId}/agent_bots/${agentBotId}`;
    },
  },

  getAgentBotInboxes: {
    url(accountId) {
      return `api/v1/accounts/${accountId}/inboxes/assignable_to_agent_bot`;
    },
  },
};

export default page => {
  return endPoints[page];
};
