<template>
  <div class="integrations-inboxes">
    <agent-bot-inboxes-item :inboxes="computedInboxes" />
    <div
      v-if="computedInboxesMore"
      v-click-outside="hideMoreInboxes"
      class="integrations-inboxes__list cursor-pointer"
      @mouseenter="handleOpenInboxes(agentBotId)"
      @mouseleave="hideMoreInboxes"
    >
      {{ computedInboxesMoreLength }}
    </div>
    <div
      v-if="agentBotId === isInboxesOpen"
      class="integrations-inboxes__list-more"
    >
      <agent-bot-inboxes-item :inboxes="computedInboxesMore" />
    </div>
  </div>
</template>

<script>
import AgentBotInboxesItem from './AgentBotInboxesItem';

export default {
  components: {
    AgentBotInboxesItem,
  },
  props: {
    agentBotId: {
      type: Number,
      default: null,
      requred: true,
    },
    inboxes: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      isInboxesOpen: null,
    };
  },
  computed: {
    computedInboxes() {
      return this.inboxes.length > 2 ? this.inboxes.slice(0, 2) : this.inboxes;
    },
    computedInboxesMore() {
      return this.inboxes.length > 2 && this.inboxes.slice(2);
    },
    computedInboxesMoreLength() {
      return this.computedInboxesMore && `+${this.computedInboxesMore.length}`;
    },
  },
  methods: {
    handleOpenInboxes(inboxId) {
      this.$data.isInboxesOpen = inboxId;
    },
    hideMoreInboxes() {
      this.$data.isInboxesOpen = null;
    },
  },
};
</script>

<style lang="scss">
.integrations {
  &-inboxes {
    display: flex;
    align-items: center;
    position: relative;
    &__list {
      background: var(--greylight-04);
      margin: 4px;
      border-radius: 30px;
      padding: 5px 10px;
      color: var(--greydark-02);
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 50ch;
      &-more {
        position: absolute;
        top: calc(100% + 12px);
        background: var(--white);
        box-shadow: 0px 2px 4px rgba(137, 148, 159, 0.32);
        border-radius: 4px;
        padding: 12px 8px;
        left: 50%;
        transform: translate(-50%, 0);
        .integrations-inboxes {
          flex-wrap: wrap;
          min-width: 300px;
          justify-content: center;
        }
      }
      span {
        display: inline-block;
        margin-left: 6px;
      }
    }
  }
}
</style>
