<template>
  <form class="column align-self-top" @submit.prevent="submitInboxName()">
    <div class="column form-input-wrapper">
      <woot-input
        v-model.trim="credentials.inbox_name"
        type="text"
        :class="{ error: $v.credentials.inbox_name.$error }"
        :label="$t('NEW_CHANNEL.WHATSAPP.FORM.INBOX_NAME.LABEL')"
        :placeholder="$t('NEW_CHANNEL.WHATSAPP.FORM.INBOX_NAME.PLACEHOLDER')"
        :error="
          $v.credentials.inbox_name.$error && credentials.inbox_name === ''
            ? $t('NEW_CHANNEL.WHATSAPP.FORM.INBOX_NAME.ERROR_REQUIRED')
            : $v.credentials.inbox_name.$error &&
              credentials.inbox_name.length > 50
            ? $t('NEW_CHANNEL.WHATSAPP.FORM.INBOX_NAME.ERROR_MAXLENGTH')
            : ''
        "
        @blur="$v.credentials.inbox_name.$touch"
      />
      <submit-button
        :disabled="$v.$invalid"
        :button-text="$t('NEW_CHANNEL.WHATSAPP.FORM.SUBMIT')"
        button-class="expanded"
        @click="fireGTM()"
      >
      </submit-button>
      <span v-html="$t('GETTING_STARTED.CHANNEL.WHATSAPP.DONT_HAVE_ACCOUNT')" />
    </div>
  </form>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';

import SubmitButton from 'dashboard/components/buttons/FormSubmitButton';

export default {
  components: {
    SubmitButton,
  },
  data() {
    return {
      credentials: {
        inbox_name: '',
      },
    };
  },
  mounted() {
    this.$customGTMStep('WABA-Connect', 'WABA-name', 1);
  },
  validations: {
    credentials: {
      inbox_name: {
        required,
        maxLength: maxLength(50),
      },
    },
  },
  methods: {
    async submitInboxName() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit('on-change-inbox', { ...this.$data.credentials });
    },
    fireGTM() {
      this.$customGTMStep('WABA-Connect', 'APIcredential', 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  .message {
    margin: 0;
    margin-top: 5px;
  }
}

span {
  margin-top: 10px;
  color: var(--grey-07);
  font-size: 14px;
  line-height: 24px;
  display: block;
  flex: 1;
  text-align: right;
}
</style>
