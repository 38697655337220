<template>
  <div class="reply-box" :class="replyBoxClass">
    <div class="reply-box__top">
      <canned-response
        v-if="showMentions && hasSlashCommand"
        v-on-clickaway="hideMentions"
        :search-key="mentionSearchKey"
        @click="replaceText"
      />
      <emoji-input
        v-if="showEmojiPicker"
        v-on-clickaway="hideEmojiPicker"
        :on-click="emojiOnClick"
      />
      <resizable-text-area
        v-if="!showRichContentEditor"
        ref="messageInput"
        v-model="message"
        class="input"
        :placeholder="messagePlaceHolder"
        :min-height="4"
        @typing-off="onTypingOff"
        @typing-on="onTypingOn"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>
    <reply-bottom-panel
      :mode="replyType"
      :send-button-text="replyButtonLabel"
      :toggle-emoji-picker="toggleEmojiPicker"
      :show-emoji-picker="showEmojiPicker"
      :on-send="sendComment"
      :is-send-disabled="isReplyButtonDisabled"
      :set-format-mode="setFormatMode"
      :is-on-private-note="isOnPrivateNote"
      :is-format-mode="showRichContentEditor"
      :enable-rich-editor="isRichEditorEnabled"
      :enter-to-send-enabled="enterToSendEnabled"
      @toggleEnterToSend="toggleEnterToSend"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';

import EmojiInput from 'shared/components/emoji/EmojiInput';
import CannedResponse from '../CannedResponse';
import ResizableTextArea from 'shared/components/ResizableTextArea';
import ReplyBottomPanel from 'dashboard/components/widgets/WootWriter/ReplyBottomPanel';
import { REPLY_EDITOR_MODES } from 'dashboard/components/widgets/WootWriter/constants';
import {
  isEscape,
  isEnter,
  hasPressedShift,
} from 'shared/helpers/KeyboardHelpers';
import { MESSAGE_MAX_LENGTH } from 'shared/helpers/MessageTypeHelper';
import inboxMixin from 'shared/mixins/inboxMixin';
import alertMixin from 'shared/mixins/alertMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  components: {
    EmojiInput,
    CannedResponse,
    ResizableTextArea,
    ReplyBottomPanel,
  },
  mixins: [clickaway, inboxMixin, uiSettingsMixin, alertMixin],
  props: {
    inReplyTo: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      message: '',
      isFocused: false,
      showEmojiPicker: false,
      showMentions: false,
      replyType: REPLY_EDITOR_MODES.REPLY,
      mentionSearchKey: '',
      hasUserMention: false,
      hasSlashCommand: false,
    };
  },
  computed: {
    ...mapGetters({ currentChat: 'getSelectedChat' }),
    showRichContentEditor() {
      const {
        display_rich_content_editor: displayRichContentEditor,
      } = this.uiSettings;
      return this.isOnPrivateNote || displayRichContentEditor;
    },
    enterToSendEnabled() {
      return !!this.uiSettings.enter_to_send_enabled;
    },
    isPrivate() {
      if (this.currentChat.can_reply) {
        return this.isOnPrivateNote;
      }
      return true;
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    messagePlaceHolder() {
      return this.isPrivate
        ? this.$t('CONVERSATION.FOOTER.PRIVATE_MSG_INPUT')
        : this.$t('CONVERSATION.FOOTER.MSG_INPUT');
    },
    isMessageLengthReachingThreshold() {
      return this.message.length > this.maxLength - 50;
    },
    charactersRemaining() {
      return this.maxLength - this.message.length;
    },
    isReplyButtonDisabled() {
      const isMessageEmpty = !this.message.trim().replace(/\n/g, '').length;
      return (
        isMessageEmpty ||
        this.message.length === 0 ||
        this.message.length > this.maxLength
      );
    },
    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },
    maxLength() {
      if (this.isPrivate) {
        return MESSAGE_MAX_LENGTH.GENERAL;
      }

      if (this.isAFacebookInbox) {
        return MESSAGE_MAX_LENGTH.FACEBOOK;
      }
      if (this.isATwilioSMSChannel) {
        return MESSAGE_MAX_LENGTH.TWILIO_SMS;
      }
      if (this.isATwitterInbox) {
        if (this.conversationType === 'tweet') {
          return MESSAGE_MAX_LENGTH.TWEET;
        }
      }

      if (this.isAnInstagramInbox) {
        return MESSAGE_MAX_LENGTH.INSTAGRAM;
      }

      return MESSAGE_MAX_LENGTH.GENERAL;
    },
    replyButtonLabel() {
      if (this.isPrivate) {
        return this.$t('CONVERSATION.REPLYBOX.CREATE');
      }
      if (this.conversationType === 'tweet') {
        return this.$t('CONVERSATION.REPLYBOX.TWEET');
      }
      return this.$t('CONVERSATION.REPLYBOX.SEND');
    },
    replyBoxClass() {
      return {
        'is-private': this.isPrivate,
        'is-focused': this.isFocused,
      };
    },
    isRichEditorEnabled() {
      return (
        this.isAWebWidgetInbox || this.isAnEmailChannel || this.isOnPrivateNote
      );
    },
    isOnPrivateNote() {
      return this.replyType === REPLY_EDITOR_MODES.NOTE;
    },
  },
  watch: {
    currentChat(conversation) {
      const { can_reply: canReply } = conversation;
      if (this.isOnPrivateNote) {
        return;
      }

      if (canReply) {
        this.replyType = REPLY_EDITOR_MODES.REPLY;
      } else {
        this.replyType = REPLY_EDITOR_MODES.NOTE;
      }
    },
    message(updatedMessage) {
      this.hasSlashCommand = updatedMessage[0] === '/';
      const hasNextWord = updatedMessage.includes(' ');
      const isShortCodeActive = this.hasSlashCommand && !hasNextWord;
      if (isShortCodeActive) {
        this.mentionSearchKey = updatedMessage.substr(1, updatedMessage.length);
        this.showMentions = true;
      } else {
        this.mentionSearchKey = '';
        this.showMentions = false;
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyEvents);
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
    toggleUserMention(currentMentionState) {
      this.hasUserMention = currentMentionState;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.hideEmojiPicker();
        this.hideMentions();
      } else if (isEnter(e)) {
        const hasSendOnEnterEnabled =
          (this.showRichContentEditor &&
            this.enterToSendEnabled &&
            !this.hasUserMention) ||
          !this.showRichContentEditor;
        const shouldSendComment =
          hasSendOnEnterEnabled && !hasPressedShift(e) && this.isFocused;
        if (shouldSendComment) {
          e.preventDefault();
          this.sendComment();
        }
      }
    },
    toggleEnterToSend(enterToSendEnabled) {
      this.updateUISettings({ enter_to_send_enabled: enterToSendEnabled });
    },
    async sendComment() {
      if (this.isReplyButtonDisabled) {
        return;
      }
      if (!this.showMentions) {
        const newMessage = this.message;
        const messagePayload = this.getMessagePayload(newMessage);
        try {
          await this.$store.dispatch('sendComment', messagePayload);
          this.clearMessage();
          bus.$emit(BUS_EVENTS.CLOSE_COMMENT_MODE);
        } catch (error) {
          this.showAlert(
            this.$t('INBOX_MGMT.INSTAGRAM_SETTINGS.COMMENT_FAILED'),
          );
        }
        this.hideEmojiPicker();
      }
    },
    replaceText(message) {
      setTimeout(() => {
        this.message = message;
      }, 100);
    },
    emojiOnClick(emoji) {
      this.message = `${this.message}${emoji} `;
    },
    clearMessage() {
      this.message = '';
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    hideMentions() {
      this.showMentions = false;
    },
    onTypingOn() {
      this.toggleTyping('on');
    },
    onTypingOff() {
      this.toggleTyping('off');
    },
    onBlur() {
      this.isFocused = false;
    },
    onFocus() {
      this.isFocused = true;
    },
    toggleTyping(status) {
      if (this.isAWebWidgetInbox && !this.isPrivate) {
        const conversationId = this.currentChat.id;
        this.$store.dispatch('conversationTypingStatus/toggleTyping', {
          status,
          conversationId,
        });
      }
    },
    getMessagePayload(message) {
      const messagePayload = {
        conversationId: this.currentChat.id,
        private: this.isPrivate,
        message,
      };

      if (this.inReplyTo) {
        messagePayload.contentAttributes = { in_reply_to: this.inReplyTo };
      }

      return messagePayload;
    },
    setFormatMode(value) {
      this.updateUISettings({ display_rich_content_editor: value });
    },
  },
};
</script>

<style lang="scss" scoped>
.send-button {
  margin-bottom: 0;
}

.reply-box {
  border-top: 1px solid var(--color-border);
  background: white;

  &.is-private {
    background: var(--y-50);
  }
}
.send-button {
  margin-bottom: 0;
}

.reply-box__top {
  padding: 0 var(--space-normal);
  border-top: 1px solid var(--color-border);
  margin-top: -1px;
}

.emoji-dialog {
  top: unset;
  bottom: 12px;
  left: -320px;
  right: unset;

  &::before {
    right: -16px;
    bottom: 10px;
    transform: rotate(270deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  }
}

.banner {
  background: var(--b-500);
  color: var(--white);
  font-size: var(--font-size-mini);
  padding: var(--space-slab) var(--space-normal);
  text-align: center;
  position: relative;
  display: flex;
  align-items: flex-start;

  span {
    padding: 0 25px;
  }

  .banner-close-button {
    cursor: pointer;
    margin-left: var(--space--two);
    color: var(--white);
  }
}
</style>
