<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable vue/html-closing-bracket-newline -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div class="column content-box">
    <woot-modal-header
      v-show="type === 'add'"
      :header-title="$t('QUICK_REPLY.TITLE')"
    />
    <form
      class="row"
      @submit.prevent="submitForm(name, contents, selectedIndex)"
    >
      <div class="medium-12 columns relative">
        <label :class="{ error: $v.name.$error }">
          {{ $t('QUICK_REPLY.FORM.NAME.LABEL') }}
          <input
            v-model.trim="name"
            type="text"
            :placeholder="$t('QUICK_REPLY.FORM.NAME.PLACEHOLDER')"
            :maxlength="100"
            @input="$v.name.$touch"
          />
          <span class="limit-name">{{ `${100 - name.length} remaining` }}</span>
        </label>
      </div>
      <div class="medium-12 columns relative">
        <label>
          {{ $t('QUICK_REPLY.FORM.CONTENTS.LABEL') }}
          <textarea
            v-model.trim="contentTemp"
            rows="5"
            type="text"
            :maxlength="20"
            :disabled="contents.length >= 13"
            @keyup.enter.exact="addQuickItem"
            @keydown.enter.exact.prevent
          />
        </label>
        <span class="limit-content">{{ 20 - contentTemp.length }}</span>
      </div>
      <span class="information">{{ $t('QUICK_REPLY.FORM.ENTER_INPUT') }}</span>
      <span class="medium-12 columns remaining"
        >{{ 13 - contents.length }} {{ $t('QUICK_REPLY.REMAINING') }}</span
      >

      <div class="medium-12 columns contents">
        <bubble-setting
          v-for="(quickContent, index) in contents"
          :key="index"
          class="contents--items"
          @click="removeQuickItem(index)"
        >
          {{ quickContent }}
        </bubble-setting>
      </div>

      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-submit-button
            :disabled="$v.name.$invalid || $v.contents.$invalid || loading"
            :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
          />
          <button class="button clear" @click.prevent="onClose">
            {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';

import BubbleSetting from '../BubbleSetting.vue';

export default {
  components: { BubbleSetting },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    submitForm: {
      type: Function,
      default: () => {},
    },
    dataName: {
      type: String,
      default: '',
    },
    dataContents: {
      type: Array,
      default: () => [],
    },
    selectedIndex: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: this.dataName,
      contents: this.dataContents,
      contentTemp: '',
      show: true,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
    contents: {
      required,
      minLength: minLength(1),
    },
  },
  methods: {
    addQuickItem() {
      if (this.contentTemp && this.contents.length < 13) {
        this.contents = [...this.contents, this.contentTemp];
        this.contentTemp = '';
      }
    },
    removeQuickItem(idx) {
      // eslint-disable-next-line prettier/prettier
      this.contents = this.contents.filter((_, i) => i !== idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.limit-content {
  position: absolute;
  right: 12px;
  bottom: 20px;
  color: var(--grey-07);
}
.remaining {
  color: var(--grey-07);
  margin-bottom: 24px;
}
.information {
  margin-bottom: 24px;
}
.contents {
  margin-bottom: 30px;
  &--items {
    margin-right: 5px;
  }
}
.limit-name {
  display: block;
  text-align: right;
  color: var(--grey-07);
  font-size: 1rem;
  margin-top: -15px;
}
</style>
