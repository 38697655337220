<template>
  <div class="whatsapp-form">
    <div class="info">
      <img src="~dashboard/assets/icons/ic_info.svg" />
      <div class="content">
        <span>{{ $t('NEW_CHANNEL.WHATSAPP.INFO') }}</span>
        <a
          href="https://docs.kata.ai/kata-omnichat/general-setup"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('NEW_CHANNEL.WHATSAPP.MORE_INFO') }}
        </a>
      </div>
    </div>
    <form
      class="column align-self-top"
      @submit.prevent="submitWhatsappChannel()"
    >
      <div class="column form-input-wrapper">
        <woot-input
          v-model.trim="credentials.username"
          type="text"
          icon="ic_user.svg"
          :class="{ error: $v.credentials.username.$error }"
          :placeholder="$t('NEW_CHANNEL.WHATSAPP.FORM.USERNAME.PLACEHOLDER')"
          :error="
            $v.credentials.username.$error
              ? $t('NEW_CHANNEL.WHATSAPP.FORM.USERNAME.ERROR')
              : ''
          "
          @blur="$v.credentials.username.$touch"
        />
        <woot-input
          v-model.trim="credentials.password"
          type="password"
          icon="ic_lock.svg"
          :class="{ error: $v.credentials.password.$error }"
          :placeholder="$t('NEW_CHANNEL.WHATSAPP.FORM.PASSWORD.PLACEHOLDER')"
          :error="
            $v.credentials.password.$error
              ? $t('NEW_CHANNEL.WHATSAPP.FORM.PASSWORD.ERROR')
              : ''
          "
          @blur="$v.credentials.password.$touch"
        />
        <submit-button
          v-if="!webhook.url"
          :disabled="$v.$invalid || isLoading"
          :button-text="$t('NEW_CHANNEL.WHATSAPP.FORM.GENERATE')"
          :loading="isLoading"
          button-class="expanded"
        >
        </submit-button>
      </div>
    </form>
    <template v-if="webhook.url">
      <div class="webhook">
        <div class="webhook-wrapper">
          <div class="webhook-url">
            {{ webhook.url }}
          </div>
          <div
            v-tooltip="{
              show: showTooltip,
              trigger: 'manual',
              content: 'Webhook copied!',
            }"
            class="webhook-copy"
            @click="onCopyWebhook"
          >
            <img
              style="max-width: inherit"
              src="~dashboard/assets/icons/ic_copy.svg"
            />
          </div>
        </div>
        <div class="webhook-info">
          <img src="~dashboard/assets/icons/ic_info.svg" />
          <span>{{ $t('NEW_CHANNEL.WHATSAPP.WEBHOOK') }}</span>
        </div>
      </div>
      <div class="add-agents">
        <submit-button
          type="button"
          :disabled="!webhook.isCopied"
          :button-text="$t('NEW_CHANNEL.WHATSAPP.FORM.ADD_AGENT')"
          @click="onClickAddAgent"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import copy from 'copy-text-to-clipboard';

import router from 'dashboard/routes/index';
import SubmitButton from 'dashboard/components/buttons/FormSubmitButton';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    SubmitButton,
  },
  mixins: [alertMixin],
  props: {
    inboxName: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
      webhook: {
        data: {},
        url: '',
        isCopied: false,
      },
      isLoading: false,
      showTooltip: false,
    };
  },
  validations: {
    credentials: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    submitWhatsappChannel() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.isLoading = true;
        const request = {
          ...this.credentials,
          inbox_name: this.inboxName,
        };
        this.$store
          .dispatch('inboxes/createWhatsappChannel', request)
          .then(data => {
            this.$data.webhook.data = data.data;
            this.$data.webhook.url = data.webhook_url;
            this.$customGTMStep('WABA-Connect', 'Generate Webhook URL', 3);
          })
          .catch(error => {
            this.isLoading = false;
            let errorMessage = this.$t(
              'NEW_CHANNEL.WHATSAPP.API.ERROR_MESSAGE',
            );
            if (error) {
              errorMessage = error.response.data.message;
            }
            this.showAlert(errorMessage);
          });
      }
    },
    onCopyWebhook(e) {
      e.preventDefault();
      copy(this.$data.webhook.url);
      this.$data.webhook.isCopied = true;
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 1500);
    },
    onClickAddAgent() {
      this.$customGTMStep('WABA-Connect', 'Success connect', 4);
      router.replace({
        name: 'settings_inboxes_add_agents',
        params: { page: 'new', inbox_id: this.$data.webhook.data.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  .message {
    margin: 0;
    margin-top: 5px;
  }
}
.info {
  display: flex;
  align-items: center;
  background: var(--greylight-02);
  border: 1px solid var(--greylight-04);
  border-radius: 8px;
  margin: 24px 0px;
  padding: 16px 18px;
  margin-top: 0;
  img {
    flex: 0;
    margin-right: 20px;
  }
  .content {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: var(--greydark-02);
    a {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
.webhook {
  background: var(--greylight-02);
  border: 1px solid var(--greylight-04);
  border-radius: 8px;
  margin: 24px 0px;
  padding: 16px 18px;
  &-wrapper {
    background: var(--white);
    border: 1px solid var(--grey-04);
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: var(--greydark-02);
    display: flex;
  }
  &-url {
    padding: 14px 16px;
    flex: 1;
  }
  &-copy {
    padding: 0 16px;
    border-left: 1px solid var(--grey-04);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &-info {
    margin: 8px 0;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    span {
      color: var(--greydark-02);
      font-size: 12px;
      line-height: 20px;
    }
  }
}
.add-agents {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
