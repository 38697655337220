<template>
  <div class="mau-container">
    <div class="row header">
      <h3>How many active user quota do you want to buy?</h3>
    </div>
    <div class="mau-form">
      <MauFormList ref="form-mau" @change="onChangeMau" />
    </div>
    <div class="orders">
      <div class="order-detail">
        <OrderDetail :orders="orders" />
      </div>
    </div>
    <div>
      <BannerInfo />
    </div>
  </div>
</template>

<script>
import MauFormList from './MauFormList';
import OrderDetail from '../orders/OrderDetail';
import BannerInfo from './BannerInfo';
import { required } from 'vuelidate/lib/validators';
import ordersMixin from '../../../../../shared/mixins/ordersMixin';

export default {
  name: 'Mau',
  components: { MauFormList, OrderDetail, BannerInfo },
  mixins: [ordersMixin],
  data() {
    return {
      mau: 0,
      orders: [],
    };
  },
  validations: {
    mau: {
      required,
      minValue: 500,
    },
    form: ['mau'],
  },
  mounted() {
    this.validate();
  },
  methods: {
    validate() {
      const isValid = this.$refs['form-mau'].validate();
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
    onChangeMau(arg) {
      this.mau = arg.mau;
      const order = this.generateOrderMau(
        arg.pricePerMau,
        arg.mau * arg.pricePerMau,
        arg.mau,
      );
      this.orders = [{ ...order }];
      this.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.mau-container {
  padding: 24px 48px;
  font-size: 14px;

  .header {
    h3 {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: bold;
    }

    margin-bottom: 48px;
  }

  .mau-form {
    margin-bottom: 48px;
  }

  .orders {
    margin-bottom: 48px;

    .order-detail {
      margin-bottom: 24px;
    }
  }
}
</style>
