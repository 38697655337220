<template>
  <div>
    <span class="pill-content" :class="status">
      <slot>{{ renderContent() }}</slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: [String],
      default: 'pending',
    },
  },
  methods: {
    renderContent() {
      switch (this.status) {
        case 'paid':
          return 'Paid';
        case 'pending':
          return 'In Progress';
        case 'failed':
          return 'Failed';
        case 'cancelled':
          return 'Cancelled';
        default:
          return 'In Progress';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pill {
  &-content {
    border-radius: 12px;
    padding: 2px 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
  }
}
.paid {
  background: #eafdf5;
  color: #0c965a;
}
.pending {
  background: #d7eaff;
  color: #006fe6;
}
.failed {
  background: #fff4f3;
  color: #ff6150;
}
.cancelled {
  background: #fffbed;
  color: #a37d00;
}
</style>
