/* global axios */

import ApiClient from './ApiClient';

class BillingInfo extends ApiClient {
  constructor() {
    super(undefined, {
      accountScoped: true,
    });
  }

  get() {
    return axios.get(`${this.url}/billing_info`);
  }

  create(data) {
    return axios.post(`${this.url}/billing_info`, data);
  }
}

export default new BillingInfo();
