<template>
  <div class="modal-body">
    <h2 class="text-center billing-title">
      {{ $t('BILLING_CREDITS.BILLING_INFO.MODAL_TITLE') }}
    </h2>
    <div class="modal-split">
      <woot-input
        v-model="first_name"
        name="first_name"
        class="medium-6 column mr-8"
        :label="$t('BILLING_CREDITS.BILLING_INFO.FORM.FIRST_NAME.LABEL')"
        :class="{ error: $v.first_name.$error }"
        :error="
          $v.first_name.$error
            ? !$v.first_name.alphaNum
              ? $t(
                  'BILLING_CREDITS.BILLING_INFO.FORM.FIRST_NAME.ERROR_ALPHANUMERIC',
                )
              : $t('BILLING_CREDITS.BILLING_INFO.FORM.FIRST_NAME.ERROR')
            : ''
        "
        @blur="$v.first_name.$touch"
      />
      <woot-input
        v-model="last_name"
        name="last_name"
        class="medium-6 column"
        :class="{ error: $v.last_name.$error }"
        :label="$t('BILLING_CREDITS.BILLING_INFO.FORM.LAST_NAME.LABEL')"
        :error="
          $v.last_name.$error
            ? !$v.last_name.alphaNum
              ? $t(
                  'BILLING_CREDITS.BILLING_INFO.FORM.LAST_NAME.ERROR_ALPHANUMERIC',
                )
              : $t('BILLING_CREDITS.BILLING_INFO.FORM.LAST_NAME.ERROR')
            : ''
        "
        @blur="$v.last_name.$touch"
      />
    </div>
    <woot-input
      v-model="email"
      name="email"
      class="medium-12 column"
      :class="{ error: $v.email.$error || !$v.email.email }"
      :label="$t('BILLING_CREDITS.BILLING_INFO.FORM.EMAIL.LABEL')"
      :error="
        $v.email.$error
          ? !$v.email.email
            ? $t('BILLING_CREDITS.BILLING_INFO.FORM.EMAIL.ERROR_FORMAT')
            : $t('BILLING_CREDITS.BILLING_INFO.FORM.EMAIL.ERROR')
          : ''
      "
      @blur="$v.email.$touch"
    />
    <woot-input
      v-model.trim="additional_emails"
      name="additional_emails"
      class="medium-12 column"
      :class="{ error: !$v.additional_emails.multipleEmail }"
      :label="$t('BILLING_CREDITS.BILLING_INFO.FORM.ADDITIONAL_EMAILS.LABEL')"
      :error="
        !$v.additional_emails.multipleEmail
          ? $t('BILLING_CREDITS.BILLING_INFO.FORM.ADDITIONAL_EMAILS.ERROR')
          : ''
      "
      @blur="$v.additional_emails.$touch"
    />
    <span v-if="$v.additional_emails.multipleEmail" class="info">
      <inline-svg
        class="icon"
        :src="require('../../../../../assets/icons/ic_info_purple.svg')"
      />
      <span class="info-content">
        {{
          $t('BILLING_CREDITS.BILLING_INFO.FORM.ADDITIONAL_EMAILS.INFO')
        }}</span
      >
    </span>
    <woot-input
      v-model="company_name"
      name="company_name"
      class="medium-12 column"
      :class="{ error: $v.company_name.$error }"
      :label="$t('BILLING_CREDITS.BILLING_INFO.FORM.COMPANY.LABEL')"
      :error="
        $v.company_name.$error
          ? !$v.company_name.alphaNum
            ? $t('BILLING_CREDITS.BILLING_INFO.FORM.COMPANY.ERROR_ALPHANUMERIC')
            : $t('BILLING_CREDITS.BILLING_INFO.FORM.COMPANY.ERRPR')
          : ''
      "
      @blur="$v.company_name.$touch"
    />
    <label>{{ $t('BILLING_CREDITS.BILLING_INFO.FORM.ADDRESS.LABEL') }}</label>
    <textarea
      v-model="address"
      name="address"
      rows="5"
      type="text"
      placeholder="Enter your company address"
      :class="{ errorarea: $v.address.$error }"
      @blur="$v.address.$touch"
    />
    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
    <span class="message"
      >{{
        $v.address.$error
          ? !$v.address.alphaNum
            ? $t('BILLING_CREDITS.BILLING_INFO.FORM.ADDRESS.ERROR_ALPHANUMERIC')
            : $t('BILLING_CREDITS.BILLING_INFO.FORM.ADDRESS.ERROR')
          : ''
      }}
    </span>

    <div class="modal-split">
      <div class="medium-6 column mr-8 select-state">
        <label>{{ $t('BILLING_CREDITS.BILLING_INFO.FORM.STATE.LABEL') }}</label>
        <multiselect
          v-model="state"
          :options="stateOption"
          :value="state"
          @select="$v.state.$touch"
        >
        </multiselect>
      </div>
      <woot-input
        v-model="zip_code"
        name="zip_code"
        class="medium-6 column mr-8 column input-zip"
        :class="{ error: $v.zip_code.$error }"
        :label="$t('BILLING_CREDITS.BILLING_INFO.FORM.ZIP_CODE.LABEL')"
        :error="
          $v.zip_code.$error
            ? !$v.zip_code.minLength || !$v.zip_code.maxLength
              ? $t('BILLING_CREDITS.BILLING_INFO.FORM.ZIP_CODE.ERROR_LENGTH')
              : $t('BILLING_CREDITS.BILLING_INFO.FORM.ZIP_CODE.ERROR')
            : ''
        "
        @blur="$v.zip_code.$touch"
      />
    </div>
    <woot-input
      v-model="npwp"
      name="npwp"
      type="number"
      class="medium-12 column"
      :class="{ error: $v.npwp.$error }"
      :label="$t('BILLING_CREDITS.BILLING_INFO.FORM.NPWP.LABEL')"
      :error="
        $v.npwp.$error
          ? !$v.npwp.minLength || !$v.npwp.maxLength
            ? $t('BILLING_CREDITS.BILLING_INFO.FORM.NPWP.ERROR_LENGTH')
            : $t('BILLING_CREDITS.BILLING_INFO.FORM.NPWP.ERROR')
          : ''
      "
      @blur="$v.npwp.$touch"
    />
    <span v-if="!$v.npwp.$error && $v.npwp.numeric" class="info">
      <inline-svg
        class="icon"
        :src="require('../../../../../assets/icons/ic_info_purple.svg')"
      />
      <span class="info-content">
        {{ $t('BILLING_CREDITS.BILLING_INFO.FORM.NPWP.INFO') }}</span
      >
    </span>

    <div class="modal-split">
      <woot-button variant="clear" size="expanded" @click="closeModal()">
        Cancel
      </woot-button>
      <woot-button
        variant="primary"
        size="expanded"
        :disabled="$v.$invalid"
        @click="submitBillingInfo"
      >
        Save Detail
      </woot-button>
    </div>
  </div>
</template>
<script>
import stateProvinceMixins from 'shared/mixins/stateProvinceMixins';
import alertMixin from 'shared/mixins/alertMixin';
import InlineSvg from 'vue-inline-svg';

import {
  required,
  email,
  numeric,
  maxLength,
  minLength,
  helpers,
} from 'vuelidate/lib/validators';

export default {
  components: { InlineSvg },
  mixins: [stateProvinceMixins, alertMixin],
  props: {
    billingData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      first_name: this.billingData.first_name || '',
      last_name: this.billingData.last_name || '',
      email: this.billingData.email || '',
      additional_emails:
        (this.billingData.additional_emails &&
          this.billingData.additional_emails.toString()) ||
        '',
      company_name: this.billingData.company_name || '',
      address: this.billingData.address || '',
      state: this.billingData.state || '',
      zip_code: this.billingData.zip_code || '',
      npwp: this.billingData.npwp || '',
    };
  },
  validations: {
    first_name: {
      required,
      alphaNum: helpers.regex('alphaNum', /^[\w\s]+$/i),
    },
    last_name: {
      alphaNum: helpers.regex('alphaNum', /^[\w\s]+$/i),
    },
    email: {
      required,
      email,
    },
    additional_emails: {
      multipleEmail: helpers.regex(
        'multipleEmail',
        /^(((\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)(,|$)){0,5})$/i,
      ),
    },
    company_name: {
      required,
      alphaNum: helpers.regex('alphaNum', /^[\w\-\\.\s]+$/i),
    },
    address: {
      required,
      alphaNum: helpers.regex('alphaNum', /^[\w\-\\.\s]+$/i),
    },
    state: {
      required,
    },
    zip_code: {
      required,
      numeric,
      minLength: minLength(5),
      maxLength: maxLength(5),
    },
    npwp: {
      required,
      numeric,
      minLength: minLength(15),
      maxLength: maxLength(15),
    },
  },
  methods: {
    async submitBillingInfo() {
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        additional_emails: this.additional_emails.replace(/,$/g, '').split(','),
        company_name: this.company_name,
        address: this.address,
        state: this.state,
        zip_code: this.zip_code,
        npwp: this.npwp,
      };
      await this.$store
        .dispatch('subscription/addBillingInfo', data)
        .then(() => {
          this.showAlert('Success add billing info');
          bus.$emit('closeModalBillingInfo');
        })
        .catch(() => this.showAlert('Error add billing info'));
    },
    closeModal() {
      bus.$emit('closeModalBillingInfo');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  padding: 24px;
}
.modal-split {
  display: flex;
  margin-right: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.billing-title {
  margin-bottom: 42px;
}
.message {
  color: var(--r-400);
  display: block;
  font-size: var(--font-size-mini);
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1rem;
  width: 100%;
}
.errorarea {
  border: 1px solid var(--r-400);
}
.info {
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
  align-content: center;
}
.info-content {
  color: #6e4ee4;
  font-size: var(--font-size-mini);
  font-weight: 400;
  width: 100%;
}

.icon {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 8px;
}
</style>
