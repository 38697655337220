<template>
  <div class="billing-card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BillingCard',
};
</script>

<style lang="scss" scoped>
.billing-card {
  padding: 32px;
  border: 1px solid #e5eaef;
  border-radius: 8px;
  font-size: 14px;
  background: var(--white);
}
</style>
