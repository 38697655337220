<template>
  <div v-if="status === 'paid'">
    <BankPaymentDetailPaid :bank="bankDetail" :paid-at="paidAt" />
  </div>
  <div v-else>
    <BankPaymentDetailPending
      :bank="bankDetail"
      :total-amount="totalAmount"
      :va-number="vaNumber"
    />
  </div>
</template>

<script>
import BankPaymentDetailPending from './BankVirtualAccounts/BankPaymentDetailPending';
import BankPaymentDetailPaid from './BankVirtualAccounts/BankPaymentDetailPaid';
import { bankHelper } from 'dashboard/helper/bankHelper';

export default {
  name: 'PaymentMethodDetail',
  components: { BankPaymentDetailPaid, BankPaymentDetailPending },
  props: {
    bank: {
      type: String,
      required: true,
      default: '',
    },
    status: {
      type: String,
      default: 'pending',
    },
    totalAmount: {
      required: true,
      type: Number,
    },
    paidAt: {
      type: String,
      default: '',
    },
    vaNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      bankDetail: {},
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    bank: function() {
      this.getBankDetail();
    },
  },
  mounted() {
    this.getBankDetail();
  },
  methods: {
    getBankDetail() {
      this.bankDetail = bankHelper.find(
        item => item.name.toLowerCase() === this.bank.toLowerCase(),
      );
    },
  },
};
</script>
