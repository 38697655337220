<template>
  <div
    class="comment-list__wrapper"
    :class="isHovered && 'is-hovered'"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <div class="comment-list__item-post">
      {{ $t('INBOX_MGMT.PRIVATE_REPLY.FROM') }}
      <a :href="permalink" target="_blank" rel="noopener noreferrer">{{
        $t('INBOX_MGMT.PRIVATE_REPLY.THIS_POST')
      }}</a>
    </div>
    <div class="comment-list__item-list" :class="isCommentReplied && `replied`">
      {{ data.content }}
    </div>
    <div
      v-if="!isCommentReplied && isHovered"
      class="comment-list__item-reply"
      @click="$emit('get-comment', data)"
    >
      <img src="~dashboard/assets/icons/ic_forward.svg" />
      <span>
        {{ $t('INBOX_MGMT.PRIVATE_REPLY.REPLY') }}
      </span>
    </div>
    <div v-if="isCommentReplied" class="comment-list__replied">
      <img src="~dashboard/assets/icons/ic_success.svg" />
      <span>{{ $t('INBOX_MGMT.INSTAGRAM_SETTINGS.COMMENT_REPLIED') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    isCommentReplied() {
      return (
        this.data.content_attributes && this.data.content_attributes.replied
      );
    },
    permalink() {
      const {
        params: { accountId, conversation_id },
      } = this.$route;
      const { source_id } = this.data;
      return `/api/v1/accounts/${accountId}/conversations/${conversation_id}/messages/instagram/${source_id}/permalink`;
    },
  },
  methods: {
    mouseEnter() {
      if (!this.isCommentReplied) {
        this.isHovered = true;
      }
    },
    mouseLeave() {
      if (!this.isCommentReplied) {
        this.isHovered = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.comment {
  &-list {
    &__wrapper {
      display: flex;
      flex: 0 0 auto;
      max-width: 100%;
      margin: 0 0 0.2rem;
      position: relative;
      flex-direction: column;
      margin: 16px 0;

      &.is-hovered {
        min-height: 95px;
      }
    }

    &__item {
      &-post {
        background: var(--indigo-01);
        width: 140px;
        height: 32px;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        font-size: 1.4rem;

        a {
          padding-left: 5px;
        }
      }

      &-list {
        font-size: 1.4rem;
        &.replied {
          color: var(--grey-05);
        }
      }

      &-reply {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        margin: 8px 0;
        border-radius: 4px;
        border: 1px solid var(--grey-05);
        padding: 4px 8px;
        max-width: 170px;
        cursor: pointer;

        img {
          margin-right: 8px;
        }
      }
    }

    &__replied {
      margin: 8px 0;
      max-width: 80px;
      font-size: 1rem;
      background: var(--green-01);
      border-radius: 16px;
      color: var(--green-07);
      padding: 6px 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 6px;
      }
    }
  }
}
</style>
