<template>
  <div class="wizard-step">
    {{ renderStep($parent.slotProps.activeTabIndex) }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$parent.tabs;
  },
  methods: {
    renderStep(active) {
      return `Step ${active + 1} Of ${this.tabs.length}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard {
  &-step {
    color: var(--grey-07);
    text-transform: uppercase;
    margin-bottom: 8px;
    font-weight: 600;
  }
}
</style>
