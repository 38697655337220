<template>
  <div class="ui-snackbar">
    <div class="ui-snackbar-text">
      {{ message }}
    </div>
  </div>
</template>

<script>
import constant from '../constants';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    duration: {
      type: Number,
      default: constant.NOTIFICATON.DURATION,
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    window.setTimeout(() => {
      bus.$emit(BUS_EVENTS.CLOSE_NOTIFICATION);
    }, (this.index + 1) * this.duration);
  },
};
</script>
