var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medium-12 column"},[_c('div',{staticClass:"row align-center"},[_c('div',{staticClass:"medium-8 column login"},[_c('div',{staticClass:"row align-center"},[_c('div',{staticClass:"small-8 medium-6 login__hero align-self-top"},[_c('img',{staticClass:"hero__logo",attrs:{"src":_vm.globalConfig.logo,"alt":_vm.globalConfig.installationName}}),_vm._v(" "),_c('h2',{staticClass:"hero__title"},[_vm._v("\n            "+_vm._s(_vm.useInstallationName(
                _vm.$t('LOGIN.TITLE'),
                _vm.globalConfig.installationName
              ))+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"row align-center"},[_c('div',{staticClass:"small-8 medium-6 column"},[_c('div',{staticClass:"row align-center"},[(!_vm.email)?_c('div',{staticClass:"small-12 medium-12 column"},[_c('form',{staticClass:"login-box column align-self-top",on:{"submit":function($event){$event.preventDefault();return _vm.login()}}},[_c('div',{staticClass:"column log-in-form"},[_c('woot-input',{class:{ error: _vm.$v.credentials.email.$error },attrs:{"type":"email","icon":"ic_message.svg","placeholder":_vm.$t('LOGIN.EMAIL.PLACEHOLDER'),"error":_vm.$v.credentials.email.$error
                        ? _vm.$t('LOGIN.EMAIL.ERROR')
                        : ''},on:{"blur":_vm.$v.credentials.email.$touch},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"credentials.email"}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.credentials.password.$error },attrs:{"type":"password","icon":"ic_lock.svg","placeholder":_vm.$t('LOGIN.PASSWORD.PLACEHOLDER'),"error":_vm.$v.credentials.password.$error
                        ? _vm.$t('LOGIN.PASSWORD.ERROR')
                        : ''},on:{"blur":_vm.$v.credentials.password.$touch},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"credentials.password"}}),_vm._v(" "),_c('div',{staticClass:"forgot-password"},[_c('router-link',{attrs:{"to":"auth/reset/password"}},[_vm._v("\n                      "+_vm._s(_vm.$t('LOGIN.FORGOT_PASSWORD'))+"\n                    ")])],1),_vm._v(" "),_c('woot-submit-button',{attrs:{"disabled":_vm.loginApi.showLoading,"button-text":_vm.$t('LOGIN.SUBMIT'),"loading":_vm.loginApi.showLoading,"button-class":"expanded"}})],1)]),_vm._v(" "),_c('div',{staticClass:"column signin__footer"},[(_vm.showSignupLink())?_c('p',[_vm._v("\n                  "+_vm._s(_vm.$t('LOGIN.DONT_HAVE_ACCOUNT'))+"\n                  "),_c('router-link',{attrs:{"to":"auth/signup"}},[_vm._v("\n                    "+_vm._s(_vm.$t('LOGIN.CREATE_NEW_ACCOUNT'))+"\n                  ")])],1):_vm._e()])]):_c('woot-spinner',{attrs:{"size":""}})],1)])])]),_vm._v(" "),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medium-4 column hero-container"},[_c('img',{attrs:{"src":require("dashboard/assets/images/hero/hero-login.svg")}})])}]

export { render, staticRenderFns }