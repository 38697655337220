<template>
  <div class="medium-12 column">
    <div class="row align-center">
      <div class="medium-4 column hero-container">
        <img src="~dashboard/assets/images/hero/hero-signup.svg" />
      </div>
      <div class="medium-8 column login">
        <div class="row align-center">
          <div class="small-8 medium-6 login__hero align-self-top">
            <img
              :src="globalConfig.logo"
              :alt="globalConfig.installationName"
              class="hero--logo"
            />
            <h2 class="hero__title" v-html="$t('REGISTER.REGISTER_ACCOUNT')" />
          </div>
        </div>
        <div class="row align-center">
          <div class="small-8 medium-6 column">
            <div class="row align-center">
              <div class="small-12 medium-12 large-12 column">
                <form class="signup--box login-box" @submit.prevent="submit">
                  <woot-input
                    v-model.trim="credentials.email"
                    type="email"
                    icon="ic_message.svg"
                    :class="{ error: $v.credentials.email.$error }"
                    :placeholder="$t('REGISTER.EMAIL.PLACEHOLDER')"
                    :error="
                      $v.credentials.email.$error
                        ? $t('REGISTER.EMAIL.ERROR')
                        : ''
                    "
                    @blur="$v.credentials.email.$touch"
                  />
                  <woot-input
                    v-model.trim="credentials.password"
                    type="password"
                    icon="ic_lock.svg"
                    :class="{ error: $v.credentials.password.$error }"
                    :placeholder="$t('REGISTER.PASSWORD.PLACEHOLDER')"
                    :error="
                      $v.credentials.password.$error
                        ? $t('REGISTER.PASSWORD.ERROR')
                        : ''
                    "
                    @blur="$v.credentials.password.$touch"
                  />
                  <woot-input
                    v-model.trim="credentials.confirmPassword"
                    type="password"
                    icon="ic_lock.svg"
                    :class="{ error: $v.credentials.confirmPassword.$error }"
                    :placeholder="$t('REGISTER.CONFIRM_PASSWORD.PLACEHOLDER')"
                    :error="
                      $v.credentials.confirmPassword.$error
                        ? $t('REGISTER.CONFIRM_PASSWORD.ERROR')
                        : ''
                    "
                    @blur="$v.credentials.confirmPassword.$touch"
                  />
                  <woot-submit-button
                    :disabled="isSignupInProgress"
                    :button-text="$t('REGISTER.SUBMIT')"
                    :loading="isSignupInProgress"
                    button-class="expanded"
                  >
                  </woot-submit-button>
                  <p class="accept--terms" v-html="termsLink"></p>
                </form>
                <div class="column signin--footer">
                  <span>{{ $t('REGISTER.HAVE_AN_ACCOUNT') }}</span>
                  <router-link to="/app/login">
                    {{
                      useInstallationName(
                        $t('REGISTER.AUTH_TITLE'),
                        globalConfig.installationName,
                      )
                    }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-redeclare */

import { required, minLength, email } from 'vuelidate/lib/validators';
import Auth from '../../api/auth';
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import alertMixin from 'shared/mixins/alertMixin';
import { DEFAULT_REDIRECT_URL } from '../../constants';

export default {
  mixins: [globalConfigMixin, alertMixin],
  data() {
    return {
      credentials: {
        email: '',
        password: '',
        confirmPassword: '',
      },
      isSignupInProgress: false,
      error: '',
    };
  },
  validations: {
    credentials: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        isEqPassword(value) {
          if (value !== this.credentials.password) {
            return false;
          }
          return true;
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    termsLink() {
      return this.$t('REGISTER.TERMS_ACCEPT')
        .replace('https://www.kata.ai/terms', this.globalConfig.termsURL)
        .replace(
          'https://www.kata.ai/privacy-policy',
          this.globalConfig.privacyURL,
        );
    },
  },
  mounted() {
    this.$customGTMStep('Self-Onboarding', `Register Account`, 1);
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isSignupInProgress = true;
      try {
        const response = await Auth.register(this.credentials);
        if (response.status === 200) {
          window.location = DEFAULT_REDIRECT_URL;
        }
      } catch (error) {
        let errorMessage = this.$t('REGISTER.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      } finally {
        this.isSignupInProgress = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.signup {
  .signup--hero {
    margin-bottom: var(--space-larger);

    .hero--logo {
      width: 180px;
    }

    .hero--title {
      margin-top: var(--space-large);
      font-weight: var(--font-weight-light);
    }
  }

  .signup--box {
    padding: var(--space-large);

    label {
      font-size: var(--font-size-default);
      color: var(--b-600);

      input {
        padding: var(--space-slab);
        height: var(--space-larger);
        font-size: var(--font-size-default);
      }
    }
  }

  .signin--footer {
    padding: var(--space-medium);
    font-size: var(--font-size-default);

    > a {
      font-weight: var(--font-weight-bold);
    }
  }

  .accept--terms {
    font-size: var(--font-size-small);
    text-align: center;
    margin: var(--space-normal) 0 0 0;
  }
}
</style>
