import { localizations } from 'shared/constants/localizations';

export default {
  computed: {
    hostURL() {
      return window.kataOmnichatConfig.hostURL;
    },
    twilioCallbackURL() {
      return `${this.hostURL}/twilio/callback`;
    },
    vapidPublicKey() {
      return window.kataOmnichatConfig.vapidPublicKey;
    },
    enabledLanguages() {
      return localizations;
    },
  },
};
