<template>
  <select v-model="activeStatus" class="status--filter" @change="onTabChange()">
    <option
      v-for="item in $t('CHAT_LIST.CHAT_STATUS_ITEMS')"
      :key="item['VALUE']"
      :value="item['VALUE']"
    >
      {{ item['TEXT'] }}
    </option>
  </select>
</template>

<script>
import { mapGetters } from 'vuex';
import constants from '../../../constants';

export default {
  data: () => ({
    activeStatus: constants.STATUS_TYPE.OPEN,
  }),
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    onTabChange() {
      this.$store.dispatch('setChatFilter', this.activeStatus);
      this.$router.push(`/app/accounts/${this.accountId}/dashboard`);
      this.$emit('statusFilterChange', this.activeStatus);
      const status =
        this.activeStatus.charAt(0).toUpperCase() + this.activeStatus.slice(1);
      this.$customGTM(
        'Event',
        `Conversation - Status ${status}`,
        'Drag Down',
        status,
      );
    },
  },
};
</script>
