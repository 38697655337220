/* global axios */
import ApiClient from '../ApiClient';

export class PlansAPI extends ApiClient {
  constructor() {
    super('plans');
  }

  get() {
    return axios.get(`${this.url}`);
  }
}

export default new PlansAPI();
