<template>
  <div class="bottom-box" :class="wrapClass">
    <div class="left-wrap">
      <woot-button
        :title="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
        icon="ion-happy-outline"
        emoji="😊"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleEmojiPicker"
      />

      <file-upload
        :size="4096 * 4096"
        accept="image/*, application/pdf, audio/mpeg, video/mp4, audio/ogg, text/csv"
        @input-file="onFileUpload"
      >
        <woot-button
          v-if="showAttachButton"
          class-names="button--upload"
          :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
          icon="ion-android-attach"
          emoji="📎"
          color-scheme="secondary"
          variant="smooth"
          size="small"
        />
      </file-upload>
      <woot-button
        v-if="enableRichEditor && !isOnPrivateNote"
        icon="ion-quote"
        emoji="🖊️"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')"
        @click="toggleFormatMode"
      />
      <div
        v-if="isInstagramChannel && getQuickReply.length"
        class="relative quick-container"
      >
        <div v-if="quickReplyMenu" class="quick" @click="hideQuickMenu">
          <span
            v-for="item in getQuickReply"
            :key="item.name"
            class="quick-item"
            @click="submitQuickReply(item.contents)"
          >
            {{ item.name }}
          </span>
        </div>
        <button
          v-if="!isOnPrivateNote"
          v-click-outside="hideQuickMenu"
          class="button clear button--emoji submit--quick"
          :title="$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')"
          @click="toggleQuickMenu"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.70003 0.666667C6.70003 0.489856 6.62979 0.320286 6.50477 0.195262C6.37975 0.0702379 6.21018 0 6.03337 0C5.85655 0 5.68699 0.0702379 5.56196 0.195262C5.43694 0.320286 5.3667 0.489856 5.3667 0.666667V5.33333H0.666667C0.489856 5.33333 0.320286 5.40357 0.195262 5.5286C0.0702379 5.65362 0 5.82319 0 6C0 6.17681 0.0702379 6.34638 0.195262 6.4714C0.320286 6.59643 0.489856 6.66667 0.666667 6.66667H5.3667V11.3333C5.3667 11.5101 5.43694 11.6797 5.56196 11.8047C5.68699 11.9298 5.85655 12 6.03337 12C6.21018 12 6.37975 11.9298 6.50477 11.8047C6.62979 11.6797 6.70003 11.5101 6.70003 11.3333V6.66667H11.3333C11.5101 6.66667 11.6797 6.59643 11.8047 6.4714C11.9298 6.34638 12 6.17681 12 6C12 5.82319 11.9298 5.65362 11.8047 5.5286C11.6797 5.40357 11.5101 5.33333 11.3333 5.33333H6.70003V0.666667Z"
              fill="#006fe6"
            />
          </svg>
          {{ $t('QUICK_REPLY.SEND') }}
        </button>
      </div>
    </div>
    <div class="right-wrap">
      <div v-if="isFormatMode" class="enter-to-send--checkbox">
        <input
          :checked="enterToSendEnabled"
          type="checkbox"
          value="enterToSend"
          @input="toggleEnterToSend"
        />
        <label for="enterToSend">
          {{ $t('CONVERSATION.REPLYBOX.ENTER_TO_SEND') }}
        </label>
      </div>
      <woot-button
        size="small"
        :class-names="buttonClass"
        :is-disabled="isSendDisabled"
        @click="onSend"
      >
        {{ sendButtonText }}
      </woot-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import FileUpload from 'vue-upload-component';

import inboxMixin from 'shared/mixins/inboxMixin';
import { REPLY_EDITOR_MODES } from './constants';
export default {
  name: 'ReplyTopPanel',
  components: { FileUpload },
  mixins: [inboxMixin],
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
    channel: {
      type: String,
      required: false,
      default: '',
    },
    selectQuickReply: {
      type: Function,
      default: () => {},
    },
    mode: {
      type: String,
      default: REPLY_EDITOR_MODES.REPLY,
    },
    onSend: {
      type: Function,
      default: () => {},
    },
    sendButtonText: {
      type: String,
      default: '',
    },
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    showEmojiPicker: {
      type: Boolean,
      default: false,
    },
    toggleEmojiPicker: {
      type: Function,
      default: () => {},
    },
    isSendDisabled: {
      type: Boolean,
      default: false,
    },
    setFormatMode: {
      type: Function,
      default: () => {},
    },
    isFormatMode: {
      type: Boolean,
      default: false,
    },
    isOnPrivateNote: {
      type: Boolean,
      default: false,
    },
    enableRichEditor: {
      type: Boolean,
      default: false,
    },
    enterToSendEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      quickReplyMenu: false,
    };
  },
  computed: {
    isNote() {
      return this.mode === REPLY_EDITOR_MODES.NOTE;
    },
    wrapClass() {
      return {
        'is-note-mode': this.isNote,
      };
    },
    buttonClass() {
      return {
        warning: this.isNote,
      };
    },
    showAttachButton() {
      return this.showFileUpload || this.isNote;
    },
    isInstagramChannel() {
      return this.isAnInstagramInbox;
    },
    getQuickReply() {
      return this.inbox.quick_reply ? this.inbox.quick_reply : [];
    },
  },
  methods: {
    toggleFormatMode() {
      this.setFormatMode(!this.isFormatMode);
    },
    toggleEnterToSend() {
      this.$emit('toggleEnterToSend', !this.enterToSendEnabled);
    },
    toggleQuickMenu() {
      this.quickReplyMenu = true;
    },
    hideQuickMenu() {
      this.quickReplyMenu = false;
    },
    submitQuickReply(item) {
      this.selectQuickReply(item);
      this.hideQuickMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-box {
  display: flex;
  justify-content: space-between;
  padding: var(--space-slab) var(--space-normal);

  &.is-note-mode {
    background: var(--y-50);
  }
}

.left-wrap .button {
  margin-right: var(--space-small);
}

.left-wrap {
  align-items: center;
  display: flex;
}

.right-wrap {
  display: flex;

  .enter-to-send--checkbox {
    align-items: center;
    display: flex;

    input {
      margin: 0;
    }

    label {
      color: var(--s-500);
      font-size: var(--font-size-mini);
    }
  }
}

::v-deep .file-uploads {
  label {
    cursor: pointer;
  }
  &:hover .button {
    background: var(--s-100);
  }
}

.quick {
  width: inherit;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 100%;
  box-shadow: 0px 2px 4px rgba(137, 148, 159, 0.32);
  border-radius: 4px;
  background: white;
  padding: 8px;
  flex-wrap: wrap;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;

  &-container {
    width: 216px;
    z-index: 1000;
  }

  &-title {
    padding: 8px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    background: white;
  }
  &-divider {
    border-bottom: 1px solid var(--grey-04);
    margin-bottom: 6px;
  }
  &-item {
    background: white;
    padding: 8px 12px;
    border: 2px solid transparent;

    border-radius: 4px;
    cursor: pointer;
    &:hover {
      border: 2px solid var(--blue-06);
      background: #b9f1f9;
    }
  }
}
.button {
  svg {
    margin-right: 5px;
  }
}
</style>
