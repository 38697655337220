import { render, staticRenderFns } from "./BankPaymentDetailPaid.vue?vue&type=template&id=beabac58&scoped=true&"
import script from "./BankPaymentDetailPaid.vue?vue&type=script&lang=js&"
export * from "./BankPaymentDetailPaid.vue?vue&type=script&lang=js&"
import style0 from "./BankPaymentDetailPaid.vue?vue&type=style&index=0&id=beabac58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beabac58",
  null
  
)

export default component.exports