<template>
  <div class="integrations-wrapper">
    <woot-loading-state v-if="isLoading" />
    <template v-else>
      <div class="integrations-wrapper__list">
        <table class="integrations-list__table">
          <thead>
            <tr>
              <th>{{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.LIST.NAME') }}</th>
              <th>{{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.LIST.INBOXES') }}</th>
              <th>
                {{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.LIST.ACCESS_TOKEN') }}
              </th>
              <th>{{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.LIST.ACTION') }}</th>
            </tr>
          </thead>
          <tbody v-if="integrations.data.length > 0">
            <tr v-for="(data, index) in integrations.data" :key="index">
              <td
                class="cursor-pointer agent-bot-name"
                @click.stop="showConfiguration(data.id)"
              >
                {{ data.name }}
              </td>
              <td>
                <agent-bot-inboxes
                  :inboxes="data.inboxes"
                  :agent-bot-id="data.id"
                />
              </td>
              <td>
                <agent-bot-access-token :agent-bot="data" />
              </td>
              <td>
                <woot-button
                  size="small"
                  variant="clear"
                  icon="ion-edit"
                  @click.stop="editConfiguration(data.id)"
                >
                  {{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.LIST.EDIT') }}
                </woot-button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">
                <agent-bot-empty
                  :title="$t('NEW_INTEGRATION_APPS.AGENT_BOT.EMPTY.TITLE')"
                  :content="$t('NEW_INTEGRATION_APPS.AGENT_BOT.EMPTY.CONTENT')"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <agent-bot-list-pagination
        v-if="integrations.total_record > limit"
        :current-page="integrations.current_page"
        :page-size="integrations.per_page"
        :total-count="integrations.total_record"
        :on-page-change="handlePageChange"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AgentBotInboxes from './AgentBotInboxes';
import AgentBotAccessToken from './AgentBotAccessToken';
import AgentBotListPagination from './AgentBotListPagination';
import AgentBotEmpty from './AgentBotEmpty';

import AgentBot from 'dashboard/api/agent-bot';
import alertMixin from 'shared/mixins/alertMixin';

const LIMIT = 10;

export default {
  components: {
    AgentBotInboxes,
    AgentBotAccessToken,
    AgentBotListPagination,
    AgentBotEmpty,
  },
  mixins: [alertMixin],
  data() {
    return {
      integrations: {
        data: [],
        per_page: 0,
        current_page: 0,
        total_record: 0,
      },
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    limit() {
      return LIMIT;
    },
  },
  created() {
    this.getAgentBotList();
  },
  mounted() {
    bus.$on('addAgentBotData', data => {
      this.$data.integrations.data = [...this.integrations.data, data];
      this.$data.integrations.total_record = this.integrations.total_record + 1;
    });
    bus.$on('editAgentBotData', data => {
      const find = this.integrations.data.find(item => item.id === data.id);
      const updated = { ...find, ...data };
      this.$data.integrations.data = this.integrations.data.map(item =>
        item.id === data.id ? { ...updated } : item,
      );
    });
    bus.$on('setStatusAgentBotData', ({ status, agentBotId }) => {
      const find = this.integrations.data.find(item => item.id === agentBotId);
      const updated = { ...find, status };
      this.$data.integrations.data = this.integrations.data.map(item =>
        item.id === agentBotId ? { ...updated } : item,
      );
    });
  },
  methods: {
    async getAgentBotList(page = 1) {
      const {
        params: { accountId },
      } = this.$route;
      this.$data.isLoading = true;
      try {
        const response = await AgentBot.getAgentBot(accountId, LIMIT, page);
        this.$data.integrations = response.data;
      } catch (error) {
        let errorMessage = this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.API.ERROR');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      } finally {
        this.$data.isLoading = false;
      }
    },
    handlePageChange(page) {
      this.getAgentBotList(page);
    },
    emitAgentBotData(id) {
      bus.$emit('fetchAgentBotData', id);
    },
    editConfiguration(id) {
      this.emitAgentBotData(id);
      this.$router.push(
        `/app/accounts/${this.accountId}/settings/integrations/agent-bot/${id}/update`,
      );
    },
    showConfiguration(id) {
      this.emitAgentBotData(id);
      this.$router.push(
        `/app/accounts/${this.accountId}/settings/integrations/agent-bot/${id}/view`,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.integrations {
  &-wrapper {
    &__list {
      background: var(--white);
      border: 1px solid var(--grey-04);
      border-radius: 8px;
      margin-top: 16px;
    }
  }
  &-list {
    &__table {
      margin: 0;
      thead,
      tbody {
        tr {
          th,
          td {
            padding: 20px 24px;
            color: var(--greydark-02);
          }
        }
      }
      thead {
        tr {
          border-bottom: 1px solid var(--grey-04);
          th {
            text-transform: capitalize;
            font-weight: bold;
            padding: 20px 24px;
          }
        }
      }
      tbody {
        tr {
          .agent-bot-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 50ch;
          }
          border-bottom: 1px solid var(--grey-04);
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
