const prepareIndustry = industry => {
  const { free_text, ...rest } = industry;
  if (free_text !== '') {
    return {
      ...rest,
      free_text,
    };
  }
  return {
    ...rest,
  };
};

export const prepareToSave = data => {
  const { employee_number, goal, industry, ...rest } = data;
  const request = {
    ...rest,
    onboarding: [
      { ...employee_number },
      { ...goal },
      { ...prepareIndustry(industry) },
    ],
  };
  return request;
};
