<template>
  <div class="wizard-body small-9 columns">
    <channel-header
      :title="$t('INBOX_MGMT.ADD.AUTH.TITLE')"
      :description="$t('INBOX_MGMT.ADD.AUTH.DESC')"
      icon="channel-icon.svg"
    />
    <div class="channels">
      <div class="channels-item__container">
        <channel-item
          v-for="channel in channelList"
          :key="channel.key"
          :channel="channel"
          :enabled-features="enabledFeatures"
          @channel-item-click="initChannelAuth"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ChannelItem from 'dashboard/components/widgets/ChannelItem';
import router from '../../../index';
import ChannelHeader from './components/Header';

export default {
  components: {
    ChannelItem,
    ChannelHeader,
  },
  data() {
    return {
      enabledFeatures: {},
    };
  },
  computed: {
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
    channelList() {
      const kataChannels = [
        {
          key: 'instagram',
          name: 'Instagram messaging',
          description: 'Connect your Instagram DM to Kata Omnichat.',
        },
        {
          key: 'whatsapp',
          name: 'WhatsApp Business API',
          description:
            'Connect your WhatsApp Business API (WABA) account to Kata Omnichat.',
        },
      ];

      const otherChannels = [
        { key: 'website', name: 'Website' },
        { key: 'facebook', name: 'Facebook' },
        { key: 'twitter', name: 'Twitter' },
        { key: 'twilio', name: 'Twilio' },
        { key: 'email', name: 'Email' },
        { key: 'api', name: 'API' },
        { key: 'telegram', name: 'Telegram' },
        { key: 'line', name: 'Line' },
      ];

      if (process.env.WITH_INBOX_OTHER_CHANNELS === 'true') {
        return [...kataChannels, ...otherChannels];
      }

      return kataChannels;
    },
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
    }),
  },
  mounted() {
    this.initializeEnabledFeatures();
    this.$customGTM('Event', 'Conversation - Connect Inbox', 'Link Click', 'Connect Inbox');
  },
  methods: {
    async initializeEnabledFeatures() {
      await this.$store.dispatch('accounts/get', this.accountId);
      this.enabledFeatures = this.account.features;
    },
    initChannelAuth(channel) {
      const params = {
        page: 'new',
        sub_page: channel,
      };
      router.push({ name: 'settings_inboxes_page_channel', params });
    },
  },
};
</script>

<style lang="scss" scoped>
.channels {
  &-item {
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }
  }
}
</style>
