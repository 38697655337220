<template>
  <div class="row">
    <div class="medium-10">
      <div class="main-content">
        <h3 class="title">
          Billing contact
        </h3>
        <p class="description">
          This is the contact or department who receives the invoices or any
          billing communications.
        </p>
      </div>
    </div>
    <div class="medium-2">
      <woot-button
        icon="ion-plus"
        size="small"
        is-expanded
        @click="openModal()"
      >
        Add info
      </woot-button>
    </div>
  </div>
</template>

<script>
import WootButton from '../../../../components/ui/WootButton';

export default {
  name: 'BillingContactEmpty',
  components: {
    WootButton,
  },
  methods: {
    openModal() {
      bus.$emit('openModalBillingInfo');
    },
  },
};
</script>

<style lang="scss" scoped></style>
