import PlanIcon from '../../dashboard/assets/images/kata/badge-plan.svg';
import MauIcon from '../../dashboard/assets/images/kata/badge-mau.svg';
import OrderAPI from 'dashboard/api/orders';

export default {
  methods: {
    async getOrderId(orderId) {
      if (!orderId) throw new Error('order not found');
      const req = await OrderAPI.getId(orderId);
      return req.data.order;
    },
    generateOrderPlan(name, mauQuota, agentQuota, price) {
      return {
        image: PlanIcon,
        title: `${name} plan`,
        description: `${this.$options.filters.toNumber(
          mauQuota,
        )} active users per month, up to ${this.$options.filters.toNumber(
          agentQuota,
        )} agents`,
        price,
      };
    },
    generateOrderMau(price, subTotal, mauQuota) {
      return {
        image: MauIcon,
        title: 'Monthly Active Users',
        description: `${this.$options.filters.toCurrency(
          price,
        )} (per 1 MAU) x ${this.$options.filters.toNumber(mauQuota)}`,
        price: subTotal,
      };
    },
    generateOrderItem(type, data) {
      switch (type) {
        case 'PlanOrder':
          return this.generateOrderPlan(
            data.name,
            data.mau_quota,
            data.agent_quota,
            data.price,
          );
        case 'MauOrder':
          return this.generateOrderMau(
            data.price,
            data.sub_total,
            data.mau_quota,
          );
        default:
          return {};
      }
    },
    async fetchOrderReview(orderId) {
      try {
        const orderData = await this.getOrderId(orderId);
        const orderable = orderData.orderable;
        this.orderSummary = {
          total_amount: orderable.total_amount,
          ...orderData,
        };
        const order = this.generateOrderItem(orderData.type, orderable);
        this.form.orders = [{ ...order }];
      } catch (e) {
        bus.$emit('newToastMessage', e.message);
        await this.$router.push({ name: 'billing_credits' });
      }
    },
  },
};
