<template>
  <div class="onboarding-container">
    <div v-show="onboardingOnProgress" class="loading-container">
      <div class="loading-wrapper">
        <loading />
        <span>{{ $t('NEW_ONBOARDING.API.LOADING') }}</span>
      </div>
    </div>
    <div class="onboarding">
      <img src="~dashboard/assets/images/kata/omni.svg" />
      <div class="onboarding-wrapper">
        <wizard
          :back-button="$t('NEW_ONBOARDING.ACTION.PREVIOUS')"
          :next-button="$t('NEW_ONBOARDING.ACTION.NEXT')"
          :finish-button="$t('NEW_ONBOARDING.ACTION.FINISH')"
          :hide-first-step="true"
          @on-submit="onSubmit"
        >
          <tab-content :before-change="() => validateStep('goal')">
            <goal
              ref="goal"
              :goal-item="onboardingQuestions.goal"
              @on-validate="mergePartialData"
            />
          </tab-content>
          <tab-content :before-change="() => validateStep('business')">
            <business
              ref="business"
              :industry-item="onboardingQuestions.industry"
              :employee-item="onboardingQuestions.employee_number"
              @on-validate="mergePartialData"
            />
          </tab-content>
          <tab-content :before-change="() => validateStep('yourself')">
            <yourself ref="yourself" @on-validate="mergePartialData" />
          </tab-content>
        </wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { TabContent } from 'vue-form-wizard';
import Cookies from 'js-cookie';

import Wizard from 'dashboard/components/kata/wizard/Wizard';
import Loading from 'dashboard/components/kata/loading/Loading';
import Onboarding from 'dashboard/api/onboarding';
import Auth from 'dashboard/api/auth';
import { frontendURL } from 'dashboard/helper/URLHelper';
import alertMixin from 'shared/mixins/alertMixin';
import { prepareToSave } from './utils/prepareToSave';

import Goal from './steps/Goal.vue';
import Business from './steps/Business.vue';
import Yourself from './steps/Yourself.vue';

export default {
  components: {
    Wizard,
    TabContent,
    Goal,
    Business,
    Yourself,
    Loading,
  },
  mixins: [alertMixin],
  data() {
    return {
      data: {},
      onboardingOnProgress: false,
      onboardingQuestions: {},
    };
  },
  created() {
    this.getQuestions();
    this.createHubspot();
  },
  methods: {
    validateStep(name) {
      const refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    mergePartialData(model, isValid) {
      let modelTmp = {};
      if (isValid) {
        if (model.goal) {
          modelTmp = model;
          this.updateHubspot({ goal: model.goal.onboarding_answer_label });
        }
        if (model.business) {
          modelTmp = model.business;
          this.updateHubspot({
            company_name: model.business.account_name,
            industry: model.business.industry.onboarding_answer_label,
            company_size:
              model.business.employee_number.onboarding_answer_label,
          });
        }
        if (model.yourself) {
          modelTmp = model.yourself;
          this.updateHubspot({
            full_name: model.yourself.user_full_name,
            phone_number:
              model.yourself.phone_number_country_code +
              model.yourself.phone_number,
          });
        }

        this.data = { ...this.data, ...modelTmp };
      }
    },
    async onSubmit() {
      this.onboardingOnProgress = true;
      try {
        const req = prepareToSave(this.data);
        const response = await Onboarding.onboarding(req);
        if (response.status === 200) {
          window.location = frontendURL(
            `accounts/${response.data.data.account_id}/getting-started`,
          );
        }
      } catch (error) {
        let errorMessage = this.$t('NEW_ONBOARDING.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
        if (error.response.status === 403) {
          window.location.reload();
        }
      } finally {
        this.onboardingOnProgress = false;
      }
    },
    async getQuestions() {
      try {
        let questionsMapped = {};
        const response = await Onboarding.onboardingQuestions('0');
        response.data.forEach(question => {
          questionsMapped[question.slug] = question;
        });
        this.onboardingQuestions = questionsMapped;
      } catch (error) {
        let errorMessage = this.$t('NEW_ONBOARDING.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      } finally {
        this.onboardingOnProgress = false;
      }
    },
    async createHubspot() {
      try {
        const response = await Onboarding.onboardingCreateHubspot({
          email: Auth.getEmail(),
        });
        Cookies.set('id_onboarding', response.data.id);
      } catch (error) {
        let errorMessage = this.$t('NEW_ONBOARDING.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      }
    },
    async updateHubspot(properties) {
      try {
        await Onboarding.onboardingUpdateHubspot({
          id: Cookies.getJSON('id_onboarding'),
          properties: properties,
        });
      } catch (error) {
        let errorMessage = this.$t('NEW_ONBOARDING.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>

<style lang="scss">
.onboarding {
  padding: 20px 24px;
  &-wrapper {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  &-title {
    display: block;
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-black);
    color: var(--grey-08);
    line-height: var(--space-medium);
    margin-bottom: var(--space-medium);
  }
}

.loading {
  &-container {
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    background: var(--white);
    z-index: 1000;
    width: 100%;
    height: 100%;

    span {
      display: block;
      margin-top: 24px;
      color: var(--grey-07);
    }
  }
  &-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
