/* global axios */
import ApiClient from '../ApiClient';

class InstagramChannel extends ApiClient {
  constructor() {
    super('channels/instagram_channel', { accountScoped: true });
  }

  create(params) {
    return axios.post(
      `${this.url.replace(this.resource, '')}callbacks/register_instagram_page`,
      params,
    );
  }

  reauthorizeFacebookPage({ omniauthToken, inboxId }) {
    return axios.post(`${this.baseUrl()}/callbacks/reauthorize_page`, {
      omniauth_token: omniauthToken,
      inbox_id: inboxId,
    });
  }
}

export default new InstagramChannel();
