/* global axios */

import ApiClient from './ApiClient';

class TrialInfo extends ApiClient {
  constructor() {
    super(undefined, {
      accountScoped: true,
    });
  }

  get() {
    return axios.get(`${this.url}/plan_expired_info`).catch(error => {
      if (error.response) {
        throw Error(error.response.data.message);
      }
      throw error;
    });
  }

  getPlanInfo() {
    return axios.get(`${this.url}/plan_subscription_info`).catch(error => {
      if (error.response) {
        throw Error(error.response.data.message);
      }
      throw error;
    });
  }

  upgradeAccountPlanOrder(data) {
    return axios.post(`${this.url}/plan_orders`, data).catch(error => {
      if (error.response) {
        throw Error(error.response.data.message);
      }
      throw error;
    });
  }

  upgradeAccountMau(data) {
    return axios.post(`${this.url}/mau_orders`, data).catch(error => {
      if (error.response) {
        throw Error(error.response.data.message);
      }
      throw error;
    });
  }

  cancelOrderAccountPlanOrder(data) {
    return axios.post(`${this.url}/plan_orders/cancel`, data).catch(error => {
      if (error.response) {
        throw Error(error.response.data.message);
      }
      throw error;
    });
  }
}

export default new TrialInfo();
