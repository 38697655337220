<template>
  <div class="icebreaker">
    <div class="icebreaker__content">
      <label class="medium-9 columns">
        {{ $t('ICE_BREAKER.LABELS') }}
      </label>
      <textarea
        v-model="tempIceBreaker"
        rows="5"
        type="text"
        label="Add ice breaker"
      />
      <button
        type="button"
        class="button icebreaker__submit"
        :disabled="iceBreakerInput.length > 3 || !tempIceBreaker"
        @click="createIceBreaker"
      >
        <span class="icebreaker__submit--content">
          <img src="~dashboard/assets/icons/ic_plus.svg" alt="" />
          {{ $t('ICE_BREAKER.NEW_ICEBREAKER') }}
        </span>
      </button>
      <div class="icebreaker__status">
        {{ 4 - iceBreakerInput.length }} {{ $t('ICE_BREAKER.REMAINING') }}
      </div>
      <div class="icebreaker__item">
        <icebreaker-item
          v-for="ice in iceBreakerInput"
          :key="ice"
          @click="removeItem(ice)"
        >
          {{ ice }}
        </icebreaker-item>
      </div>
    </div>
    <div class="icebreaker__content">
      <h3 class="icebreaker__title">
        {{ $t('ICE_BREAKER.TITLE') }}
      </h3>
      <p class="icebreaker__description">
        {{ $t('ICE_BREAKER.DESCRIPTION') }}
      </p>
      <img
        class="icebreaker__image"
        src="~dashboard/assets/images/ice-breaker.png"
      />
    </div>
  </div>
</template>

<script>
import IcebreakerItem from '../IcebreakerItem.vue';
import alertMixin from 'shared/mixins/alertMixin';
import IceBreakerAPI from '../../../../../api/inbox/iceBreaker';

export default {
  components: { IcebreakerItem },
  mixins: [alertMixin],
  props: {
    inboxId: {
      type: String,
      default: '',
    },
  },

  data() {
    return { iceBreakerInput: [], tempIceBreaker: '' };
  },
  watch: {
    selectedTabKey(value) {
      if (value === 'ice_breaker') {
        this.fetchIceBreaker();
      }
    },
  },

  created() {
    this.fetchIceBreaker();
  },

  methods: {
    async fetchIceBreaker() {
      try {
        const response = await IceBreakerAPI.get(this.inboxId);
        this.iceBreakerInput = response.data.ice_breakers;
      } catch (error) {
        //  Handle error
      }
    },
    async createIceBreaker() {
      try {
        const response = await IceBreakerAPI.create(this.inboxId, {
          ice_breakers: [...this.iceBreakerInput, this.tempIceBreaker],
        });
        this.iceBreakerInput = response.data.ice_breakers;
        this.tempIceBreaker = '';
        this.showAlert(this.$t('ICE_BREAKER.CREATE.SUCCESS_MESSAGE'));
      } catch {
        this.showAlert(this.$t('ICE_BREAKER.CREATE.ERROR_MESSAGE'));
      }
    },
    async removeItem(idx) {
      try {
        const data = this.iceBreakerInput.filter((value) => value !== idx);
        if (data.length === 0) {
          await IceBreakerAPI.delete(this.inboxId);
          this.iceBreakerInput = [];
        } else {
          const response = await IceBreakerAPI.create(this.inboxId, {
            ice_breakers: data,
          });
          this.iceBreakerInput = response.data.ice_breakers;
        }
        this.tempIceBreaker = '';
        this.showAlert(this.$t('ICE_BREAKER.DELETE.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('ICE_BREAKER.DELETE.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
