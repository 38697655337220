<template>
  <div class="plan-card">
    <div class="type">
      {{ plan.name }}
    </div>
    <div class="pricing">
      <div v-if="isPriceCustom()">Custom</div>
      <div v-else-if="isPriceFree()">Free</div>
      <div v-else>
        {{ plan.price | toCurrency }}
      </div>
    </div>
    <div class="description">
      <div v-if="isPriceCustom()">
        For those seeking enterprise-grade controls & support
      </div>
      <div v-else>
        Unlimited conversations with up to
        {{ plan.mau_quota | toNumber }} active users per month
      </div>
    </div>
    <div>
      <woot-button
        class-names="expanded"
        :disabled="active"
        @click="handleSelectPlan"
      >
        <span v-if="active"> Your current plan </span>
        <span v-else-if="isPriceCustom()"> Talk to Sales </span>
        <span v-else> Select </span>
      </woot-button>
    </div>
    <div class="features">
      <ul v-if="isPriceCustom()">
        <li>Unlimited team members</li>
        <li>Access to all features</li>
        <li>Access to all channels</li>
        <li>24/7 support</li>
      </ul>
      <ul v-else>
        <li>{{ plan.agent_quota }} agent only</li>
        <li>Access to all features</li>
        <li>Access to all channels</li>
        <li>Weekday support</li>
      </ul>
    </div>
  </div>
</template>

<script>
import WootButton from '../../../../components/ui/WootButton';

export default {
  name: 'Plan',
  components: { WootButton },
  props: ['plan', 'active', 'openModal'],
  methods: {
    isPriceCustom() {
      return (
        this.plan.price === 0 &&
        this.plan.mau_quota === 0 &&
        this.plan.duration === 0 &&
        this.plan.agent_quota === 0
      );
    },
    isPriceFree() {
      return this.plan.price === 0;
    },
    handleSelectPlan() {
      if (this.active) return;
      if (this.isPriceCustom()) {
        this.openModal();
      } else {
        this.$emit('input', this.plan);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-card {
  background: #fafbfd;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;

  div:not(:last-child) {
    margin-bottom: 24px;
  }

  .type {
    font-weight: 600;
    text-transform: uppercase;
    color: #6a7a8a;
  }

  .pricing {
    font-weight: 700;
    font-size: 20px;
  }

  .features {
    text-align: left;
  }
}
</style>
