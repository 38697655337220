var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medium-12 column"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12 align-self-top"},[_c('span',{staticClass:"onboarding-title"},[_vm._v(_vm._s(_vm.$t('NEW_ONBOARDING.TELL.YOURSELF')))])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12 form-group"},[_c('woot-input',{class:{ error: _vm.$v.form.user_full_name.$error },attrs:{"label":_vm.$t('NEW_ONBOARDING.YOURSELF.NAME.LABEL'),"type":"text","placeholder":_vm.$t('NEW_ONBOARDING.YOURSELF.NAME.PLACEHOLDER'),"error":_vm.$v.form.user_full_name.$error
            ? _vm.$t('NEW_ONBOARDING.YOURSELF.NAME.ERROR')
            : ''},on:{"blur":function($event){_vm.$v.form.user_full_name.$touch;
          _vm.$customGTM(
            'Event',
            'Onboarding - User Profile - Name',
            'Text',
            'User - Name'
          );}},model:{value:(_vm.form.user_full_name),callback:function ($$v) {_vm.$set(_vm.form, "user_full_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.user_full_name"}})],1),_vm._v(" "),_c('div',{staticClass:"small-12 form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('NEW_ONBOARDING.YOURSELF.PHONE.LABEL')))]),_vm._v(" "),_c('div',{staticClass:"row phone"},[_c('div',{staticClass:"small-2 form-group"},[_c('dropdown',{attrs:{"value":"+62","with-flag":true,"options":_vm.codes,"placeholder":_vm.$t('NEW_ONBOARDING.YOURSELF.PHONE_CODE.PLACEHOLDER')},on:{"click":_vm.selectPhoneCode}})],1),_vm._v(" "),_c('div',{staticClass:"small-10 form-group"},[_c('woot-input',{class:{ error: _vm.$v.form.phone_number.$error },attrs:{"type":"text","placeholder":_vm.$t('NEW_ONBOARDING.YOURSELF.PHONE.PLACEHOLDER'),"error":_vm.$v.form.phone_number.$error
                ? _vm.$t('NEW_ONBOARDING.YOURSELF.PHONE.ERROR')
                : ''},on:{"blur":function($event){_vm.$v.form.phone_number.$touch;
              _vm.$customGTM(
                'Event',
                'Onboarding - User Profile - Phone',
                'Text',
                'User - Phone'
              );}},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.phone_number"}}),_vm._v(" "),(!_vm.$v.form.phone_number.$error)?_c('span',{staticClass:"info"},[_c('inline-svg',{staticClass:"icon",attrs:{"src":require('../../../assets/icons/ic_info_purple.svg')}}),_vm._v(" "),_c('span',{staticClass:"info-content"},[_vm._v("\n              "+_vm._s(_vm.$t('NEW_ONBOARDING.YOURSELF.PHONE.INFO')))])],1):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }