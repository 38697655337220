var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status"},[_c('div',{staticClass:"status-view"},[_c('availability-status-badge',{attrs:{"status":_vm.currentUserAvailabilityStatus}}),_vm._v(" "),_c('div',{staticClass:"status-view--title"},[_vm._v("\n      "+_vm._s(_vm.availabilityDisplayLabel)+"\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"status-change"},[_c('transition',{attrs:{"name":"menu-slide"}},[(_vm.isStatusMenuOpened)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeStatusMenu),expression:"closeStatusMenu"}],staticClass:"dropdown-pane dropdowm--top"},[_c('woot-dropdown-menu',_vm._l((_vm.availabilityStatuses),function(status){return _c('woot-dropdown-item',{key:status.value,staticClass:"status-items"},[_c('woot-button',{attrs:{"variant":"clear","size":"small","color-scheme":"secondary","class-names":"status-change--dropdown-button","is-disabled":status.disabled},on:{"click":function($event){_vm.changeAvailabilityStatus(status.value);
                _vm.$customGTM(
                  'Event',
                  ("Getting Started - Status " + (status.value)),
                  'Button',
                  status.value
                );}}},[_c('availability-status-badge',{attrs:{"status":status.value}}),_vm._v("\n              "+_vm._s(status.label)+"\n            ")],1)],1)}),1)],1):_vm._e()]),_vm._v(" "),_c('woot-button',{attrs:{"variant":"clear","color-scheme":"secondary","class-names":"status-change--change-button link"},on:{"click":function($event){_vm.openStatusMenu();
        _vm.$customGTM(
          'Event',
          'Getting Started - Status Change',
          'Button',
          'Change'
        );}}},[_vm._v("\n      "+_vm._s(_vm.$t('SIDEBAR_ITEMS.CHANGE_AVAILABILITY_STATUS'))+"\n    ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }