import SettingsContent from '../../Wrapper';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { headerSettingOptions } from '../integrations.routes';

import List from './Index';
import Create from './create/Create';
import Update from './update/Update';
import View from './view/View';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/integrations/agent-bot'),
      props: params => headerSettingOptions(params),
      component: SettingsContent,
      children: [
        {
          path: '',
          component: List,
          name: 'settings_integrations_agent_bot',
          roles: ['administrator'],
        },
        {
          path: 'create',
          component: Create,
          name: 'settings_integrations_agent_bot_create',
          roles: ['administrator'],
        },
        {
          path: ':agent_bot_id/update',
          component: Update,
          name: 'settings_integrations_agent_bot_update',
          roles: ['administrator'],
        },
        {
          path: ':agent_bot_id/view',
          component: View,
          name: 'settings_integrations_agent_bot_view',
          roles: ['administrator'],
        },
      ],
    },
  ],
};
