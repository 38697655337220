<template>
  <div class="box-content column">
    <div class="container">
      <woot-loading-state v-if="isFetchOrder" />
      <template v-else>
        <div v-if="showWizard" class="header">
          <Stepper
            :steps="['Select payment method', 'Review order']"
            :current-index="1"
          />
        </div>
        <OrderReview :orders="orders" :order-summary="orderSummary" />
        <div v-if="showWizard" class="footer row align-right">
          <woot-button
            class-names="nice outline"
            color-scheme=""
            @click="handleCancelOrder(true)"
          >
            Cancel order
          </woot-button>
          <woot-button @click="$router.go(0)">
            Reload page
          </woot-button>

          <AlertModal
            :show="showModalCancelOrder"
            message="Cancel order? "
            reject-text="Cancel"
            confirm-text="Continue"
            @on-confirm="handleConfirmCancelOrderModal"
            @on-close="handleCancelOrder(false)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import OrderReview from '../../../../modules/billing/components/orders/OrderReview';
import ordersMixin from '../../../../../shared/mixins/ordersMixin';
import Stepper from '../../../../modules/billing/components/Stepper';
import AlertModal from 'dashboard/components/widgets/modal/AlertModal';
import TrialInfoAPI from 'dashboard/api/trialInfo';

export default {
  name: 'BillingOrderDetail',
  components: { Stepper, OrderReview, AlertModal },
  mixins: [ordersMixin],
  data() {
    return {
      orders: [],
      orderSummary: {},
      showWizard: false,
      showModalCancelOrder: false,
      isFetchOrder: false,
    };
  },
  activated() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.isFetchOrder = true;
        const params = this.$route.params;
        if (!params.orderId) {
          throw new Error('order not found');
        }
        const orderData = await this.getOrderId(params.orderId);
        const orderable = orderData.orderable;
        this.orderSummary = {
          total_amount: orderable.total_amount,
          ...orderData,
        };
        const order = this.generateOrderItem(orderData.type, orderable);
        this.orders = [{ ...order }];
        this.showWizard = orderData.status === 'pending';
      } catch (e) {
        bus.$emit('newToastMessage', e.message);
        await this.$router.push({ name: 'billing_credits' });
      } finally {
        this.isFetchOrder = false;
      }
    },
    handleCancelOrder(status) {
      this.showModalCancelOrder = status;
    },
    async handleConfirmCancelOrderModal() {
      try {
        const payload = {
          id: this.orderSummary.id,
        };
        await TrialInfoAPI.cancelOrderAccountPlanOrder(payload);
        await this.$router.push({ name: 'billing_credits' });
        this.showModalCancelOrder = false;
      } catch (e) {
        bus.$emit('newToastMessage', e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box-content {
  overflow: auto;
  padding: 40px 65px;

  .header {
    padding: 32px 48px;
    background-color: #f5f6f8;
  }

  .footer {
    border-top: 1px solid #eff2f5;
    padding: 24px 48px;
    gap: 10px;
  }

  .container {
    .plan-container {
      padding-top: 24px;
    }

    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid #e5eaef;
  }
}
</style>
