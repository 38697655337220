import { frontendURL } from 'dashboard/helper/URLHelper';
import GettingStarted from './GettingStarted';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/getting-started'),
      name: 'getting_started',
      roles: ['administrator', 'agent'],
      component: GettingStarted,
    },
  ],
};
