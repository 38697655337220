<template>
  <div class="channels-item__wrapper" :class="{ inactive: !isActive }">
    <img
      v-if="channel.key === 'facebook'"
      src="~dashboard/assets/images/channels/facebook.png"
    />
    <img
      v-if="channel.key === 'twitter'"
      src="~dashboard/assets/images/channels/twitter.png"
    />
    <img
      v-if="channel.key === 'telegram'"
      src="~dashboard/assets/images/channels/telegram.png"
    />
    <img
      v-if="channel.key === 'api' && !channel.thumbnail"
      src="~dashboard/assets/images/channels/api.png"
    />
    <img
      v-if="channel.key === 'api' && channel.thumbnail"
      :src="channel.thumbnail"
    />
    <img
      v-if="channel.key === 'email'"
      src="~dashboard/assets/images/channels/email.png"
    />
    <img
      v-if="channel.key === 'line'"
      src="~dashboard/assets/images/channels/line.png"
    />
    <img
      v-if="channel.key === 'website'"
      src="~dashboard/assets/images/channels/website.png"
    />
    <img
      v-if="channel.key === 'twilio'"
      src="~dashboard/assets/images/channels/twilio.png"
    />
    <img
      v-if="channel.key === 'instagram'"
      src="~dashboard/assets/images/kata/channel-instagram.svg"
    />
    <img
      v-if="channel.key === 'whatsapp'"
      src="~dashboard/assets/images/kata/channel-whatsapp.svg"
    />
    <h3 class="channels-item__title">
      {{ channel.name }}
    </h3>
    <p v-if="channel.description" class="channels-item__description">
      {{ channel.description }}
    </p>
    <kata-button
      variant="outline"
      :disabled="!isAdmin || !isConfirmed"
      class="full-width"
      @click="onItemClick"
    >
      {{ $t('INBOX_MGMT.ADD.AUTH.CONNECT') }}
    </kata-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KataButton from 'dashboard/components/buttons/Button';
import adminMixin from '../../mixins/isAdmin';

export default {
  components: {
    KataButton,
  },
  mixins: [adminMixin],
  props: {
    channel: {
      type: Object,
      required: true,
    },
    enabledFeatures: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    isConfirmed() {
      return this.currentUser.confirmed;
    },
    isActive() {
      const { key } = this.channel;
      if (Object.keys(this.enabledFeatures).length === 0) {
        return false;
      }
      if (key === 'facebook') {
        return this.enabledFeatures.channel_facebook;
      }
      if (key === 'twitter') {
        return this.enabledFeatures.channel_twitter;
      }
      if (key === 'email') {
        return this.enabledFeatures.channel_email;
      }
      return ['website', 'api', 'instagram', 'whatsapp'].includes(key);
    },
  },
  methods: {
    onItemClick() {
      if (this.isActive) {
        this.$emit('channel-item-click', this.channel.key);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.channels {
  &-item {
    &__wrapper {
      padding: 24px;
      border: 1px solid var(--grey-04);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:last-child {
        margin: 0;
      }

      img {
        margin-bottom: 16px;
        max-width: 45px;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 4px;
    }

    &__description {
      font-size: 14px;
      line-height: 24px;
      color: var(--grey-07);
      margin-bottom: 16px;
    }
  }
}
</style>
