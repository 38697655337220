<template>
  <div id="app" class="app-wrapper app-root">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <woot-snackbar-box />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import WootSnackbarBox from './components/SnackbarContainer';
import { accountIdFromPathname } from './helper/URLHelper';
import router from '../dashboard/routes';
import MyGTM from './helper/gtmHelper';
import Vue from 'vue';
import './helper/globalFilter';

export default {
  name: 'App',

  components: {
    WootSnackbarBox,
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
    }),
  },
  created() {
    const { default: VueGtm } = require('@gtm-support/vue2-gtm');
    Vue.use(VueGtm, {
      id: 'GTM-NH7MGD9',
      enabled: true,
      vueRouter: router,
      loadScript: true,
    });
    Vue.use(MyGTM);
  },
  mounted() {
    this.$store.dispatch('setUser');
    this.setLocale(window.kataOmnichatConfig.selectedLocale);
    this.initializeAccount();
  },

  methods: {
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },

    async initializeAccount() {
      const { pathname } = window.location;
      const accountId = accountIdFromPathname(pathname);

      if (accountId) {
        await this.$store.dispatch('accounts/get');
        const { locale } = this.getAccount(accountId);
        this.setLocale(locale);
      }
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
.dummy {
  color: inherit;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
