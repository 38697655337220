<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="agent-setting__warning">
    <img src="~dashboard/assets/icons/ic_critical.svg" />
    <div>
      <div class="agent-setting__title">
        {{ $t('AGENT_MGMT.LIMIT.TITLE') }}
      </div>
      <div>
        {{ $t('AGENT_MGMT.LIMIT.DESCRIPTION') }}
        <span
          ><router-link :to="`/app/accounts/${accountId}/settings/billing`">{{
            $t('AGENT_MGMT.LIMIT.SUBSCRIPTION')
          }}</router-link></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
};
</script>

<style lang="scss" scoped>
.agent {
  &-setting {
    &__title {
      font-weight: bold;
      margin-bottom: 8px;
    }
    &__warning {
      display: flex;
      align-items: center;
      background: var(--red-light);
      border: 1px solid var(--red-dark);
      border-radius: 4px;
      margin-top: 12px;
      margin-bottom: 16px;
      margin-right: 22px;
      padding: 16px 18px;
      font-size: 14px;
      line-height: 24px;
      img {
        margin-right: 20px;
      }
      a {
        text-decoration: underline;
      }
      b,
      strong {
        margin-left: 4px;
      }
    }
  }
}
</style>
