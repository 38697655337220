export const localizations = [
  {
    name: 'English (en)',
    iso_639_3_code: 'eng',
    iso_639_1_code: 'en',
    enabled: true,
  },
  {
    name: 'Bahasa Indonesia (id)',
    iso_639_3_code: 'ind',
    iso_639_1_code: 'id',
    enabled: true,
  },
];
