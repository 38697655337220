<template>
  <div v-if="orders.length > 0" class="plan-container with-wizard-footer">
    <div v-if="title" class="row header">
      <h3>{{ title }}</h3>
    </div>
    <div class="orders">
      <OrderDetail :orders="orders" />
    </div>
    <div class="billing-contact">
      <BillingContact :billing-data="billingInfo" />
    </div>
    <div>
      <PaymentMethod @changeBank="handleChangeBank" />
    </div>
    <woot-modal
      :show.sync="showBillingContactModal"
      :on-close="() => showBillingContact(false)"
      :close-on-backdrop-click="false"
    >
      <add-billing-info :billing-data="billingInfo" />
    </woot-modal>
  </div>
</template>

<script>
import OrderDetail from './OrderDetail';
import PaymentMethod from '../payment-method/PaymentMethod';
import BillingContact from '../billing-contact/BillingContact';
import AddBillingInfo from '../../../../routes/dashboard/settings/billing-credits/components/AddBillingInfo.vue';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'OrderConfirmation',
  components: {
    OrderDetail,
    PaymentMethod,
    BillingContact,
    AddBillingInfo,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    orders: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      bank: undefined,
      showBillingContactModal: false,
      billingInformation: undefined,
    };
  },
  computed: {
    ...mapGetters({
      billingInfo: 'subscription/getBillingInfo',
    }),
  },
  mounted() {
    this.init();
  },
  activated() {
    this.init();
  },
  validations: {
    bank: {
      required,
    },
    billingInformation: {
      required,
    },
    form: ['bank', 'billingInformation'],
  },
  methods: {
    init() {
      this.initBilling();
      bus.$on('openModalBillingInfo', () => {
        this.showBillingContact(true);
      });
      bus.$on('closeModalBillingInfo', () => {
        this.showBillingContact(false);
      });
      this.validate();
    },
    handleChangeBank(bank) {
      this.bank = bank;
      this.$emit('change', bank);
    },
    validate() {
      const isValid = !this.$v.form.$invalid;
      this.$v.form.$touch();
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
    showBillingContact(show) {
      this.showBillingContactModal = show;
      this.billingInformation = this.billingInfo;
      this.validate();
    },
    async initBilling() {
      await this.$store.dispatch('subscription/getBillingInfo');
      this.billingInformation = this.billingInfo;
      this.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-container {
  padding: 48px;

  .header {
    h3 {
      margin-bottom: 36px;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .orders,
  .billing-contact {
    margin-bottom: 48px;
  }
}
</style>
