/* eslint no-console: 0 */
/* global axios */
/* eslint no-undef: "error" */

import endPoints from './endPoints';

export default {
  onboarding(creds) {
    const urlData = endPoints('onboarding');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .post(urlData.url, creds)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  onboardingQuestions(question_type) {
    const urlData = endPoints('onboardingQuestion');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .get(urlData.url, {
          params: {
            question_type,
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  onboardingCreateHubspot(email) {
    const urlData = endPoints('onboardingCreateHubspot');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .post(urlData.url, email)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  onboardingUpdateHubspot(id, properties) {
    const urlData = endPoints('onboardingUpdateHubspot');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .put(urlData.url, id, properties)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  checkInstagramEligibility(accountId, omniauth_token) {
    const urlData = endPoints('checkInstagramEligibility');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .get(urlData.url(accountId), {
          params: {
            omniauth_token,
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
};
