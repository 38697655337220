<template>
  <div class="view-box fill-height">
    <activate-comment
      v-if="featureFlags.conversation.instagram.commentReply"
      :is-comment-mode="isCommentMode"
      @set-comment-mode="handleCommentMode"
    />
    <banner-policy
      v-if="
        (channelMeta === 'Channel::InstagramPage' ||
          channelMeta === 'Channel::Whatsapp') &&
          !currentChat.can_reply &&
          !isCommentMode
      "
      :channel-meta="channelMeta"
    />
    <banner-expired
      v-if="isPlanExpired()"
      :title="$t('CONVERSATION.EXPIRED')"
    />
    <ConversationAlert
      v-if="
        channelMeta === 'Channel::Whatsapp' && getInboxesWhatsappCredit.isWarned
      "
      variant="alert"
    >
      {{ $t('CONVERSATION.WHATSAPP_INSUFFICIENT_CREDITS') }}
      <router-link
        :to="{
          name: 'settings_inbox_show',
          params: { inboxId: currentChat.inbox_id },
          query: { tab: 'credits' },
        }"
        class="link"
      >
        here
      </router-link>
    </ConversationAlert>
    <banner-expired
      v-if="isMauLimit()"
      :show-link="false"
      :title="$t('BANNER.MAU_WARNING.TITLE')"
    />
    <div
      v-if="!currentChat.can_reply && isATwilioWhatsappChannel"
      class="banner messenger-policy--banner"
    >
      <span>
        {{ $t('CONVERSATION.TWILIO_WHATSAPP_CAN_REPLY') }}
        <a
          :href="twilioWhatsAppReplyPolicy"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          {{ $t('CONVERSATION.TWILIO_WHATSAPP_24_HOURS_WINDOW') }}
        </a>
      </span>
    </div>

    <template v-if="isCommentMode">
      <comments :conversation-id="currentChat.id" />
    </template>
    <template v-else>
      <div v-if="isATweet" class="banner">
        <span v-if="!selectedTweetId">
          {{ $t('CONVERSATION.LAST_INCOMING_TWEET') }}
        </span>
        <span v-else>
          {{ $t('CONVERSATION.REPLYING_TO') }}
          {{ selectedTweet }}
        </span>
        <button
          v-if="selectedTweetId"
          class="banner-close-button"
          @click="removeTweetSelection"
        >
          <i
            v-tooltip="$t('CONVERSATION.REMOVE_SELECTION')"
            class="ion-close"
          />
        </button>
      </div>
      <ul class="conversation-panel">
        <transition name="slide-up">
          <li class="spinner--container">
            <span v-if="shouldShowSpinner" class="spinner message" />
          </li>
        </transition>
        <message
          v-for="readMessage in getReadMessages"
          :key="readMessage.id"
          :data="readMessage"
          :channel="channelMeta"
          :additional-attributes="channelAdditionalAttributes"
          :is-a-tweet="isATweet"
        />
        <li v-show="getUnreadCount != 0" class="unread--toast">
          <span class="text-uppercase">
            {{ getUnreadCount }}
            {{
              getUnreadCount > 1
                ? $t('CONVERSATION.UNREAD_MESSAGES')
                : $t('CONVERSATION.UNREAD_MESSAGE')
            }}
          </span>
        </li>
        <message
          v-for="unReadMessage in getUnReadMessages"
          :key="unReadMessage.id"
          :data="unReadMessage"
          :is-a-tweet="isATweet"
        />
        <li v-if="selectedQuickReply.length" class="quick--container">
          <div v-for="item in selectedQuickReply" :key="item">
            <bubble-setting :read-only="true" class="quick--item">
              {{ item }}
            </bubble-setting>
          </div>
          <span class="quick--action">
            <button class="cancel" @click="handleCancelQuickReply">
              <img src="~dashboard/assets/icons/ic_cancel.svg" /> Cancel
            </button>
            <button class="done" :disabled="!message" @click="submitQuickReply">
              <img src="~dashboard/assets/icons/ic_tick.svg" /> Done
            </button>
          </span>
        </li>
      </ul>
      <div class="conversation-footer">
        <div v-if="isAnyoneTyping" class="typing-indicator-wrap">
          <div class="typing-indicator">
            {{ typingUserNames }}
            <img
              class="gif"
              src="~dashboard/assets/images/typing.gif"
              alt="Someone is typing"
            />
          </div>
        </div>
        <ReplyBox
          v-show="!isBot"
          :selected-quick-reply="selectedQuickReply"
          :quick-reply-action="handleClickQuickReply"
          :submit-quick-reply="submitQuickReply"
          :conversation-id="currentChat.id"
          :in-reply-to="selectedTweetId"
          :is-plan-expired="isPlanExpired()"
          @message-has-value="setMessageIsHaveValue"
          @scrollToMessage="scrollToBottom"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ReplyBox from './ReplyBox';
import Message from './Message';
import conversationMixin from '../../../mixins/conversations';
import { getTypingUsersText } from '../../../helper/commons';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import BannerPolicy from './components/BannerPolicy';
import ActivateComment from './comment/ActivateComment';
import Comments from './comment/Comments';
import BubbleSetting from '../../../routes/dashboard/settings/inbox/components/BubbleSetting.vue';
import { REPLY_POLICY } from 'shared/constants/links';
import inboxMixin from 'shared/mixins/inboxMixin';
import BannerExpired from './components/BannerExpired';
import ConversationAlert from './components/ConversationAlert';

export default {
  components: {
    ConversationAlert,
    Message,
    ReplyBox,
    BannerPolicy,
    ActivateComment,
    Comments,
    BubbleSetting,
    BannerExpired,
  },
  mixins: [conversationMixin, inboxMixin],
  props: {
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
    isBot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingPrevious: true,
      heightBeforeLoad: null,
      conversationPanel: null,
      selectedTweetId: null,
      isCommentMode: false,
      selectedQuickReply: [],
      message: '',
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      listLoadingStatus: 'getAllMessagesLoaded',
      getUnreadCount: 'getUnreadCount',
      loadingChatList: 'getChatListLoadingStatus',
      featureFlags: 'flags/featureFlags',
      getSubscriptionNotify: 'subscription/getSubscriptionNotify',
      getCurrentSubscription: 'subscription/getCurrentSubscription',
      getSubscriptionInfoPlan: 'subscription/getSubscriptionInfoPlan',
      getInboxesWhatsappCredit: 'inboxes/getInboxesWhatsappCredit',
    }),
    typingUsersList() {
      const userList = this.$store.getters[
        'conversationTypingStatus/getUserList'
      ](this.currentChat.id);
      return userList;
    },
    isAnyoneTyping() {
      const userList = this.typingUsersList;
      return userList.length !== 0;
    },
    typingUserNames() {
      const userList = this.typingUsersList;
      if (this.isAnyoneTyping) {
        const userListAsName = getTypingUsersText(userList);
        return userListAsName;
      }
      return '';
    },
    getMessages() {
      const [chat] = this.allConversations.filter(
        c => c.id === this.currentChat.id,
      );
      return chat;
    },
    getReadMessages() {
      const chat = this.getMessages;
      return chat === undefined ? null : this.readMessages(chat);
    },
    getUnReadMessages() {
      const chat = this.getMessages;
      return chat === undefined ? null : this.unReadMessages(chat);
    },
    shouldShowSpinner() {
      return (
        (this.getMessages && this.getMessages.dataFetched === undefined) ||
        (!this.listLoadingStatus && this.isLoadingPrevious)
      );
    },
    shouldLoadMoreChats() {
      return !this.listLoadingStatus && !this.isLoadingPrevious;
    },
    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },
    isATweet() {
      return this.conversationType === 'tweet';
    },
    channelMeta() {
      return this.getMessages.meta.channel || this.getMessages.channel;
    },
    channelAdditionalAttributes() {
      return this.getMessages.additional_attributes;
    },
    selectedTweet() {
      if (this.selectedTweetId) {
        const { messages = [] } = this.getMessages;
        const [selectedMessage = {}] = messages.filter(
          message => message.id === this.selectedTweetId,
        );
        return selectedMessage.content || '';
      }
      return '';
    },
    facebookReplyPolicy() {
      return REPLY_POLICY.FACEBOOK;
    },
    twilioWhatsAppReplyPolicy() {
      return REPLY_POLICY.TWILIO_WHATSAPP;
    },
  },
  watch: {
    currentChat(newChat, oldChat) {
      if (newChat.id === oldChat.id) {
        return;
      }
      this.selectedTweetId = null;
    },
    $route() {
      this.selectedQuickReply = [];
    },
  },
  created() {
    bus.$on('scrollToMessage', () => {
      setTimeout(() => this.scrollToBottom(), 0);
      this.makeMessagesRead();
    });
    bus.$on(BUS_EVENTS.SET_TWEET_REPLY, selectedTweetId => {
      this.selectedTweetId = selectedTweetId;
    });
    bus.$on(BUS_EVENTS.CLOSE_COMMENT_MODE, () => {
      if (this.isCommentMode) {
        this.isCommentMode = false;
        setTimeout(() => this.addScrollListener(), 0);
      }
    });
    bus.$on(BUS_EVENTS.SET_MESSAGE_CONVERSATION, message => {
      this.message = message;
    });
  },
  mounted() {
    this.addScrollListener();
    this.$store.dispatch('subscription/get');
  },
  unmounted() {
    this.removeScrollListener();
  },
  methods: {
    addScrollListener() {
      this.conversationPanel = this.$el.querySelector('.conversation-panel');
      if (this.conversationPanel) {
        this.setScrollParams();
        this.conversationPanel.addEventListener('scroll', this.handleScroll);
        this.scrollToBottom();
        this.isLoadingPrevious = false;
      }
    },
    removeScrollListener() {
      this.conversationPanel.removeEventListener('scroll', this.handleScroll);
    },
    scrollToBottom() {
      this.conversationPanel.scrollTop = this.conversationPanel.scrollHeight;
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    setScrollParams() {
      this.heightBeforeLoad = this.conversationPanel.scrollHeight;
      this.scrollTopBeforeLoad = this.conversationPanel.scrollTop;
    },
    handleScroll(e) {
      this.setScrollParams();
      const dataFetchCheck =
        this.getMessages.dataFetched === true && this.shouldLoadMoreChats;
      if (
        e.target.scrollTop < 100 &&
        !this.isLoadingPrevious &&
        dataFetchCheck
      ) {
        this.isLoadingPrevious = true;
        this.$store
          .dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.getMessages.messages[0].id,
          })
          .then(() => {
            const heightDifference =
              this.conversationPanel.scrollHeight - this.heightBeforeLoad;
            this.conversationPanel.scrollTop =
              this.scrollTopBeforeLoad + heightDifference;
            this.isLoadingPrevious = false;
            this.setScrollParams();
          });
      }
    },
    makeMessagesRead() {
      this.$store.dispatch('markMessagesRead', { id: this.currentChat.id });
    },
    removeTweetSelection() {
      this.selectedTweetId = null;
    },
    handleCommentMode({ payload }) {
      this.isCommentMode = payload;
      if (!payload) {
        setTimeout(() => this.addScrollListener(), 0);
      }
    },
    handleClickQuickReply(item) {
      this.selectedQuickReply = item;
      setTimeout(() => this.addScrollListener(), 0);
    },
    handleCancelQuickReply() {
      this.selectedQuickReply = [];
    },

    async submitQuickReply() {
      if (this.message) {
        const data = {
          data: {
            content: this.message,
            instagram_quick_replies: this.selectedQuickReply,
          },
          conversationId: this.currentChat.id,
        };
        try {
          await this.$store.dispatch('sendMessageWithQuickReply', data);
          this.$emit('scrollToMessage');
          this.selectedQuickReply = [];
          bus.$emit(BUS_EVENTS.CLEAR_CONVERSATION);
        } catch (error) {
          // Error
        }
      }
    },
    setMessageIsHaveValue(value) {
      this.message = value;
    },
    isPlanExpired() {
      if (this.getSubscriptionInfoPlan.plan_name === 'Free') {
        return false;
      }
      return this.getSubscriptionNotify.isExpired;
    },
    isMauLimit() {
      if (
        this.getCurrentSubscription.plan !== 'Trial' &&
        this.getSubscriptionNotify.isMauLimit
      ) {
        return true;
      }
      if (this.getCurrentSubscription.plan === 'Trial') {
        if (this.getSubscriptionNotify.isExpired) {
          return this.getSubscriptionNotify.isMauLimit;
        }
        return false;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.spinner--container {
  min-height: var(--space-jumbo);
}

.quick {
  &--container {
    display: flex;
    flex-direction: column;
    margin-left: 1.6rem;
    list-style: none;
  }

  &--item {
    margin-bottom: 4px;
    background: white;
  }

  &--action {
    button {
      cursor: pointer;
      padding: 5px;
      display: inline-block;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.05px;
    }

    img {
      margin-bottom: 2px;
      margin-right: 5px;
    }

    .cancel {
      color: var(--grey-08);
    }

    .done {
      color: $color-woot;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.view-box.fill-height {
  height: auto;
  flex-grow: 1;
}

.view-box.fill-height {
  height: auto;
  flex-grow: 1;
  min-width: 0;
}
</style>
