<template>
  <div class="plan-container">
    <woot-loading-state v-if="isFetchPlans" />
    <template v-else>
      <div class="row header">
        <h3>Select a plan</h3>
      </div>
      <div class="plans-grid">
        <Plan
          v-for="plan in plans"
          :key="plan.id"
          :plan="plan"
          :active="plan.id === activeId"
          :open-modal="openModal"
          @input="handleSelectPlan"
        />
      </div>
      <woot-modal
        :show.sync="showModal"
        :on-close="hideModal"
        :close-on-backdrop-click="false"
      >
        <email-enterprise :close-modal="hideModal" />
      </woot-modal>
      <AlertModal
        :show="showModalIsTrialAndNotExpired"
        message="Continue upgrade plan? Your free trial session will be changed into any paid plan you choose"
        reject-text="Cancel"
        confirm-text="Continue"
        @on-confirm="handleConfirmModalWarningIsTrial"
        @on-close="handleShowModalWarningIsTrial(false)"
      />
    </template>
  </div>
</template>

<script>
import Plan from './Plan';
import { required } from 'vuelidate/lib/validators';
import PlansAPI from '../../../../api/billing/plans';
import { mapGetters } from 'vuex';
import ordersMixin from '../../../../../shared/mixins/ordersMixin';
import EmailEnterprise from '../email-enterprise/EmailEnterprise.vue';
import AlertModal from 'dashboard/components/widgets/modal/AlertModal';

export default {
  name: 'Plans',
  components: {
    Plan,
    EmailEnterprise,
    AlertModal,
  },
  mixins: [ordersMixin],
  data() {
    return {
      plan: {},
      plans: [],
      orders: [],
      activeId: undefined,
      showModal: false,
      showModalIsTrialAndNotExpired: false,
      isFetchPlans: false,
    };
  },
  validations: {
    plan: {
      required,
    },
    form: ['plan'],
  },
  computed: {
    ...mapGetters({
      isSubscriptionActive: 'subscription/isSubscriptionActive',
      activePlanId: 'subscription/getActivePlanId',
      getSubscriptionNotify: 'subscription/getSubscriptionNotify',
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    handleSelectPlan(plan) {
      this.plan = plan;
      if (
        this.getSubscriptionNotify.isTrial &&
        !this.getSubscriptionNotify.isExpired
      ) {
        this.handleShowModalWarningIsTrial();
      } else {
        this.selectPlan();
      }
    },
    selectPlan() {
      const plan = this.plan;
      const order = this.generateOrderPlan(
        plan.name,
        plan.mau_quota,
        plan.agent_quota,
        plan.price,
      );
      this.orders = [{ ...order }];
      this.$parent.$parent.$parent.$refs.wizard.nextTab();
    },
    handleConfirmModalWarningIsTrial() {
      this.handleShowModalWarningIsTrial(false);
      this.selectPlan();
    },
    handleShowModalWarningIsTrial(status = true) {
      this.showModalIsTrialAndNotExpired = status;
    },
    validate() {
      const isValid = !this.$v.form.$invalid;
      this.$v.form.$touch();
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
    openModal() {
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
    async init() {
      try {
        this.isFetchPlans = true;
        await this.$store.dispatch('subscription/get');
        const request = await PlansAPI.get();
        let plans = request.data.data;
        if (!Array.isArray(plans) && plans.length === 0) {
          throw new Error('plans is empty');
        }
        const getFreePlan = plans.find(
          plan => plan.name.toLowerCase() === 'free',
        );
        if (this.activePlanId && this.activePlanId !== getFreePlan.id) {
          plans = plans.filter(plan => plan.id !== getFreePlan.id);
        }
        this.activeId = this.activePlanId ? this.activePlanId : getFreePlan.id;
        this.plans = plans;
      } catch (e) {
        bus.$emit('newToastMessage', e.message);
      } finally {
        this.isFetchPlans = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-container {
  padding: 24px 36px 36px;
  .header {
    h3 {
      margin-bottom: 36px;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .plans-grid {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(196px, 1fr));
  }
}
</style>
