<template>
  <SubscriptionCard>
    <div class="small-8">
      <h3 class="title">
        Additional Active Users Quota
      </h3>
      <p class="description">
        {{ getSubscriptionQuota.additionalMauLeft | toNumber }} users remaining
      </p>
      <p v-if="getSubscriptionQuota.additionalMauLeft > 0" class="description">
        Valid until
        {{ getSubscriptionInfoPlan.end_date | moment('D MMMM YYYY') }}
      </p>
    </div>
    <div class="small-4 text-right">
      <woot-button size="small" @click="handleClickCard">
        Top Up
      </woot-button>
    </div>
  </SubscriptionCard>
</template>

<script>
import WootButton from 'dashboard/components/ui/WootButton';
import SubscriptionCard from './SubscriptionCard';
import { mapGetters } from 'vuex';

export default {
  name: 'SubscriptionCardMau',
  components: {
    WootButton,
    SubscriptionCard,
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      getSubscriptionNextBilling: 'subscription/getSubscriptionNextBilling',
      getSubscriptionQuota: 'subscription/getSubscriptionQuota',
      getSubscriptionInfoPlan: 'subscription/getSubscriptionInfoPlan',
      getSubscriptionNotify: 'subscription/getSubscriptionNotify',
    }),
  },
  methods: {
    handleClickCard() {
      this.$router.push(`/app/accounts/${this.accountId}/settings/billing/mau`);
    },
  },
};
</script>

<style scoped></style>
