/* global axios */

import ApiClient from '../ApiClient';

class IceBreaker extends ApiClient {
  constructor() {
    super(`inboxes`, {
      accountScoped: true,
    });
  }

  get(inboxId) {
    return axios.get(`${this.url}/${inboxId}/instagram_ice_breakers`);
  }

  create(inboxId, data) {
    return axios.post(`${this.url}/${inboxId}/instagram_ice_breakers`, data);
  }

  delete(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/instagram_ice_breakers`);
  }
}

export default new IceBreaker();
