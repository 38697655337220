<template>
  <div class="column content-box">
    <div class="row">
      <div class="small-12 columns integrations-wrap">
        <div class="row integrations">
          <div v-if="withAgentBot">
            <div
              v-for="item in integrations"
              :key="item.id"
              class="small-12 columns integration"
            >
              <integration
                :integration-id="item.id"
                :integration-logo="item.logo"
                :integration-name="item.name"
                :integration-description="item.description"
                :integration-enabled="item.enabled"
                :integration-action="item.action"
              />
            </div>
          </div>
          <div
            v-for="item in integrationsList"
            :key="item.id"
            class="small-12 columns integration"
          >
            <integration
              :integration-id="item.id"
              :integration-logo="item.logo"
              :integration-name="item.name"
              :integration-description="item.description"
              :integration-enabled="item.enabled"
              :integration-action="item.action"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Integration from './Integration';

import integrations from './constants/integrations.json';

export default {
  components: {
    Integration,
  },
  data() {
    return {
      integrations: integrations,
      withAgentBot: process.env.WITH_INTEGRATIONS_AGENT_BOT === 'true',
    };
  },
  computed: {
    ...mapGetters({
      integrationsList: 'integrations/getIntegrations',
    }),
  },
  mounted() {
    this.$store.dispatch('integrations/get');
  },
};
</script>
