var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"whatsapp-form"},[_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":require("dashboard/assets/icons/ic_info.svg")}}),_vm._v(" "),_c('div',{staticClass:"content"},[_c('span',[_vm._v(_vm._s(_vm.$t('NEW_CHANNEL.WHATSAPP.INFO')))]),_vm._v(" "),_c('a',{attrs:{"href":"https://docs.kata.ai/kata-omnichat/general-setup","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("\n        "+_vm._s(_vm.$t('NEW_CHANNEL.WHATSAPP.MORE_INFO'))+"\n      ")])])]),_vm._v(" "),_c('form',{staticClass:"column align-self-top",on:{"submit":function($event){$event.preventDefault();return _vm.submitWhatsappChannel()}}},[_c('div',{staticClass:"column form-input-wrapper"},[_c('woot-input',{class:{ error: _vm.$v.credentials.username.$error },attrs:{"type":"text","icon":"ic_user.svg","placeholder":_vm.$t('NEW_CHANNEL.WHATSAPP.FORM.USERNAME.PLACEHOLDER'),"error":_vm.$v.credentials.username.$error
            ? _vm.$t('NEW_CHANNEL.WHATSAPP.FORM.USERNAME.ERROR')
            : ''},on:{"blur":_vm.$v.credentials.username.$touch},model:{value:(_vm.credentials.username),callback:function ($$v) {_vm.$set(_vm.credentials, "username", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"credentials.username"}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.credentials.password.$error },attrs:{"type":"password","icon":"ic_lock.svg","placeholder":_vm.$t('NEW_CHANNEL.WHATSAPP.FORM.PASSWORD.PLACEHOLDER'),"error":_vm.$v.credentials.password.$error
            ? _vm.$t('NEW_CHANNEL.WHATSAPP.FORM.PASSWORD.ERROR')
            : ''},on:{"blur":_vm.$v.credentials.password.$touch},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"credentials.password"}}),_vm._v(" "),(!_vm.webhook.url)?_c('submit-button',{attrs:{"disabled":_vm.$v.$invalid || _vm.isLoading,"button-text":_vm.$t('NEW_CHANNEL.WHATSAPP.FORM.GENERATE'),"loading":_vm.isLoading,"button-class":"expanded"}}):_vm._e()],1)]),_vm._v(" "),(_vm.webhook.url)?[_c('div',{staticClass:"webhook"},[_c('div',{staticClass:"webhook-wrapper"},[_c('div',{staticClass:"webhook-url"},[_vm._v("\n          "+_vm._s(_vm.webhook.url)+"\n        ")]),_vm._v(" "),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            show: _vm.showTooltip,
            trigger: 'manual',
            content: 'Webhook copied!',
          }),expression:"{\n            show: showTooltip,\n            trigger: 'manual',\n            content: 'Webhook copied!',\n          }"}],staticClass:"webhook-copy",on:{"click":_vm.onCopyWebhook}},[_c('img',{staticStyle:{"max-width":"inherit"},attrs:{"src":require("dashboard/assets/icons/ic_copy.svg")}})])]),_vm._v(" "),_c('div',{staticClass:"webhook-info"},[_c('img',{attrs:{"src":require("dashboard/assets/icons/ic_info.svg")}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NEW_CHANNEL.WHATSAPP.WEBHOOK')))])])]),_vm._v(" "),_c('div',{staticClass:"add-agents"},[_c('submit-button',{attrs:{"type":"button","disabled":!_vm.webhook.isCopied,"button-text":_vm.$t('NEW_CHANNEL.WHATSAPP.FORM.ADD_AGENT')},on:{"click":_vm.onClickAddAgent}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }