<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="getting-started__warning">
    <img src="~dashboard/assets/icons/ic_warning.svg" />
    <div>
      <div class="getting-started__title">
        {{ $t('BANNER.MAU_WARNING.TITLE') }}
      </div>
      <div>
        {{ $t('BANNER.MAU_WARNING.DESCRIPTION') }}
        <span
          ><router-link :to="`/app/accounts/${accountId}/settings/billing`"
            >here</router-link
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      getCurrentSubscription: 'subscription/getCurrentSubscription',
    }),
    expiredIn() {
      const dayLeft = this.getCurrentSubscription.days_left;
      if (dayLeft === 1) {
        return 'Tomorrow';
      }
      return `${dayLeft} days`;
    },
  },
};
</script>

<style lang="scss" scoped>
.getting {
  &-started {
    &__title {
      font-weight: bold;
      margin-bottom: 8px;
    }
    &__warning {
      display: flex;
      align-items: center;
      background: var(--yellow-02);
      border: 1px solid var(--yellow-07);
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 16px 18px;
      font-size: 14px;
      line-height: 24px;
      img {
        margin-right: 20px;
      }
      a {
        text-decoration: underline;
      }
      b,
      strong {
        margin-left: 4px;
      }
    }
  }
}
</style>
