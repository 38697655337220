<template>
  <div class="container">
    <slot></slot>
    <img
      class="icon"
      src="~dashboard/assets/icons/ic_close.svg"
      alt=""
      @click="onClick"
    />
  </div>
</template>

<script>
export default {
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: inline-flex;
  border: 1px solid #ebedef;
  box-sizing: border-box;
  border-radius: 36px;
  padding: 8px 12px;
  width: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #313f4e;
  margin-top: 8px;
  z-index: 1000;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background: #ebedef;
  }
}

.icon {
  margin: 0 0 0 10px;
  cursor: pointer;
}
</style>
