<template>
  <div v-if="inbox && inbox.comment_reply" class="activate-comment">
    <span>{{ $t('INBOX_MGMT.INSTAGRAM_SETTINGS.COMMENT') }}</span>
    <div class="activate-comment__switch">
      <span>{{ $t('INBOX_MGMT.INSTAGRAM_SETTINGS.COMMENT_MODE') }}</span>
      <switch-component
        id="reply-comment"
        name="reply-comment"
        type="switch"
        size="normal"
        :checked="isCommentMode"
        @input-change="onSwitchChange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SwitchComponent from 'dashboard/components/ui/Switch';

export default {
  components: {
    SwitchComponent,
  },
  props: {
    isCommentMode: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      chatLists: 'getAllConversations',
    }),
    conversation() {
      const conversationId = Number(this.$route.params.conversation_id);
      if (conversationId) {
        const [conversation] = this.chatLists.filter(
          item => item.id === conversationId,
        );
        return conversation;
      }
      return null;
    },
    inbox() {
      const converse = this.conversation;
      if (converse && converse.inbox_id) {
        const { inbox_id } = converse;
        return this.$store.getters['inboxes/getInbox'](inbox_id);
      }
      return null;
    },
  },
  methods: {
    onSwitchChange(value) {
      const converse = this.conversation;
      if (converse && converse.inbox_id) {
        const { inbox_id } = converse;
        this.$emit('set-comment-mode', {
          inboxId: inbox_id,
          payload: value,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.activate-comment {
  display: flex;
  align-items: center;
  background: var(--white);
  color: var(--indigo-03);
  font-size: var(--font-size-mini);
  padding: var(--space-normal) var(--space-normal);
  text-align: center;
  position: relative;
  border-bottom: 1px solid var(--grey-04);

  &__switch {
    margin-top: 12px;
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-end;

    span {
      margin-right: 8px;
      color: var(--black);
    }
  }
}
</style>
