<template>
  <div class="small-8 medium-6 column">
    <form
      class="login-box medium-12 column align-self-middle"
      @submit.prevent="submit()"
    >
      <div class="column log-in-form">
        <div :class="{ error: $v.credentials.email.$error }">
          <woot-input
            v-model.trim="credentials.email"
            type="email"
            icon="ic_message.svg"
            :placeholder="$t('RESET_PASSWORD.EMAIL.PLACEHOLDER')"
            @blur="$v.credentials.email.$touch"
          />
          <span v-if="$v.credentials.email.$error" class="message">
            {{ $t('RESET_PASSWORD.EMAIL.ERROR') }}
          </span>
        </div>
        <woot-submit-button
          :disabled="resetPassword.showLoading"
          :button-text="$t('RESET_PASSWORD.SUBMIT')"
          :loading="resetPassword.showLoading"
          button-class="expanded"
        />
      </div>
    </form>
    <div class="column signin--footer">
      <span>{{ $t('RESET_PASSWORD.REMEMBER') }}</span>
      <router-link to="/app/login">
        {{
          useInstallationName(
            $t('REGISTER.AUTH_TITLE'),
            globalConfig.installationName,
          )
        }}
      </router-link>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import { mapGetters } from 'vuex';
import { required, minLength, email } from 'vuelidate/lib/validators';

import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Auth from '../../../api/auth';

export default {
  mixins: [globalConfigMixin],
  props: {
    setIsFinished: Function,
  },
  data() {
    return {
      credentials: {
        email: '',
      },
      resetPassword: {
        message: '',
        showLoading: false,
      },
      error: '',
    };
  },
  validations: {
    credentials: {
      email: {
        required,
        email,
        minLength: minLength(4),
      },
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  watch: {
    'credentials.email'(val) {
      this.$emit('set-email-credential', val);
    },
  },
  methods: {
    showAlert(message) {
      this.resetPassword.showLoading = false;
      bus.$emit('newToastMessage', message);
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.resetPassword.showLoading = true;
      Auth.resetPassword(this.credentials)
        .then(() => {
          let successMessage = this.$t('RESET_PASSWORD.API.SUCCESS_MESSAGE');
          this.$emit('set-is-finished', true);
          this.showAlert(successMessage);
        })
        .catch(error => {
          let errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
          if (error?.response?.data?.message) {
            errorMessage = error.response.data.message;
          }
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>
