<template>
  <div class="banner messenger-policy--banner">
    <span>
      {{ $t('CONVERSATION.CANNOT_REPLY') }}
      <a
        href="https://developers.facebook.com/docs/messenger-platform/policy/policy-overview/"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        {{
          channelMeta === 'Channel::InstagramPage'
            ? $t('CONVERSATION.7_DAYS_WINDOW')
            : $t('CONVERSATION.24_HOURS_WINDOW')
        }}
      </a>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    channelMeta: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  background: var(--b-500);
  color: var(--white);
  font-size: var(--font-size-mini);
  padding: var(--space-slab) var(--space-normal);
  text-align: center;
  position: relative;

  a {
    text-decoration: underline;
    color: var(--white);
    font-size: var(--font-size-mini);
  }

  &.messenger-policy--banner {
    background: var(--r-400);
  }

  .banner-close-button {
    cursor: pointer;
    margin-left: var(--space--two);
    color: var(--white);
  }
}
</style>
