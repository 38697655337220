<template>
  <section class="conversation-page">
    <chat-list
      :conversation-inbox="inboxId"
      :label="label"
      :team-id="teamId"
      @conversation-load="onConversationLoad"
    >
      <pop-over-search />
    </chat-list>
    <conversation-box
      :inbox-id="inboxId"
      :is-contact-panel-open="isContactPanelOpen"
      @contact-panel-toggle="onToggleContactPanel"
    >
    </conversation-box>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ChatList from '../../../components/ChatList';
import ConversationBox from '../../../components/widgets/conversation/ConversationBox';
import PopOverSearch from './search/PopOverSearch';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { isEmptyObject } from 'dashboard/helper/commons';
import { INBOX_TYPES } from '../../../../shared/mixins/inboxMixin';

export default {
  components: {
    ChatList,
    ConversationBox,
    PopOverSearch,
  },
  mixins: [uiSettingsMixin],
  props: {
    inboxId: {
      type: [String, Number],
      default: 0,
    },
    conversationId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    teamId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showSearchModal: false,
    };
  },
  computed: {
    ...mapGetters({
      chatList: 'getAllConversations',
      currentChat: 'getSelectedChat',
      accountId: 'getCurrentAccountId',
    }),
    isContactPanelOpen() {
      if (this.currentChat.id) {
        const {
          is_contact_sidebar_open: isContactSidebarOpen,
        } = this.uiSettings;
        return isContactSidebarOpen;
      }
      return false;
    },
    isInstagramChannel() {
      return (
        !isEmptyObject(this.currentChat) &&
        this.currentChat.meta.channel === INBOX_TYPES.IG
      );
    },
    isWhatsappChannel() {
      return (
        !isEmptyObject(this.currentChat) &&
        this.currentChat.meta.channel === INBOX_TYPES.WHATSAPP
      );
    },
  },

  mounted() {
    this.initialize();
    this.$watch('$store.state.route', () => this.initialize());
    this.$watch('chatList.length', () => {
      this.setActiveChat();
    });
    this.$watch('currentChat.meta.channel', channel => {
      if (channel === INBOX_TYPES.WHATSAPP) {
        this.getWhatsappCredit();
      }
    });
  },

  methods: {
    onConversationLoad() {
      this.fetchConversationIfUnavailable();
    },
    initialize() {
      this.$store.dispatch('setActiveInbox', this.inboxId);
      this.setActiveChat();
    },
    getWhatsappCredit() {
      this.$store.dispatch('inboxes/getWhatsappCredits', {
        inboxId: this.currentChat.inbox_id,
        params: {
          contact_id: this.currentChat.meta.sender.id,
        },
      });
    },
    fetchConversationIfUnavailable() {
      if (!this.conversationId) {
        return;
      }
      const chat = this.findConversation();
      if (!chat) {
        this.$store.dispatch('getConversation', this.conversationId);
      }
    },
    findConversation() {
      const conversationId = parseInt(this.conversationId, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
    setActiveChat() {
      if (this.conversationId) {
        const chat = this.findConversation();
        if (!chat) {
          return;
        }
        this.$store.dispatch('setActiveChat', chat).then(() => {
          bus.$emit('scrollToMessage');
        });
      } else {
        this.$store.dispatch('clearSelectedState');
      }
    },
    onToggleContactPanel() {
      this.updateUISettings(
        {
          is_contact_sidebar_open: !this.isContactPanelOpen,
        },
        {
          accountId: this.accountId,
          conversationId: this.conversationId,
          ...(this.isInstagramChannel && {
            isInstagramChannel: this.isInstagramChannel,
          }),
          ...(this.isWhatsappChannel && {
            isWhatsappChannel: this.isWhatsappChannel,
          }),
        },
      );
    },
    onSearch() {
      this.showSearchModal = true;
    },
    closeSearch() {
      this.showSearchModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation-page {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
