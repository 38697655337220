<template>
  <div class="modal-body">
    <h2 class="text-center modal-title">Contact Sales</h2>
    <woot-input
      v-model="subject"
      class="medium-12"
      label="Subject"
      :class="{ error: $v.subject.$error }"
      :error="$v.subject.$error && 'Subject is required field'"
      @blur="$v.subject.$touch"
    />
    <label>Description</label>
    <textarea
      v-model="description"
      rows="5"
      type="text"
      placeholder="Enter your company description"
      :class="{ errorarea: $v.description.$error }"
      @blur="$v.description.$touch"
    />
    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
    <span v-if="$v.description.$error" class="message"
      >Description is required field
    </span>
    <div class="modal-split">
      <woot-button variant="clear" size="expanded" @click="closeModal()">
        Cancel
      </woot-button>
      <woot-button
        variant="primary"
        size="expanded"
        :disabled="$v.$invalid"
        @click="submitEmail"
      >
        Save Detail
      </woot-button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    closeModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      subject: '',
      description: '',
    };
  },
  validations: {
    subject: {
      required,
    },
    description: {
      required,
    },
  },
  methods: {
    submitEmail() {
      this.closeModal();
      window.open(
        `mailto:sales@kata.ai?subject=${this.subject}&body=${this.description}`,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  padding: 24px;
}
.modal-split {
  display: flex;
  margin-right: 8px;
}
.message {
  color: var(--r-400);
  display: block;
  font-size: var(--font-size-mini);
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1rem;
  width: 100%;
}

.modal-title {
  margin-bottom: 42px;
}

.errorarea {
  border: 1px solid var(--r-400);
}
</style>
