<template>
  <div v-if="url" class="image instagram-image message-text__wrap">
    <img :src="url" @click="onClick" />
    <woot-modal :show.sync="show" :on-close="onClose">
      <img :src="url" class="modal-image" />
    </woot-modal>
  </div>
</template>

<script>
export default {
  props: {
    geturl: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      url: null,
    };
  },
  mounted() {
    this.geturl
      .then(res => {
        this.url = res;
      })
      .catch(() => {
        this.url = null;
      });
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
  },
};
</script>
