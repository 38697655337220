/* global axios */
import ApiClient from '../ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  get({ inboxId, status, assigneeType, page, labels, teamId }) {
    return axios.get(this.url, {
      params: {
        inbox_id: inboxId,
        team_id: teamId,
        status,
        assignee_type: assigneeType,
        page,
        labels,
      },
    });
  }

  search({ q }) {
    return axios.get(`${this.url}/search`, {
      params: {
        q,
        page: 1,
      },
    });
  }

  toggleStatus({ conversationId, status }) {
    return axios.post(`${this.url}/${conversationId}/toggle_status`, {
      status,
    });
  }

  assignAgent({ conversationId, agentId }) {
    return axios.post(
      `${this.url}/${conversationId}/assignments?assignee_id=${agentId}`,
      {},
    );
  }

  assignTeam({ conversationId, teamId }) {
    const params = { team_id: teamId };
    return axios.post(`${this.url}/${conversationId}/assignments`, params);
  }

  markMessageRead({ id }) {
    return axios.post(`${this.url}/${id}/update_last_seen`);
  }

  toggleTyping({ conversationId, status }) {
    return axios.post(`${this.url}/${conversationId}/toggle_typing_status`, {
      typing_status: status,
    });
  }

  mute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/mute`);
  }

  unmute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/unmute`);
  }

  meta({ inboxId, status, assigneeType, labels, teamId }) {
    return axios.get(`${this.url}/meta`, {
      params: {
        inbox_id: inboxId,
        status,
        assignee_type: assigneeType,
        labels,
        team_id: teamId,
      },
    });
  }

  sendEmailTranscript({ conversationId, email }) {
    return axios.post(`${this.url}/${conversationId}/transcript`, { email });
  }

  getMessageDetails({ conversationId, sourceId, index }) {
    const params = typeof index !== 'undefined' ? `?index=${index}` : '';
    return `${this.url}/${conversationId}/messages/instagram/${sourceId}/detail${params}`;
  }

  getMessageType({ conversationId, sourceId }) {
    return axios.get(
      `${this.url}/${conversationId}/messages/instagram/${sourceId}/type`,
    );
  }

  handleReaction(params, data) {
    const { conversationId, messageId } = params;
    return axios.post(
      `${this.url}/${conversationId}/messages/${messageId}/reacts`,
      data,
    );
  }

  getCommentsConversation(conversationId, beforeId) {
    const before = beforeId ? `&before=${beforeId}` : '';
    return axios.get(
      `${this.url}/${conversationId}/messages?message_type=9${before}`,
    );
  }

  getCommentDetail({ conversationId, commentId }) {
    return axios.get(`${this.url}/${conversationId}/messages/${commentId}`);
  }
}

export default new ConversationApi();
