/* eslint-disable dot-notation */
const mappingSlug = (mapped, question, slug) => {
  if (slug === 'monthly_conversation') {
    mapped['dropdown'] = {
      ...mapped['dropdown'],
      [slug]: question,
    };
  } else if (slug === 'direct_messages') {
    mapped['checkbox'] = {
      ...mapped['checkbox'],
      [slug]: question,
    };
  } else {
    mapped['radio'] = {
      ...mapped['radio'],
      [slug]: question,
    };
  }

  return mapped;
};

const removeFreeText = response => {
  const { free_text, ...rest } = response;
  if (free_text !== '') {
    return {
      ...rest,
      free_text,
    };
  }
  return {
    ...rest,
  };
};

export const prepareToDisplay = response => {
  let mapped = {};
  response.data.forEach(question => {
    const { slug } = question;
    mapped = mappingSlug(mapped, question, slug);
  });

  return mapped;
};

export const prepareToSave = response => {
  const {
    device,
    direct_messages,
    discover,
    division,
    intention,
    monthly_conversation,
    ...rest
  } = response;
  const request = {
    ...rest,
    onboarding: [
      { ...removeFreeText(device) },
      { ...removeFreeText(discover) },
      { ...removeFreeText(division) },
      { ...removeFreeText(intention) },
      { ...direct_messages },
      { ...monthly_conversation },
    ],
  };
  return request;
};
