<template>
  <div class="billing-card row flex-dir-row align-center-middle">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SubscriptionCard',
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables';

.billing-card {
  padding: 32px;
  border: 1px solid var(--greylight-04);
  border-radius: 12px;
  background-color: var(--white);

  .title {
    font-size: 16px;
    font-weight: $font-weight-black;
    margin-bottom: 4px;
    color: var(--greydark-02);
  }

  .description {
    font-size: 14px;
    color: var(--greymed-04);

    span {
      &.bold {
        font-weight: $font-weight-black;
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
</style>
