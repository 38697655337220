<template>
  <div class="goal-item__icon">
    <img
      v-if="goalKey === 1"
      src="~dashboard/assets/icons/ic_onboard_evaluate.svg"
    />
    <img
      v-if="goalKey === 2"
      src="~dashboard/assets/icons/ic_onboard_instagram_dm.svg"
    />
    <img
      v-if="goalKey === 3"
      src="~dashboard/assets/icons/ic_onboard_whatsapp.svg"
    />
  </div>
</template>
<script>
export default {
  props: {
    goalKey: {
      type: Number,
      required: true,
      default: null,
    },
  },
};
</script>
