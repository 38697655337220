<template>
  <div class="payment-method-container">
    <div class="row header">
      <h3>Select payment method</h3>
    </div>
    <BankVirtualAccounts @changeBank="value => $emit('changeBank', value)" />
  </div>
</template>

<script>
import BankVirtualAccounts from './BankVirtualAccounts/BankVirtualAccounts';
export default {
  name: 'PaymentMethod',
  components: { BankVirtualAccounts },
};
</script>

<style lang="scss" scoped>
.payment-method-container {
  .header {
    h3 {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }
}
</style>
