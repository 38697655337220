<template>
  <div class="column content-box">
    <div class="integrations">
      <woot-button
        color-scheme="success"
        class-names="button--fixed-right-top"
        icon="ion-android-add-circle"
        @click.stop="createNewConfiguration"
      >
        {{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.ADD.ADD') }}
      </woot-button>
      <div class="row">
        <div class="small-12 columns">
          <h1 class="integrations-title">
            {{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.ADD.TITLE') }}
          </h1>
          <agent-bot-list />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AgentBotList from './components/AgentBotList';

export default {
  components: {
    AgentBotList,
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    createNewConfiguration() {
      this.$customGTM(
        'Event',
        `Agent Bot - Add New Configuration`,
        'Button',
        `Add New Configuration`,
      );
      bus.$emit('clearAgentBotData');
      this.$router.push(
        `/app/accounts/${this.accountId}/settings/integrations/agent-bot/create`,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.integrations {
  &-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }
}
</style>
