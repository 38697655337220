<template>
  <div class="instagram-step__third">
    <div class="instagram-step__third-content">
      <h3>{{ $t('NEW_CHANNEL.INSTAGRAM.THIRD_STEP.TITLE') }}</h3>
      <p>{{ $t('NEW_CHANNEL.INSTAGRAM.THIRD_STEP.CONTENT') }}</p>
      <div class="grant">
        <input id="grant" v-model="grant" type="checkbox" />
        <label for="grant">{{
          $t('NEW_CHANNEL.INSTAGRAM.THIRD_STEP.UNDERSTAND')
        }}</label>
      </div>
      <div v-if="$v.grant.$error" :class="$v.grant.$error && 'error'">
        <span class="message">
          {{ $t('NEW_CHANNEL.INSTAGRAM.FORM.ERROR') }}
        </span>
      </div>
      <p v-html="$t('NEW_CHANNEL.INSTAGRAM.THIRD_STEP.DESCRIPTION')" />
    </div>
    <img src="~dashboard/assets/images/hero/instagram-third-step.png" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
export default {
  data() {
    return {
      grant: false,
    };
  },
  validations: {
    grant: {
      required,
      mustSelected(value) {
        if (!value) {
          return false;
        }
        return true;
      },
    },
    form: ['grant'],
  },
  methods: {
    validate() {
      const isValid = !this.$v.form.$invalid;
      this.$v.form.$touch();
      return isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
.instagram {
  &-step {
    &__third {
      display: flex;
      align-items: center;
      img {
        flex: 1;
        height: 100%;
      }
      &-content {
        flex: 1;
        text-align: left;
        h3 {
          display: block;
          font-size: 20px;
          font-weight: bold;
          color: var(--grey-09);
          margin-bottom: 12px;
          line-height: 28px;
        }
        p {
          margin: 24px 0;
          font-size: 14px;
          line-height: 24px;
          color: var(--grey-08);
        }
        ul {
          margin: 12px 20px;
        }
      }
    }
  }
}
.grant {
  padding: 20px 30px;
  border: 1px solid var(--grey-04);
  border-radius: 4px;
  margin-bottom: 0;
  input[type='checkbox'] {
    margin: 0;
  }
}
.error {
  .message {
    margin: 0;
    margin-top: 5px;
  }
}
</style>
