<template>
  <div class="small-9 instagram-wrapper">
    <div v-if="finalStep" class="instagram-wrapper__title">
      <channel-header
        :title="$t('NEW_CHANNEL.FINAL_STEP.TITLE')"
        icon="channel-instagram.svg"
      />
      <instagram-final-setup
        :token="accessToken"
        :is-loading="isLoading"
        @on-submit="onSubmit"
      />
    </div>
    <div v-else class="instagram-wrapper__title">
      <channel-header
        :title="$t('NEW_CHANNEL.INSTAGRAM.TITLE')"
        icon="channel-instagram.svg"
      />
      <instagram-getting-started
        v-if="gettingStarted"
        @get-started="gettingStarted = false"
      />
      <div v-else class="instagram-wrapper__wizard">
        <wizard
          :back-button="$t('NEW_CHANNEL.WIZARD.BACK')"
          :next-button="$t('NEW_CHANNEL.WIZARD.NEXT')"
          :hide-first-step="false"
          :hide-last-step="true"
          step-position="bottom"
        >
          <template slot="custom-prev-button">
            <kata-button variant="outline" @click.stop="backToChannels">
              <img src="~dashboard/assets/icons/ic_chevron_left.svg" />
              {{ $t('NEW_CHANNEL.WIZARD.BACK_TO_CHANNEL') }}
            </kata-button>
          </template>
          <tab-content
            :before-change="() => validateStep('first-step')"
            :lazy="true"
          >
            <instagram-first-step
              ref="first-step"
              @on-validate="mergePartialData"
            />
          </tab-content>
          <tab-content
            :before-change="() => validateStep('second-step')"
            :lazy="true"
          >
            <instagram-second-step
              ref="second-step"
              @on-validate="mergePartialData"
            />
          </tab-content>
          <tab-content
            :before-change="() => validateStep('third-step')"
            :lazy="true"
          >
            <instagram-third-step
              ref="third-step"
              @on-validate="mergePartialData"
            />
          </tab-content>
          <tab-content
            :before-change="() => validateStep('fourth-step')"
            :lazy="true"
          >
            <instagram-fourth-step
              ref="fourth-step"
              @on-validate="mergePartialData"
            />
          </tab-content>
          <tab-content>
            <instagram-fifth-step
              @on-change-access-token="changeAccessToken"
              @on-change-final-step="finalStep = true"
            />
          </tab-content>
        </wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { TabContent } from 'vue-form-wizard';
import { mapGetters } from 'vuex';

import Wizard from 'dashboard/components/kata/wizard/Wizard';
import alertMixin from 'shared/mixins/alertMixin';
import KataButton from 'dashboard/components/buttons/Button';
import ChannelHeader from '../../components/Header';

import router from 'dashboard/routes/index';
import { prepareToSave } from './helper/helper';
import Onboarding from 'dashboard/api/onboarding';

import InstagramGettingStarted from './components/GettingStarted.vue';
import InstagramFinalSetup from './components/FinalSetup.vue';
import InstagramFirstStep from './wizard/InstagramFirstStep';
import InstagramSecondStep from './wizard/InstagramSecondStep';
import InstagramThirdStep from './wizard/InstagramThirdStep';
import InstagramFourthStep from './wizard/InstagramFourthStep';
import InstagramFifthStep from './wizard/InstagramFifthStep';

export default {
  components: {
    Wizard,
    TabContent,
    ChannelHeader,
    KataButton,
    InstagramGettingStarted,
    InstagramFinalSetup,
    InstagramFirstStep,
    InstagramSecondStep,
    InstagramThirdStep,
    InstagramFourthStep,
    InstagramFifthStep,
  },
  mixins: [alertMixin],
  data() {
    return {
      data: {},
      accessToken: '',
      gettingStarted: true,
      finalStep: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    validateStep(name) {
      const refToValidate = this.$refs[name];
      // this.fireGTM(name);
      return refToValidate.validate();
    },
    mergePartialData(model, isValid) {
      if (isValid) {
        this.data = { ...this.data, ...model };
      }
    },
    backToChannels() {
      this.$router.push(`/app/accounts/${this.accountId}/settings/inboxes/new`);
    },
    showError(error) {
      let errorMessage = this.$t('NEW_CHANNEL.API.ERROR_MESSAGE');
      if (error.response && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      if (error.message) {
        errorMessage = error.message;
      }
      this.showAlert(errorMessage);
    },
    async createChannel(request) {
      this.$store
        .dispatch('inboxes/createInstagramChannel', request)
        .then(res => {
          router.replace({
            name: 'settings_inboxes_add_agents',
            params: { page: 'new', inbox_id: res.id },
          });
        })
        .catch(error => {
          this.showError(error);
        });
    },
    async onSubmit(data) {
      const { page_access_token } = data;
      this.isLoading = true;
      try {
        const request = prepareToSave({ ...this.data, ...data });
        await Onboarding.checkInstagramEligibility(
          this.accountId,
          page_access_token,
        );
        this.createChannel(request);
      } catch (error) {
        this.showError(error);
      } finally {
        this.isLoading = false;
      }
    },
    changeAccessToken(accessToken) {
      this.accessToken = accessToken;
    },
  },
};
</script>

<style lang="scss" scoped>
.instagram {
  &-wrapper {
    background: var(--white);
    padding: var(--space-medium);
    border: 1px solid var(--grey-03);
    position: relative;

    button {
      img {
        margin-right: 12px;
      }
    }
  }
}
</style>
