<template>
  <div class="channel-heading__container">
    <div class="channel-heading__title">
      <img
        v-if="icon"
        :src="require(`../../../../../assets/images/kata/${icon}`)"
      />
      <h2>{{ title }}</h2>
    </div>
    <p
      v-if="description"
      class="channel-heading__description"
      v-html="description"
    ></p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.channel {
  &-heading {
    &__container {
      margin: var(--space-two) 0;
      padding: 0 20px;
    }
    &__title {
      display: flex;
      align-items: center;
      margin-bottom: var(--space-two);
      h2 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        margin: 0;
      }
      img {
        margin-right: 12px;
        max-width: 40px;
      }
    }
    &__description {
      display: flex;
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
