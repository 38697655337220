<template>
  <div class="row align-center">
    <div class="small-12 medium-12 column">
      <agent-bot-form-header
        :form-type="formType"
        :access-token="accessToken"
        :status="status"
        :webhook-url="webhookUrl"
      />
      <form class="column align-self-top" @submit.prevent="submit()">
        <div class="column form-input-wrapper">
          <woot-input
            v-model.trim="credentials.name"
            type="text"
            :disabled="formTypeView"
            :class="{ error: $v.credentials.name.$error }"
            :label="$t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.NAME.LABEL')"
            :placeholder="
              $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.NAME.PLACEHOLDER')
            "
            :error="
              $v.credentials.name.$error && credentials.name === ''
                ? $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.NAME.ERROR_REQUIRED')
                : $v.credentials.name.$error && credentials.name.length > 50
                ? $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.NAME.ERROR_MAXLENGTH')
                : ''
            "
            @blur="
              () => {
                $customGTM(
                  'Event',
                  `Agent Bot - Add New Configuration - Name`,
                  'Text',
                  `Add New Configuration - Name`,
                );
                $v.credentials.name.$touch;
              }
            "
          />
          <woot-input
            v-model.trim="credentials.description"
            type="text"
            :disabled="formTypeView"
            :class="{ error: $v.credentials.description.$error }"
            :label="$t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.DESCRIPTION.LABEL')"
            :placeholder="
              $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.DESCRIPTION.PLACEHOLDER')
            "
            :error="
              $v.credentials.description.$error
                ? $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.DESCRIPTION.ERROR')
                : ''
            "
            @blur="
              () => {
                $customGTM(
                  'Event',
                  `Agent Bot - Add New Configuration - Description`,
                  'Text',
                  `Add New Configuration - Description`,
                );
                $v.credentials.description.$touch;
              }
            "
          />
          <div v-if="accessToken" class="accesstoken">
            <label>
              {{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ACCESS_TOKEN.LABEL') }}
            </label>
            <div class="accesstoken-wrapper">
              <div class="accesstoken-url">
                {{ accessToken }}
              </div>
              <div
                v-tooltip="{
                  show: showTooltip,
                  trigger: 'manual',
                  content: $t(
                    'NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ACCESS_TOKEN.COPIED',
                  ),
                }"
                class="accesstoken-copy"
                @click="onCopyAccessToken"
              >
                <img src="~dashboard/assets/icons/ic_copy_black.svg" />
              </div>
            </div>
          </div>
          <div v-if="accessToken" class="multiselect">
            <label :class="{ error: $v.selectedInboxes.$error }">
              {{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ASSIGNED.LABEL') }}
              <multiselect
                v-model="selectedInboxes"
                :options="inboxes"
                :disabled="formTypeView"
                track-by="id"
                :custom-label="customLabel"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :hide-selected="true"
                selected-label
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                :placeholder="
                  $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ASSIGNED.PLACEHOLDER')
                "
                @select="$v.selectedInboxes.$touch"
              >
                <template slot="tag" slot-scope="props">
                  <span class="multiselect__tag">
                    <i
                      class="inbox-icon"
                      :class="computedInboxClass(props.option)"
                    />
                    <span class="multiselect__name">
                      {{ props.option.name }}</span
                    >
                    <i
                      aria-hidden="true"
                      tabindex="1"
                      class="multiselect__tag-icon"
                      @click="removeSelectedInbox(props.option)"
                    />
                  </span>
                </template>
                <template slot="option" slot-scope="props"
                  ><div class="wrap">
                    <i
                      class="inbox-icon"
                      :class="computedInboxClass(props.option)"
                    />
                    <span>
                      {{ props.option.name }}
                    </span>
                  </div></template
                >
              </multiselect>
              <span v-if="$v.selectedInboxes.$error" class="message">
                {{
                  $t(
                    'NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ASSIGNED.VALIDATION_ERROR',
                  )
                }}
              </span>
            </label>
          </div>
          <woot-input
            v-if="accessToken"
            v-model.trim="webhookUrl"
            :disabled="formTypeView"
            type="text"
            :class="{ error: $v.webhookUrl.$error }"
            :label="$t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.WEBHOOK_URL.LABEL')"
            :placeholder="
              $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.WEBHOOK_URL.PLACEHOLDER')
            "
            :error="
              $v.webhookUrl.$error
                ? $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.WEBHOOK_URL.ERROR')
                : ''
            "
            @blur="
              () => {
                $customGTM(
                  'Event',
                  `Agent Bot - Add New Configuration - Webhook URL`,
                  'Text',
                  `Add New Configuration - Webhook URL`,
                );
                $v.webhookUrl.$touch;
              }
            "
          />
          <div class="form-action">
            <div class="form-action__activate">
              <span>{{
                $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ACTION.ACTIVATE')
              }}</span>
              <toggle
                :value="status"
                :on-change="changeAgentBotStatus"
                :disabled="
                  status === null ||
                  (accessToken &&
                    (!webhookUrl || selectedInboxes.length === 0)) ||
                  formTypeView
                "
              />
            </div>
            <div
              v-if="(formTypeCreate || formTypeView) && status !== null"
              class="form-action__button"
            >
              <woot-button
                type="button"
                variant="hollow"
                color-scheme="secondary"
                icon="ion-edit"
                @click.stop="editConfiguration(agentBotId)"
              >
                {{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ACTION.EDIT') }}
              </woot-button>
            </div>
            <div v-else class="form-action__button">
              <woot-button
                type="button"
                variant="hollow"
                color-scheme="secondary"
                @click.stop="goBack"
              >
                {{ $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ACTION.CANCEL') }}
              </woot-button>
              <submit-button
                :button-text="
                  accessToken
                    ? $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ACTION.SAVE')
                    : $t('NEW_INTEGRATION_APPS.AGENT_BOT.FORM.ACTION.GENERATE')
                "
                type="submit"
                :disabled="
                  accessToken
                    ? !webhookUrl || selectedInboxes.length === 0
                    : null
                "
                :loading="isLoading"
              >
              </submit-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import copy from 'copy-text-to-clipboard';
import SubmitButton from 'dashboard/components/buttons/FormSubmitButton';
import Toggle from 'dashboard/components/widgets/forms/Toggle';
import { getInboxClassByType } from 'dashboard/helper/inbox';
import AgentBotFormHeader from './AgentBotFormHeader';

import AgentBot from 'dashboard/api/agent-bot';
import alertMixin from 'shared/mixins/alertMixin';
import prepareToDisplay, {
  ACTIVE,
  INACTIVE,
  CREATE_FORM,
  UPDATE_FORM,
  VIEW_FORM,
} from '../utils/prepareToDisplay';

function requiredIfAccessToken(value) {
  return !this.$data.accessToken || required(value);
}

export default {
  components: {
    AgentBotFormHeader,
    SubmitButton,
    Toggle,
  },
  mixins: [alertMixin],
  props: {
    formType: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      credentials: {
        name: '',
        description: '',
      },
      agentBotId: '',
      selectedInboxes: [],
      accessToken: '',
      webhookUrl: '',
      status: null,
      isLoading: false,
      inboxes: [],
      showTooltip: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    inboxesId() {
      return this.$data.selectedInboxes.map(({ id }) => id);
    },
    inboxesMap() {
      return this.$data.selectedInboxes.map(({ id, name, channel_type }) => ({
        id,
        name,
        channel_type,
      }));
    },
    formTypeUpdate() {
      return this.formType === UPDATE_FORM;
    },
    formTypeView() {
      return this.formType === VIEW_FORM;
    },
    formTypeCreate() {
      return this.formType === CREATE_FORM;
    },
  },
  mounted() {
    const {
      params: { accountId },
    } = this.$route;
    bus.$on('fetchAgentBotData', id => {
      this.fetchAgentBot(accountId, id);
      this.fetchAgentBotInboxes(accountId);
    });
    bus.$on('clearAgentBotData', () => {
      this.clearInitialData();
      this.fetchAgentBotInboxes(accountId);
    });
  },
  created() {
    const {
      params: { accountId, agent_bot_id },
    } = this.$route;
    if (this.formTypeUpdate || this.formTypeView) {
      this.fetchAgentBot(accountId, agent_bot_id);
    }
    this.fetchAgentBotInboxes(accountId);
  },
  validations: {
    credentials: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      description: {
        required,
      },
    },
    selectedInboxes: {
      required: requiredIfAccessToken,
    },
    webhookUrl: {
      required: requiredIfAccessToken,
    },
  },
  methods: {
    async fetchAgentBot(accountId, id) {
      this.$data.isLoading = true;
      try {
        const response = await AgentBot.viewAgentBot(accountId, id);
        const {
          credentials,
          agentBotId,
          selectedInboxes,
          accessToken,
          webhookUrl,
          status,
        } = prepareToDisplay(response.data);
        this.$data.credentials = credentials;
        this.$data.agentBotId = agentBotId;
        this.$data.selectedInboxes = selectedInboxes;
        this.$data.accessToken = accessToken;
        this.$data.webhookUrl = webhookUrl;
        this.$data.status = status;
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.$data.isLoading = false;
      }
    },
    async createAgentBot() {
      this.$data.isLoading = true;
      try {
        const response = await AgentBot.createAgentBot(this.accountId, {
          ...this.credentials,
        });
        this.$data.accessToken = response.data.access_token;
        this.$data.agentBotId = response.data.id;
        bus.$emit('addAgentBotData', response.data);
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.$data.isLoading = false;
      }
    },
    async updateAgentBot() {
      this.$data.isLoading = true;
      try {
        await AgentBot.verifyWebhook(this.accountId, {
          url: this.webhookUrl,
        });
        const res = await AgentBot.updateAgentBot(
          this.accountId,
          this.agentBotId,
          {
            ...this.credentials,
            outgoing_url: this.webhookUrl,
          },
        );
        this.$data.status = res.data.status === ACTIVE;
        bus.$emit('editAgentBotData', {
          ...res.data,
          inboxes: this.inboxesMap,
        });
        await AgentBot.setAgentBotInboxes(this.accountId, this.agentBotId, {
          inbox_ids: this.inboxesId,
          status: this.status ? 'active' : 'inactive',
        });
        this.showAlert(this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.CONNECTED'));
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.$data.isLoading = false;
      }
    },
    async changeAgentBotStatus() {
      try {
        const request = this.status ? INACTIVE : ACTIVE;
        await AgentBot.setAgentBotStatus(this.accountId, this.agentBotId, {
          status: request,
        });
        this.$data.status = !this.status;
        if (this.status) {
          this.showAlert(this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.ACTIVATED'));
        } else {
          this.showAlert(this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.DEACTIVATED'));
        }
        bus.$emit('setStatusAgentBotData', {
          agentBotId: this.agentBotId,
          status: request,
        });
      } catch (error) {
        this.showErrorMessage(error);
      }
    },
    async fetchAgentBotInboxes(accountId) {
      try {
        const response = await AgentBot.getAgentBotInboxes(accountId);
        this.$data.inboxes = response.data.payload;
      } catch (error) {
        this.showErrorMessage(error);
      } finally {
        this.$data.isLoading = false;
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.accessToken) {
        this.$customGTM(
          'Event',
          `Agent Bot - Add New Configuration - Generate Access Token`,
          'Button',
          `Add New Configuration - Generate Access Token`,
        );
        this.createAgentBot();
      } else {
        this.$customGTM(
          'Event',
          `Agent Bot - Add New Configuration - Activate Agent Bot`,
          'Button',
          `Add New Configuration - Activate Agent Bot`,
        );
        this.updateAgentBot();
      }
      return true;
    },
    goBack() {
      this.$router.go(-1);
    },
    onCopyAccessToken(e) {
      e.preventDefault();
      this.$customGTM(
        'Event',
        `Agent Bot - Add New Configuration - Access Token`,
        'Button',
        `Add New Configuration - Access Token`,
      );
      copy(this.$data.accessToken);
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 1500);
    },
    showErrorMessage(error) {
      let errorMessage = this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.API.ERROR');
      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      this.showAlert(errorMessage);
    },
    editConfiguration(id) {
      this.$router.push(
        `/app/accounts/${this.accountId}/settings/integrations/agent-bot/${id}/update`,
      );
    },
    removeSelectedInbox({ id }) {
      this.selectedInboxes = this.selectedInboxes.filter(
        selectedInbox => selectedInbox.id !== id,
      );
    },
    clearInitialData() {
      this.$data.credentials = {
        name: '',
        description: '',
      };
      this.$data.agentBotId = '';
      this.$data.selectedInboxes = [];
      this.$data.accessToken = '';
      this.$data.webhookUrl = '';
      this.$data.status = null;
    },
    customLabel({ name, channel_type }) {
      return `${channel_type.split('::')[1]} - ${name}`;
    },
    computedInboxClass(child) {
      const { channel_type } = child;
      const classByType = getInboxClassByType(channel_type);
      return classByType;
    },
    computedChildClass(child) {
      if (!child.truncateLabel) return '';
      return 'text-truncate';
    },
    computedChildTitle(child) {
      if (!child.truncateLabel) return false;
      return child.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect {
  &::v-deep .multiselect {
    margin-top: var(--space-small);
  }
  .multiselect__name {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 300px;
  }
}
.form {
  &-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__activate {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: var(--greymed-04);
        display: inline-flex;
        margin-right: 10px;
      }
    }

    button {
      margin-left: 8px;
    }
  }
}
.accesstoken {
  margin-bottom: var(--space-normal);
  &-wrapper {
    background: var(--white);
    border: 1px solid var(--grey-04);
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: var(--greydark-02);
    display: flex;
  }
  &-url {
    padding: 14px 16px;
    flex: 1;
  }
  &-copy {
    padding: 0 16px;
    border-left: 1px solid var(--grey-04);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.wrap {
  display: flex;
  align-items: center;
}

.inbox-icon {
  position: relative;
  margin-right: 5px;
  top: -1px;
  &.ion-ios-email {
    font-size: var(--font-size-medium);
  }
}
</style>
