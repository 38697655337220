<template>
  <div class="wrapper">
    <table class="woot-table quickreply-table">
      <thead>
        <th>{{ $t('QUICK_REPLY.TABLE.NAME') }}</th>
        <th>{{ $t('QUICK_REPLY.TABLE.CONTENT') }}</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="(data, index) in quickReply" :key="index">
          <td>
            {{ data.name | truncate(20, '...') }}
            <i
              v-if="data.name.length > 20"
              v-tooltip.auto="data.name"
              class="ion-information-circled"
            />
          </td>
          <td class="content">
            <bubble-setting
              v-for="(content, idx) in data.contents"
              :key="idx"
              class="item"
              :read-only="true"
            >
              {{ content }}
            </bubble-setting>
          </td>
          <td>
            <div class="button-edit" @click="openEditModal(data, index)">
              {{ $t('QUICK_REPLY.TABLE.EDIT') }}
            </div>
            <div class="button-remove" @click="openDeletePopup(data, index)">
              {{ $t('QUICK_REPLY.TABLE.REMOVE') }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <woot-modal :show.sync="showEdit" :on-close="hideEditModal">
      <modal :show.sync="show" :on-close="hideEditModal">
        <quick-reply-form
          type="edit"
          :on-close="hideEditModal"
          :data-name="selectedQuickReply.name"
          :data-contents="selectedQuickReply.contents"
          :inbox-id="inboxId"
          :selected-index="selectedIndex"
          :submit-form="editQuickReply"
          :loading="loading"
        />
      </modal>
    </woot-modal>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('QUICK_REPLY.FORM.DELETE.CONFIRM_TITLE')"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import QuickReplyForm from './QuickReplyForm.vue';
import Modal from '../../../../../../components/Modal.vue';
import BubbleSetting from '../BubbleSetting.vue';

export default {
  components: { QuickReplyForm, Modal, BubbleSetting },
  filters: {
    truncate(text, length, clamp) {
      clamp = clamp || '...';
      const node = document.createElement('div');
      node.innerHTML = text;
      const content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
  },
  mixins: [alertMixin],
  props: {
    inboxId: {
      type: String,
      default: '',
    },
    quickReply: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      show: true,
      showDeleteConfirmationPopup: false,
      showEdit: false,
      showAdd: false,
      selectedIndex: null,
      selectedQuickReply: {},
    };
  },
  computed: {
    deleteConfirmText() {
      return `${this.$t('QUICK_REPLY.FORM.DELETE.CONFIRM_YES')} ${
        this.selectedQuickReply.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('QUICK_REPLY.FORM.DELETE.CONFIRM_NO')} ${
        this.selectedQuickReply.name
      }`;
    },
    deleteMessage() {
      return `${this.$t('QUICK_REPLY.FORM.DELETE.CONFIRM_MESSAGE')} ${
        this.selectedQuickReply.name
      } ?`;
    },
  },
  methods: {
    openEditModal(data, index) {
      this.showEdit = true;
      this.selectedQuickReply = data;
      this.selectedIndex = index;
    },
    hideEditModal() {
      this.showEdit = false;
    },
    openDeletePopup(data, index) {
      this.showDeleteConfirmationPopup = true;
      this.selectedIndex = index;
      this.selectedQuickReply = data;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.deleteQuickReplies(this.selectedIndex);
      this.closeDeletePopup();
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    editQuickReply(name, contents, index) {
      const data = {
        index,
        name,
        contents,
        inboxId: this.inboxId,
      };

      this.loading = true;
      this.$store
        .dispatch('inboxes/editQuickReplyAction', data)
        .then(() => {
          this.loading = false;
          this.showAlert(this.$t('QUICK_REPLY.FORM.EDIT.SUCCESS_MESSAGE'));
          this.hideEditModal();
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(this.$t('QUICK_REPLY.FORM.EDIT.ERROR_MESSAGE'));
        });
    },
    deleteQuickReplies(idx) {
      const data = {
        index: idx,
        inboxId: this.inboxId,
      };
      if (this.quickReply.length === 1) {
        this.$store
          .dispatch('inboxes/deleteAllQuickReplyAction', this.inboxId)
          .then(() => {
            this.showAlert(this.$t('QUICK_REPLY.FORM.DELETE.SUCCESS_MESSAGE'));
          })
          .catch(() => {
            this.showAlert(this.$t('QUICK_REPLY.FORM.DELETE.ERROR_MESSAGE'));
          });
      } else {
        this.$store
          .dispatch('inboxes/deleteQuickReplyAction', data)
          .then(() => {
            this.showAlert(this.$t('QUICK_REPLY.FORM.DELETE.SUCCESS_MESSAGE'));
          })
          .catch(() => {
            this.showAlert(this.$t('QUICK_REPLY.FORM.DELETE.ERROR_MESSAGE'));
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.wrapper {
  padding-top: 20px;
}

.item {
  margin-right: 5px;
}

.button-edit {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  border: 2px solid transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  z-index: 1000;
  color: var(--grey-08);

  &:hover {
    outline: none;
    border: 2px solid rgba(0, 111, 230, 0.4);
  }
}

.button-remove {
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  color: $alert-color;
  font-weight: 500;
}

.quickreply-table {
  thead {
    border-bottom: 1px solid var(--color-border);
    background: white;

    th:first-child {
      padding-left: var(--space-medium);
      width: 30%;
    }
  }

  tbody {
    tr {
      &:hover {
        background: var(--b-50);
      }

      td {
        padding: var(--space-slab);
        width: 30%;

        &:first-child {
          padding-left: var(--space-medium);
        }

        i {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
