<template>
  <div class="video instagram-video">
    <div class="video-container" @click="onClick">
      <div class="play">
        <i class="ion-play" />
      </div>
      <video>
        <source :src="url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <woot-modal :show.sync="show" :on-close="onClose">
      <video controls>
        <source :src="url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </woot-modal>
  </div>
</template>

<script>
export default {
  props: {
    geturl: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      url: null,
    };
  },
  mounted() {
    this.geturl
      .then(res => {
        this.url = res;
      })
      .catch(() => {
        this.url = null;
      });
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
  },
};
</script>
