<!-- eslint-disable vue/html-closing-bracket-newline -->
<!-- eslint-disable vue/html-indent -->
<template>
  <div>
    <div
      v-if="isActionOpened"
      class="instagram__action-handler"
      :class="reactionClass"
    >
      <span
        v-if="data.content_attributes && data.content_attributes.reaction"
        @click="handleReact('unreact')"
        >Unlike</span
      >
      <span v-else @click="handleReact('react', 'love')">Like</span>
      <span v-if="data.content" @click="doCopy">Copy</span>
    </div>
    <div tabindex="0" class="instagram__action-more" @click="handleOpenAction">
      <img src="~dashboard/assets/icons/ic_more.svg" />
    </div>
  </div>
</template>

<script>
import copy from 'copy-text-to-clipboard';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isActionOpened: false,
    };
  },
  computed: {
    reactionClass() {
      return this.data.content ? 'with-content' : 'no-content';
    },
  },
  methods: {
    handleOpenAction() {
      this.isActionOpened = !this.isActionOpened;
    },
    doCopy(e) {
      e.preventDefault();
      copy(this.data.content);
      bus.$emit('newToastMessage', this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'));
      this.handleOpenAction();
    },
    reactionParams() {
      const { conversation_id } = this.$route.params;
      return {
        conversationId: conversation_id,
        messageId: this.id,
      };
    },
    reactPayload(reaction) {
      return reaction
        ? {
            reaction,
          }
        : undefined;
    },
    handleReact(sender_action, reaction) {
      const payload = this.reactPayload(reaction);
      this.$store
        .dispatch('handleReact', {
          params: this.reactionParams(),
          data: {
            sender_action,
            payload,
          },
        })
        .catch(() => {
          const errorMessage = this.$t(
            'INBOX_MGMT.ADD.INSTAGRAM.REACTION.ERROR',
          );
          this.showAlert(errorMessage);
        })
        .finally(() => this.handleOpenAction());
    },
  },
};
</script>

<style lang="scss" scoped>
.instagram {
  align-items: flex-end;
  display: flex;

  &__action {
    &-more {
      background: var(--indigo-01);
      border: 1px solid var(--grey-04);
      width: 24px;
      height: 24px;
      position: absolute;
      top: 5px;
      left: calc(100% + 12px);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:focus {
        outline: none;
        border: 2px solid var(--blue-06);
      }
    }
    &-handler {
      position: absolute;
      bottom: 100%;
      left: calc(100% + 12px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--white);
      border-radius: 4px;

      &.with-content {
        & > span {
          &:first-child {
            border-radius: 4px 0px 0px 4px;
          }

          &:last-child {
            border-radius: 0px 4px 4px 0px;
          }
        }
      }

      &.no-content {
        & > span {
          border-radius: 4px;
        }
      }

      & > span {
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: var(--grey-08);
        padding: 4px 8px;
        border: 1px solid var(--grey-05);
        cursor: pointer;
        border-left: none;

        &:first-child {
          border-left: 1px solid var(--grey-05);
        }

        &:active {
          outline: none;
          border: 1px solid var(--blue-06);
        }
      }
    }
  }
}
</style>
