<template>
  <VueToggles
    :value="value"
    unchecked-bg="#6A7A8A"
    checked-bg="#0EAD69"
    height="24"
    width="44"
    :disabled="disabled"
    @click="onChange"
  />
</template>

<script>
/* eslint-disable vue/require-prop-types */
import VueToggles from 'vue-toggles';

export default {
  components: {
    VueToggles,
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
      required: true,
    },
  },
};
</script>
