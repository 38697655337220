/* global axios */

import ApiClient from '../ApiClient';

class QuickReplies extends ApiClient {
  constructor() {
    super(`inboxes`, {
      accountScoped: true,
    });
  }

  get(inboxId) {
    return axios.get(`${this.url}/${inboxId}/instagram_quick_replies`);
  }

  modify(inboxId, data) {
    return axios.post(`${this.url}/${inboxId}/instagram_quick_replies`, {
      quick_replies: data,
    });
  }

  delete(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/instagram_quick_replies`);
  }
}

export default new QuickReplies();
