<template>
  <div class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.INSTAGRAM_SETTINGS.TITLE')"
      :sub-title="$t('INBOX_MGMT.INSTAGRAM_SETTINGS.SUBTITLE')"
    >
      <div class="setting-wrapper">
        <span>{{
          isEnabled
            ? $t('INSTAGRAM_SETTING.IS_ENABLED')
            : $t('INSTAGRAM_SETTING.IS_DISABLED')
        }}</span>
        <switch-component
          id="reply-comment"
          name="reply-comment"
          type="switch"
          size="normal"
          :checked="isEnabled"
          :disabled="isLoading"
          @input-change="onSwitchChange"
        />
      </div>
    </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

import SettingsSection from 'dashboard/components/SettingsSection';
import SwitchComponent from 'dashboard/components/ui/Switch';

export default {
  components: {
    SettingsSection,
    SwitchComponent,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      isEnabled: false,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
    currentInboxId() {
      return Number(this.$route.params.inboxId);
    },
  },
  mounted() {
    this.fetchInstagramCommentActivation();
  },
  methods: {
    onSwitchChange(value) {
      this.isLoading = true;
      this.$store
        .dispatch('inboxes/handleInstagramCommentActivation', {
          inboxId: this.currentInboxId,
          value,
        })
        .then(() => {
          this.isEnabled = value;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchInstagramCommentActivation() {
      this.$store
        .dispatch('inboxes/getInstagramCommentActivation', {
          inboxId: this.currentInboxId,
        })
        .then(res => {
          this.isEnabled = res.data.comment_enabled;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-wrapper {
  display: flex;

  span {
    display: block;
    padding: 2px 10px;
  }
}
</style>
