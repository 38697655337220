<template>
  <div class="billing-card-preference">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BillingCardPreference',
};
</script>

<style lang="scss">
.billing-card-preference {
  padding: 32px;
  border: 1px solid #e5eaef;
  border-radius: 8px;
  font-size: 14px;
  background: var(--white);

  .main-content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title-description-container {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    .title {
      font-size: 16px;
      font-weight: 700;
    }

    .title,
    .description {
      margin: 0;
    }
  }
}
</style>
