<template>
  <div class="instagram-get__started">
    <img src="~dashboard/assets/images/hero/instagram-hero-get-started.png" />
    <div class="instagram-get__started-content">
      <span>{{ $t('NEW_CHANNEL.INSTAGRAM.GETTING_STARTED.TITLE') }}</span>
      <p>{{ $t('NEW_CHANNEL.INSTAGRAM.GETTING_STARTED.CONTENT') }}</p>
    </div>
    <div class="instagram-get__started-footer">
      <kata-button @click.native="getStarted()">
        {{ $t('NEW_CHANNEL.INSTAGRAM.GETTING_STARTED.ACTION') }}
      </kata-button>
    </div>
  </div>
</template>

<script>
import KataButton from 'dashboard/components/buttons/Button';
export default {
  components: {
    KataButton,
  },
  mounted() {
    this.$customGTMStep('IGDM-connect', 'Start IGDM', 1);
  },
  methods: {
    getStarted() {
      this.$emit('get-started');
      this.$customGTMStep('IGDM-connect', 'have business account', 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.instagram {
  &-get {
    &__started {
      text-align: center;
      img {
        max-width: 100%;
        margin-bottom: 30px;
      }
      &-content {
        text-align: left;
        font-size: 16px;
        span {
          font-weight: bold;
          display: block;
          color: var(--grey-09);
          margin-bottom: 12px;
          line-height: 20px;
        }
        p {
          line-height: 24px;
          color: var(--grey-08);
        }
      }
      &-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        padding: 24px 48px;
        border-top: 1px solid var(--grey-03);
      }
    }
  }
}
</style>
