<template>
  <div class="row order-banner">
    <div class="title">
      <h3>Enterpise</h3>
      <p>Get enterprise-grade privileges</p>
    </div>
    <div class="line">
      &nbsp;
    </div>
    <div class="description">
      <ul>
        <li>Post-paid method</li>
        <li>Dedicated support & account manager</li>
      </ul>
    </div>
    <div class="action">
      <woot-button
        class-names="nice outline"
        color-scheme=""
        @click="openModal()"
      >
        Contact us
      </woot-button>
    </div>
    <woot-modal
      :show.sync="showModal"
      :on-close="hideModal"
      :close-on-backdrop-click="false"
    >
      <email-enterprise :close-modal="hideModal" />
    </woot-modal>
  </div>
</template>

<script>
import WootButton from '../../../../components/ui/WootButton';
import EmailEnterprise from '../email-enterprise/EmailEnterprise.vue';

export default {
  name: 'BannerInfo',
  components: { WootButton, EmailEnterprise },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-banner {
  background-color: #fafbfd;
  padding: 24px 40px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;

  .title {
    h3 {
      font-size: 28px;
      margin-bottom: 8px;
    }

    p {
      font-size: 12px;
    }
  }

  .line {
    border-right: 1px solid #e5eaef;
    align-self: stretch;
  }

  .description {
    flex: 1;

    ul {
      margin: 0;
      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        column-gap: 16px;
        &:before {
          content: '';
          display: inline;
          height: 14px;
          width: 14px;
          background-image: url('~dashboard/assets/icons/ic_check.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
</style>
