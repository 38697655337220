var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"notification--table-wrap"},[(_vm.notificationMetadata.unreadCount)?_c('woot-submit-button',{staticClass:"button nice success button--fixed-right-top",attrs:{"button-text":_vm.$t('NOTIFICATIONS_PAGE.MARK_ALL_DONE'),"loading":_vm.isUpdating},on:{"click":_vm.onMarkAllDoneClick}}):_vm._e(),_vm._v(" "),_c('table',{staticClass:"woot-table notifications-table"},[_c('tbody',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}]},_vm._l((_vm.notifications),function(notificationItem){return _c('tr',{key:notificationItem.id,on:{"click":function () { return _vm.onClickNotification(notificationItem); }}},[_c('td',[_c('div',{},[_c('h5',{staticClass:"notification--title"},[_vm._v("\n              "+_vm._s(("#" + (notificationItem.primary_actor
                    ? notificationItem.primary_actor.id
                    : 'deleted')))+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"notification--message-title"},[_vm._v("\n              "+_vm._s(notificationItem.push_message_title)+"\n              "),(notificationItem.notification_type === 'trial_expired')?_c('span',[_vm._v("\n                "+_vm._s(' - ' +
                  _vm.bodyExpiredNotif(
                    notificationItem.content_attributes.days_left,
                    notificationItem.primary_actor.trial_expired_date
                  ))+"\n              ")]):_vm._e(),_vm._v(" "),(notificationItem.notification_type === 'plan_expired')?_c('span',[_vm._v("\n                "+_vm._s(' - ' +
                  _vm.bodyPlanExpiredNotif(
                    notificationItem.content_attributes.days_left,
                    notificationItem.primary_actor.end_date
                  ))+"\n              ")]):_vm._e()])])]),_vm._v(" "),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"notification--type"},[_vm._v("\n            "+_vm._s(_vm.$t(
                ("NOTIFICATIONS_PAGE.TYPE_LABEL." + (notificationItem.notification_type))
              ))+"\n          ")])]),_vm._v(" "),_c('td',{staticClass:"thumbnail--column"},[(notificationItem.primary_actor.meta)?_c('span',[(notificationItem.primary_actor.meta.assignee)?_c('thumbnail',{attrs:{"src":notificationItem.primary_actor.meta.assignee.thumbnail,"size":"36px","username":notificationItem.primary_actor.meta.assignee.name}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('td',[_c('div',{staticClass:"text-right timestamp--column"},[_c('span',{staticClass:"notification--created-at"},[_vm._v("\n              "+_vm._s(_vm.dynamicTime(notificationItem.created_at))+"\n            ")])])]),_vm._v(" "),_c('td',[(!notificationItem.read_at)?_c('div',{staticClass:"notification--unread-indicator"}):_vm._e()])])}),0)]),_vm._v(" "),(_vm.showEmptyResult)?_c('empty-state',{attrs:{"title":_vm.$t('NOTIFICATIONS_PAGE.LIST.404')}}):_vm._e(),_vm._v(" "),(_vm.isLoading)?_c('div',{staticClass:"notifications--loader"},[_c('spinner'),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NOTIFICATIONS_PAGE.LIST.LOADING_MESSAGE')))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }