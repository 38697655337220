<template>
  <div class="token">
    <div class="token__key">
      <span>{{ agentBot.access_token }}</span>
      <img
        src="~dashboard/assets/icons/ic_copy_secondary.svg"
        class="cursor-pointer"
        @click="e => onCopy(e, agentBot.access_token)"
      />
    </div>
    <div class="token__status">
      <img
        v-if="computedStep === 1"
        src="~dashboard/assets/icons/ic_info.svg"
      />
      <img
        v-if="computedStep === 2"
        src="~dashboard/assets/icons/ic_info_active.svg"
      />
      <img
        v-if="computedStep === 3"
        src="~dashboard/assets/icons/ic_success.svg"
      />
      <span>{{ renderStep }}</span> {{ renderStatusStep }}
    </div>
  </div>
</template>
<script>
import copy from 'copy-text-to-clipboard';
import alertMixin from 'shared/mixins/alertMixin';
import { ACTIVE, INACTIVE } from '../utils/prepareToDisplay';

export default {
  mixins: [alertMixin],
  props: {
    agentBot: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    computedStep() {
      if (!this.agentBot.outgoing_url) {
        return 1;
      }
      if (this.agentBot.status === INACTIVE) {
        return 2;
      }
      if (this.agentBot.status === ACTIVE) {
        return 3;
      }
      return 0;
    },
    renderStep() {
      return `Step ${this.computedStep} of 3`;
    },
    renderStatusStep() {
      switch (this.computedStep) {
        case 1:
          return this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.STATUS.WAITING');
        case 2:
          return this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.STATUS.READY');
        case 3:
          return this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.STATUS.ACTIVE');
        default:
          return '';
      }
    },
  },
  methods: {
    onCopy(e, accessToken) {
      e.preventDefault();
      copy(accessToken);
      this.showAlert(this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.LIST.COPIED'));
    },
  },
};
</script>

<style lang="scss" scoped>
.token {
  &__key {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    span {
      color: var(--greydark-02);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
      display: block;
      margin-right: 8px;
    }
  }

  &__status {
    font-size: 11px;
    line-height: 17px;
    color: var(--greymed-04);
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
    span {
      display: inline-flex;
      margin-right: 8px;
      font-weight: bold;
    }
  }
}
</style>
