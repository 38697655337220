<template>
  <div class="row content-box full-height">
    <wrapper>
      <agent-bot-form form-type="update" />
    </wrapper>
  </div>
</template>

<script>
import Wrapper from '../components/Wrapper';
import AgentBotForm from '../components/AgentBotForm';

export default {
  components: {
    Wrapper,
    AgentBotForm,
  },
};
</script>
