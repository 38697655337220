<template>
  <form class="settings columns container" @submit.prevent="updateInbox()">
    <div>
      <setting-intro-banner
        :header-image="inbox.avatarUrl"
        :header-title="inboxName"
      >
        <woot-tabs :index="selectedTabIndex" @change="onTabChange">
          <woot-tabs-item
            v-for="tab in tabs"
            :key="tab.key"
            :name="tab.name"
            :show-badge="false"
          />
        </woot-tabs>
      </setting-intro-banner>

      <div v-if="selectedTabKey === 'inbox_settings'" class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT')"
        >
          <!-- Hide avatar upload function -->
          <div v-if="withAvatarUpload">
            <woot-ava`tar-uploader
              :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL')"
              :src="avatarUrl"
              @change="handleImageUpload"
            />`
          </div>
          <woot-input
            v-model.trim="$v.selectedInboxName.$model"
            class="medium-9 columns"
            :class="{ error: $v.selectedInboxName.$error }"
            type="text"
            :label="inboxNameLabel"
            :placeholder="inboxNamePlaceHolder"
            :error="
              $v.selectedInboxName.$error && selectedInboxName === ''
                ? $t('INBOX_MGMT.ADD.CHANNEL_NAME.ERROR_REQUIRED')
                : $v.selectedInboxName.$error && selectedInboxName.length > 50
                ? $t('INBOX_MGMT.ADD.CHANNEL_NAME.ERROR_MAXLENGTH')
                : ''
            "
          />
          <woot-input
            v-if="isAWebWidgetInbox"
            v-model.trim="channelWebsiteUrl"
            class="medium-9 columns"
            :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.LABEL')"
            :placeholder="
              $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_DOMAIN.PLACEHOLDER')
            "
          />
          <woot-input
            v-if="isAWebWidgetInbox"
            v-model.trim="channelWelcomeTitle"
            class="medium-9 columns"
            :label="
              $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.LABEL')
            "
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TITLE.PLACEHOLDER',
              )
            "
          />

          <woot-input
            v-if="isAWebWidgetInbox"
            v-model.trim="channelWelcomeTagline"
            class="medium-9 columns"
            :label="
              $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.LABEL')
            "
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER',
              )
            "
          />

          <label v-if="isAWebWidgetInbox" class="medium-9 columns">
            {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.WIDGET_COLOR.LABEL') }}
            <woot-color-picker v-model="inbox.widget_color" />
          </label>

          <!-- Hide enable channel greeting function -->
          <div v-if="withEnableChannelGreeting">
            <label class="medium-9 columns">
              {{
                $t(
                  'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.LABEL',
                )
              }}
              <select v-model="greetingEnabled">
                <option :value="true">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.ENABLED',
                    )
                  }}
                </option>
                <option :value="false">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.DISABLED',
                    )
                  }}
                </option>
              </select>
              <p class="help-text">
                {{
                  $t(
                    'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_TOGGLE.HELP_TEXT',
                  )
                }}
              </p>
            </label>
          </div>

          <woot-input
            v-if="greetingEnabled"
            v-model.trim="greetingMessage"
            class="medium-9 columns"
            :label="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.LABEL',
              )
            "
            :placeholder="
              $t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_GREETING_MESSAGE.PLACEHOLDER',
              )
            "
          />

          <!-- Hide set reply time function -->
          <div v-if="withSetReplyTime">
            <label class="medium-9 columns">
              {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.TITLE') }}
              <select v-model="replyTime">
                <option key="in_a_few_minutes" value="in_a_few_minutes">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_MINUTES',
                    )
                  }}
                </option>
                <option key="in_a_few_hours" value="in_a_few_hours">
                  {{
                    $t(
                      'INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_FEW_HOURS',
                    )
                  }}
                </option>
                <option key="in_a_day" value="in_a_day">
                  {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.IN_A_DAY') }}
                </option>
              </select>

              <p class="help-text">
                {{ $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.REPLY_TIME.HELP_TEXT') }}
              </p>
            </label>

            <label v-if="isAWebWidgetInbox" class="medium-9 columns">
              {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX') }}
              <select v-model="emailCollectEnabled">
                <option :value="true">
                  {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.ENABLED') }}
                </option>
                <option :value="false">
                  {{ $t('INBOX_MGMT.EDIT.EMAIL_COLLECT_BOX.DISABLED') }}
                </option>
              </select>
              <p class="help-text">
                {{
                  $t(
                    'INBOX_MGMT.SETTINGS_POPUP.ENABLE_EMAIL_COLLECT_BOX_SUB_TEXT',
                  )
                }}
              </p>
            </label>
          </div>

          <label class="medium-9 columns">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT') }}
            <select v-model="autoAssignment">
              <option :value="true">
                {{ $t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.ENABLED') }}
              </option>
              <option :value="false">
                {{ $t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.DISABLED') }}
              </option>
            </select>
            <p class="help-text">
              {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT_SUB_TEXT') }}
            </p>
          </label>

          <label v-if="isAWebWidgetInbox">
            {{ $t('INBOX_MGMT.FEATURES.LABEL') }}
          </label>
          <div v-if="isAWebWidgetInbox" class="widget--feature-flag">
            <input
              v-model="selectedFeatureFlags"
              type="checkbox"
              value="attachments"
              @input="handleFeatureFlag"
            />
            <label for="attachments">
              {{ $t('INBOX_MGMT.FEATURES.DISPLAY_FILE_PICKER') }}
            </label>
          </div>
          <div v-if="isAWebWidgetInbox">
            <input
              v-model="selectedFeatureFlags"
              type="checkbox"
              value="emoji_picker"
              @input="handleFeatureFlag"
            />
            <label for="emoji_picker">
              {{ $t('INBOX_MGMT.FEATURES.DISPLAY_EMOJI_PICKER') }}
            </label>
          </div>

          <submit-button
            :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
            :disabled="$v.$invalid"
            :loading="uiFlags.isUpdatingInbox"
          />
        </settings-section>
        <div v-if="waWebhookUrl !== null">
          <settings-section
            :title="$t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_WEBHOOK_URL_TITLE')"
            :sub-title="
              $t('INBOX_MGMT.SETTINGS_POPUP.WHATSAPP_WEBHOOK_URL_SUB_TEXT')
            "
          >
            <div class="webhook">
              <div class="webhook-wrapper">
                <div class="webhook-url">
                  {{ waWebhookUrl }}
                </div>
                <div
                  v-tooltip="{
                    show: showTooltip,
                    trigger: 'manual',
                    content: 'Webhook copied!',
                  }"
                  class="webhook-copy"
                  @click="onCopyWebhook"
                >
                  <img
                    width="24"
                    src="~dashboard/assets/icons/ic_copy_black.svg"
                  />
                </div>
              </div>
              <div class="webhook-info">
                <img src="~dashboard/assets/icons/ic_info.svg" />
                <span>{{ $t('NEW_CHANNEL.WHATSAPP.WEBHOOK') }}</span>
              </div>
            </div>
          </settings-section>
        </div>
        <facebook-reauthorize
          v-if="isAFacebookInbox && inbox.reauthorization_required"
          :inbox-id="inbox.id"
        />
      </div>

      <!-- update agents in inbox -->

      <div v-if="selectedTabKey === 'collaborators'" class="settings--content">
        <settings-section
          :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS')"
          :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT')"
        >
          <multiselect
            v-model="$v.selectedAgents.$model"
            :options="agentList"
            :class="{ 'error-multiselect': $v.selectedAgents.$error }"
            track-by="id"
            label="name"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            placeholder="Pick some"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @select="$v.selectedAgents.$touch"
          />
          <p class="error-multiselect-text">
            {{
              $v.selectedAgents.$error
                ? $t('INBOX_MGMT.ADD.AGENTS.VALIDATION_ERROR')
                : ''
            }}
          </p>
          <submit-button
            :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
            :disabled="$v.$invalid"
            :loading="isAgentListUpdating"
            type="button"
            @click="updateAgents"
          />
        </settings-section>
      </div>
      <div v-if="selectedTabKey === 'configuration'">
        <div v-if="isATwilioChannel" class="settings--content">
          <settings-section
            :title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.TITLE')"
            :sub-title="$t('INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE')"
          >
            <woot-code :script="twilioCallbackURL" lang="html"></woot-code>
          </settings-section>
        </div>
        <div v-else-if="isAWebWidgetInbox">
          <div class="settings--content">
            <settings-section
              :title="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_HEADING')"
              :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.MESSENGER_SUB_HEAD')"
            >
              <woot-code :script="inbox.web_widget_script"></woot-code>
            </settings-section>

            <settings-section
              :title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_VERIFICATION')"
              :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.HMAC_DESCRIPTION')"
            >
              <woot-code :script="inbox.hmac_token"></woot-code>
            </settings-section>
          </div>
        </div>
      </div>
      <div v-if="isAnInstagramInbox && selectedTabKey === 'ice_breaker'">
        <ice-breaker :inbox-id="currentInboxId" />
      </div>
      <div v-if="isAnInstagramInbox && selectedTabKey === 'quick_reply'">
        <quick-reply :inbox-id="currentInboxId" />
      </div>
      <div v-if="selectedTabKey === 'preChatForm'">
        <pre-chat-form-settings :inbox="inbox" />
      </div>
      <div v-if="selectedTabKey === 'credits'">
        <credits />
      </div>
      <div v-if="selectedTabKey === 'businesshours'">
        <weekly-availability :inbox="inbox" />
      </div>
      <div v-if="isAnInstagramInbox && selectedTabKey === 'instagram_settings'">
        <instagram-settings :inbox="inbox" />
      </div>
      <div v-if="selectedTabKey === 'campaign'">
        <campaign :selected-agents="selectedAgents" />
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { maxLength, required } from 'vuelidate/lib/validators';
import { createMessengerScript } from 'dashboard/helper/scriptGenerator';
import configMixin from 'shared/mixins/configMixin';
import alertMixin from 'shared/mixins/alertMixin';
import copy from 'copy-text-to-clipboard';
import SettingIntroBanner from 'dashboard/components/widgets/SettingIntroBanner';
import SettingsSection from '../../../../components/SettingsSection';
import inboxMixin from 'shared/mixins/inboxMixin';
import FacebookReauthorize from './facebook/Reauthorize';

import SubmitButton from 'dashboard/components/buttons/FormSubmitButton';
import PreChatFormSettings from './PreChatForm/Settings';
import IceBreaker from './components/IceBreaker.vue';
import WeeklyAvailability from './components/WeeklyAvailability';
import InstagramSettings from './components/InstagramSettings';
import QuickReply from './components/quickreply/QuickReply.vue';
import Campaign from './components/Campaign';
import Credits from './components/Credits';

export default {
  components: {
    Credits,
    SettingIntroBanner,
    SettingsSection,
    FacebookReauthorize,
    PreChatFormSettings,
    IceBreaker,
    InstagramSettings,
    QuickReply,
    WeeklyAvailability,
    Campaign,
    SubmitButton,
  },
  mixins: [alertMixin, configMixin, inboxMixin],
  data() {
    return {
      avatarFile: null,
      avatarUrl: '',
      selectedAgents: [],
      greetingEnabled: true,
      greetingMessage: '',
      autoAssignment: false,
      emailCollectEnabled: false,
      isAgentListUpdating: false,
      selectedInboxName: '',
      waWebhookUrl: '',
      showTooltip: false,
      channelWebsiteUrl: '',
      channelWelcomeTitle: '',
      channelWelcomeTagline: '',
      selectedFeatureFlags: [],
      replyTime: '',
      autoAssignmentOptions: [
        {
          value: true,
          label: this.$t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.ENABLED'),
        },
        {
          value: false,
          label: this.$t('INBOX_MGMT.EDIT.AUTO_ASSIGNMENT.DISABLED'),
        },
      ],
      selectedTabIndex: 0,
      withAvatarUpload: process.env.WITH_SETTINGS_AVATAR_UPLOAD === 'true',
      withEnableChannelGreeting:
        process.env.WITH_SETTINGS_ENABLE_CHANNEL_GREETING === 'true',
      withSetReplyTime: process.env.WITH_SETTINGS_SET_REPLY_TIME === 'true',
      withBusinessHours: process.env.WITH_SETTINGS_BUSINESS_HOURS === 'true',
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      uiFlags: 'inboxes/getUIFlags',
      currentChat: 'getSelectedChat',
      featureFlags: 'flags/featureFlags',
    }),
    selectedTabKey() {
      return this.tabs[this.selectedTabIndex]?.key;
    },
    tabs() {
      let visibleToAllChannelTabs = [
        {
          key: 'inbox_settings',
          name: this.$t('INBOX_MGMT.TABS.ACCOUNTS'),
        },
        {
          key: 'collaborators',
          name: this.$t('INBOX_MGMT.TABS.COLLABORATORS'),
        },
      ];

      if (this.isAnInstagramInbox) {
        let channelTabs = [
          ...visibleToAllChannelTabs,
          {
            key: 'quick_reply',
            name: this.$t('INBOX_MGMT.TABS.QUICK_REPLY'),
          },
        ];

        if (this.withBusinessHours) {
          channelTabs = [
            ...channelTabs,
            {
              key: 'businesshours',
              name: this.$t('INBOX_MGMT.TABS.BUSINESS_HOURS'),
            },
          ];
        }

        if (this.featureFlags.conversation.instagram.iceBreaker) {
          channelTabs = [
            ...channelTabs,
            {
              key: 'ice_breaker',
              name: this.$t('INBOX_MGMT.TABS.ICE_BREAKER'),
            },
          ];
        }

        if (this.featureFlags.conversation.instagram.commentReply) {
          channelTabs = [
            ...channelTabs,
            {
              key: 'instagram_settings',
              name: this.$t('INBOX_MGMT.TABS.INSTAGRAM_SETTINGS'),
            },
          ];
        }

        return channelTabs;
      }

      if (this.isAWebWidgetInbox) {
        if (this.withBusinessHours) {
          visibleToAllChannelTabs = [
            ...visibleToAllChannelTabs,
            {
              key: 'businesshours',
              name: this.$t('INBOX_MGMT.TABS.BUSINESS_HOURS'),
            },
          ];
        }
        return [
          ...visibleToAllChannelTabs,
          {
            key: 'campaign',
            name: this.$t('INBOX_MGMT.TABS.CAMPAIGN'),
          },
          {
            key: 'preChatForm',
            name: this.$t('INBOX_MGMT.TABS.PRE_CHAT_FORM'),
          },
          {
            key: 'configuration',
            name: this.$t('INBOX_MGMT.TABS.CONFIGURATION'),
          },
        ];
      }

      if (this.isWhatsappChannel && this.withBusinessHours) {
        return [
          ...visibleToAllChannelTabs,
          {
            key: 'credits',
            name: this.$t('INBOX_MGMT.TABS.CREDITS'),
          },
          {
            key: 'businesshours',
            name: this.$t('INBOX_MGMT.TABS.BUSINESS_HOURS'),
          },
        ];
      }

      if (this.isATwilioChannel) {
        return [
          ...visibleToAllChannelTabs,
          {
            key: 'configuration',
            name: this.$t('INBOX_MGMT.TABS.CONFIGURATION'),
          },
        ];
      }
      return visibleToAllChannelTabs;
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxName() {
      if (this.isATwilioSMSChannel || this.isATwilioWhatsappChannel) {
        return `${this.inbox.name} (${this.inbox.phone_number})`;
      }
      return this.inbox.name;
    },
    messengerScript() {
      return createMessengerScript(this.inbox.page_id);
    },
    inboxNameLabel() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.LABEL');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL');
    },
    inboxNamePlaceHolder() {
      if (this.isAWebWidgetInbox) {
        return this.$t('INBOX_MGMT.ADD.WEBSITE_NAME.PLACEHOLDER');
      }
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.PLACEHOLDER');
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'settings_inbox_show') {
        this.fetchInboxSettings();
      }
    },
  },
  mounted() {
    this.fetchInboxSettings();
  },
  activated() {
    const selectTab = this.$route.query.tab;
    if (selectTab) {
      const getIndex = this.tabs.findIndex(tab => tab.key === selectTab);
      if (getIndex >= 0) {
        this.onTabChange(getIndex);
      }
    }
  },
  methods: {
    handleFeatureFlag(e) {
      this.selectedFeatureFlags = this.toggleInput(
        this.selectedFeatureFlags,
        e.target.value,
      );
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        return selected.filter(flag => flag !== current);
      }
      return [...selected, current];
    },
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
    fetchInboxSettings() {
      this.selectedTabIndex = 0;
      this.selectedAgents = [];
      this.$store.dispatch('agents/get');
      this.$store.dispatch('teams/get');
      this.$store.dispatch('inboxes/get').then(() => {
        this.fetchAttachedAgents();
        this.avatarUrl = this.inbox.avatar_url;
        this.selectedInboxName = this.inbox.name;
        this.waWebhookUrl = this.inbox.whatsapp_webhook_url;
        this.greetingEnabled = this.inbox.greeting_enabled;
        this.greetingMessage = this.inbox.greeting_message;
        this.autoAssignment = this.inbox.enable_auto_assignment;
        this.emailCollectEnabled = this.inbox.enable_email_collect;
        this.channelWebsiteUrl = this.inbox.website_url;
        this.channelWelcomeTitle = this.inbox.welcome_title;
        this.channelWelcomeTagline = this.inbox.welcome_tagline;
        this.selectedFeatureFlags = this.inbox.selected_feature_flags || [];
        this.replyTime = this.inbox.reply_time;
      });
    },
    onCopyWebhook(e) {
      e.preventDefault();
      copy(this.waWebhookUrl);
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 1500);
    },
    async fetchAttachedAgents() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.currentInboxId,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.selectedAgents = inboxMembers;
      } catch (error) {
        //  Handle error
      }
    },
    async updateAgents() {
      const agentList = this.selectedAgents.map(el => el.id);
      this.isAgentListUpdating = true;
      try {
        await this.$store.dispatch('inboxMembers/create', {
          inboxId: this.currentInboxId,
          agentList,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
      this.isAgentListUpdating = false;
    },
    async updateInbox() {
      try {
        const payload = {
          id: this.currentInboxId,
          name: this.selectedInboxName,
          enable_auto_assignment: this.autoAssignment,
          enable_email_collect: this.emailCollectEnabled,
          greeting_enabled: this.greetingEnabled,
          greeting_message: this.greetingMessage || '',
          channel: {
            widget_color: this.inbox.widget_color,
            website_url: this.channelWebsiteUrl,
            welcome_title: this.channelWelcomeTitle || '',
            welcome_tagline: this.channelWelcomeTagline || '',
            selectedFeatureFlags: this.selectedFeatureFlags,
            reply_time: this.replyTime || 'in_a_few_minutes',
          },
        };
        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
        }
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      }
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
  },
  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
    selectedInboxName: {
      required,
      maxLength: maxLength(50),
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.settings {
  background: $color-white;

  .tabs {
    padding: 0;
    margin-bottom: -1px;
  }
}

.webhook {
  background: var(--greylight-02);
  border: 1px solid var(--greylight-04);
  border-radius: 8px;
  padding: 16px 18px;
  width: fit-content;
  &-wrapper {
    background: var(--white);
    border: 1px solid var(--grey-04);
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: var(--greydark-02);
    display: flex;
  }
  &-url {
    padding: 14px 16px;
    flex: 1;
  }
  &-copy {
    padding: 0 16px;
    border-left: 1px solid var(--grey-04);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &-info {
    margin: 8px 0;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    span {
      color: var(--greydark-02);
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.widget--feature-flag {
  padding-top: var(--space-small);
}

.error-multiselect {
  border: 1px solid red;
  border-radius: 6px;
}

.error-multiselect-text {
  margin-bottom: 12px;
  margin-top: -6px;
  color: red;
  font-size: 12px;
}
</style>
