<template>
  <woot-modal
    :show="showAccountModal"
    :on-close="() => $emit('close-account-modal')"
    class="account-selector--modal"
  >
    <woot-modal-header
      :header-title="$t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS')"
      :header-content="$t('SIDEBAR_ITEMS.SELECTOR_SUBTITLE')"
    />
    <div
      v-for="account in currentUser.accounts"
      :key="account.id"
      class="account-selector"
    >
      <a @click="account.status === 'active' && onSwitchAccount(account.id)">
        <i v-if="account.id === accountId" class="ion ion-ios-checkmark" />
        <label :for="account.name" class="account--details">
          <div class="account--name" :class="account.status">
            {{ account.name }}
          </div>
          <div class="account--role" :class="account.status">
            {{ account.role }}
          </div>
        </label>
      </a>
    </div>
    <div
      v-if="globalConfig.createNewAccountFromDashboard"
      class="modal-footer delete-item"
    >
      <button
        class="button success large expanded nice"
        @click="$emit('show-create-account-modal')"
      >
        {{ $t('CREATE_ACCOUNT.NEW_ACCOUNT') }}
      </button>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountAPI from 'dashboard/api/account';
export default {
  props: {
    showAccountModal: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
    }),
  },
  methods: {
    async onSwitchAccount(accountId) {
      try {
        await AccountAPI.switchAccount(accountId);
        window.location.href = `/app/accounts/${accountId}/dashboard`;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>
