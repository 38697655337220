<template>
  <loading-state :message="$t('CONFIRM_EMAIL')"></loading-state>
</template>
<script>
import LoadingState from '../../components/widgets/LoadingState';
import Auth from '../../api/auth';
import { DEFAULT_REDIRECT_URL } from '../../constants';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    LoadingState,
  },
  mixins: [alertMixin],
  props: {
    confirmationToken: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.confirmToken();
  },
  methods: {
    async confirmToken() {
      try {
        await Auth.verifyPasswordToken({
          confirmationToken: this.confirmationToken,
        });
        window.location = DEFAULT_REDIRECT_URL;
      } catch (error) {
        if (error && error.status === 401) {
          const { message } = error.data;
          this.showAlert(message);
        }
        setTimeout(() => {
          window.location = DEFAULT_REDIRECT_URL;
        }, 4000);
      }
    },
  },
};
</script>
