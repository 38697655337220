<template>
  <div class="order-detail-card row flex-dir-column">
    <div v-for="(order, index) in orders" :key="index" class="orders">
      <OrderItem
        :image="order.image"
        :title="order.title"
        :description="order.description"
        :price="order.price"
      />
    </div>
    <div class="medium-offset-1 total-amount">
      <div class="item row tax justify-space-between align-middle">
        <div class="name">Tax {{ taxRate }}%</div>
        <div class="price">
          {{ getTax | toCurrency }}
        </div>
      </div>
      <div class="item row total justify-space-between align-middle">
        <div class="name">
          Total amount due
        </div>
        <div class="price">
          {{ getTotal | toCurrency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderItem from './OrderItem';

export default {
  name: 'OrderDetail',
  components: { OrderItem },
  props: {
    orders: {
      type: Array,
      default: () => [],
      required: false,
    },
    tax: {
      type: Number,
      default: 0,
    },
    taxRate: {
      type: Number,
      default: 11,
    },
    grandTotal: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getTax() {
      return this.calculateTax();
    },
    getTotal() {
      return (
        this.grandTotal || this.calculateGrandTotal() + this.calculateTax()
      );
    },
  },
  methods: {
    calculateTax() {
      return (this.calculateGrandTotal() * this.taxRate) / 100;
    },
    calculateGrandTotal() {
      return this.orders.reduce((prev, cur) => prev + cur.price, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-detail-card {
  border: 1px dashed #c6d0db;
  border-radius: 8px;
  padding: 32px;
  font-size: 12px;

  .total-amount {
    border-top: 1px solid #e5eaef;
    padding-top: 16px;

    .tax {
      color: #b0b7bf;

      .name {
        font-weight: 700;
      }
    }

    .total {
      font-weight: 700;

      .price {
        font-size: 20px;
      }
    }

    .item:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
