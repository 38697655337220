import moment from 'moment';

export const getters = {
  getSubscriptionInfo(state) {
    return state.info;
  },
  getSubscriptionInfoPlan(state) {
    return state.plan;
  },
  isSubscriptionActive(state) {
    return state.plan.status === 'active';
  },
  getActivePlanId(state) {
    return state.plan.plan_id;
  },
  getSubscriptionQuota(state) {
    return {
      additionalMauQuota: state.plan.additional_mau_quota || 0,
      additionalMauUsed: state.plan.additional_mau_count || 0,
      additionalMauLeft:
        state.plan.additional_mau_quota - state.plan.additional_mau_count || 0,
      mauQuota: state.plan.mau_quota || 0,
      mauQuotaUsed: state.plan.mau_count || 0,
      mauQuotaLeft: state.plan.mau_quota - state.plan.mau_count || 0,
      agentQuota: state.plan.agent_quota || 100,
      agentQuotaUsed: state.plan.agent_count || 0,
      agentQuotaLeft: state.plan.agent_quota - state.plan.agent_count || 0,
    };
  },
  getCurrentSubscription(state) {
    const dueDaysNotify = 7;
    let data = { ...state.info, isTrial: false, notify: false };
    if (state.info.plan && state.info.days_left) {
      if (state.info.plan.toLowerCase() === 'trial') {
        data = { ...data, isTrial: true };
      }
      if (
        state.info.plan.toLowerCase() === 'trial' &&
        state.info.days_left <= dueDaysNotify
      ) {
        return { ...data, notify: true };
      }
    }
    return data;
  },
  getSubscriptionNextBilling(state) {
    if (state.plan.next_billing && state.plan.next_billing.date) {
      const dueDaysNotify = 7;
      const expiredDate = moment(state.plan.next_billing.date);
      const dateNow = moment();
      const daysRemaining = expiredDate.diff(dateNow, 'days');
      return {
        ...state.plan.next_billing,
        next_plan_subscription_id: state.plan.next_plan_subscription_id,
        daysRemaining,
        notify: daysRemaining <= dueDaysNotify,
      };
    }
    return {
      ...state.plan.next_billing,
      next_plan_subscription_id: state.plan.next_plan_subscription_id,
      daysRemaining: null,
      notify: null,
    };
  },
  getSubscriptionNotify(state) {
    const dueDaysNotify = 7;
    const planExpiredInfo = state.info;
    const planSubscriptionInfo = state.plan;
    return {
      isTrial: planExpiredInfo.plan
        ? planExpiredInfo.plan.toLowerCase() === 'trial'
        : false,
      isInDueDays:
        planExpiredInfo.days_left <= dueDaysNotify &&
        planExpiredInfo.days_left > 0,
      isExpired:
        (planSubscriptionInfo.status &&
          planSubscriptionInfo.status !== 'active') ||
        planExpiredInfo.days_left <= 0,
      isHaveRenewal: !!planSubscriptionInfo.next_plan_subscription_id,
      expiredIn: planExpiredInfo.days_left,
      expiredDate: planExpiredInfo.expired_date,
      isMauLimit:
        planSubscriptionInfo.mau_count >= planSubscriptionInfo.mau_quota
          ? planSubscriptionInfo.additional_mau_count >=
            planSubscriptionInfo.additional_mau_quota
          : false,
    };
  },
  getBillingInfo(state) {
    return state.billingInfo;
  },
  getUIFlags(state) {
    return state.uiFlags;
  },
};
