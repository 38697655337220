import { render, staticRenderFns } from "./Wrapper.vue?vue&type=template&id=e89c5a88&scoped=true&"
var script = {}
import style0 from "./Wrapper.vue?vue&type=style&index=0&id=e89c5a88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e89c5a88",
  null
  
)

export default component.exports