<template>
  <SubscriptionCard>
    <div class="small-8">
      <img
        class="badge-plan"
        src="~dashboard/assets/images/kata/badge-plan.svg"
      />
      <h3 class="title">
        {{ getSubscriptionInfoPlan.plan_name }} plan
        <span class="title-info">
          <span
            v-if="
              getSubscriptionNotify.isExpired ||
                getSubscriptionNotify.isInDueDays
            "
            class="title-info"
          >Expired
          </span>

          <span
            v-if="
              isSubscriptionActive &&
                getSubscriptionNotify.isInDueDays &&
                !getSubscriptionNotify.isHaveRenewal
            "
          >in {{ getSubscriptionNotify.expiredIn }} days</span
          >
        </span>
        <router-link
          v-if="
            (getSubscriptionNotify.isInDueDays ||
              getSubscriptionNotify.isExpired) &&
              !getSubscriptionNotify.isHaveRenewal
          "
          class="renew"
          :to="{
            name: 'billing_credits_plan_renewal',
            params: { renewalPlanId: getSubscriptionInfoPlan.plan_id },
          }"
        >
          Renew
        </router-link>
      </h3>
      <p class="description">
        Monthly active user
        <span class="bold">
          {{ getSubscriptionQuota.mauQuotaUsed | toNumber }}/{{
            getSubscriptionQuota.mauQuota | toNumber
          }}
        </span>
        used
        <span v-if="getSubscriptionNextBilling.next_plan_subscription_id">
          (Reset on
          {{ getSubscriptionInfoPlan.end_date | moment('D MMMM YYYY') }})
        </span>
      </p>
      <p class="description">
        Next billing total
        {{ getSubscriptionNextBilling.price | toCurrency }} on
        {{ getSubscriptionNextBilling.date | moment('D MMMM YYYY') }}
      </p>
    </div>
    <div class="small-4 text-right">
      <woot-button size="small" @click="handleClickCard">
        Change plan
      </woot-button>
    </div>
  </SubscriptionCard>
</template>

<script>
import SubscriptionCard from '../SubscriptionCard';
import { mapGetters } from 'vuex';
import WootButton from 'dashboard/components/ui/WootButton';

export default {
  name: 'SubcriptionCardPlusPlan',
  components: {
    WootButton,
    SubscriptionCard,
  },
  computed: {
    ...mapGetters({
      isSubscriptionActive: 'subscription/isSubscriptionActive',
      getSubscriptionNextBilling: 'subscription/getSubscriptionNextBilling',
      getSubscriptionQuota: 'subscription/getSubscriptionQuota',
      getSubscriptionInfoPlan: 'subscription/getSubscriptionInfoPlan',
      getCurrentSubscription: 'subscription/getCurrentSubscription',
      getSubscriptionNotify: 'subscription/getSubscriptionNotify',
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    handleClickCard() {
      this.$router.push(
        { name: 'billing_credits_plan' },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-transform: capitalize;
}

.title-info {
  font-weight: normal;
  color: #ff6150;
  font-size: 14px;
  margin-left: 8px;
}

.renew {
  font-weight: normal;
  color: #0057b4;
  font-size: 14px;
  text-decoration: underline;
  margin-left: 8px;
}

.badge-plan {
  margin-bottom: 16px;
}
</style>

