var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"getting-started__wrapper"},[_c('div',{staticClass:"banner-wrapper"},[(_vm.isConfirmed !== undefined && !_vm.isConfirmed)?_c('confirmation'):_vm._e(),_vm._v(" "),(
        _vm.getSubscriptionNotify.isTrial && _vm.getSubscriptionNotify.isInDueDays
      )?_c('banner-trial-warning'):_vm._e(),_vm._v(" "),(
        _vm.getCurrentSubscription.plan !== 'Trial' &&
          _vm.getSubscriptionNotify.isMauLimit
      )?_c('banner-mau-warning'):_vm._e(),_vm._v(" "),(
        !_vm.getSubscriptionNotify.isTrial &&
          (_vm.getSubscriptionNotify.isExpired ||
            _vm.getSubscriptionNotify.isInDueDays)
      )?_c('banner-plan-warning'):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"getting-started__box welcome"},[_c('div',{staticClass:"welcome-item"},[_c('h1',[_vm._v(_vm._s(_vm.$t('GETTING_STARTED.WELCOME.TITLE')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('GETTING_STARTED.WELCOME.DESCRIPTION')))])]),_vm._v(" "),_c('img',{attrs:{"src":require("dashboard/assets/images/getting-started/getting-started-welcome-new.svg")}})]),_vm._v(" "),_c('div',{staticClass:"getting-started__channel"},[_c('div',{staticClass:"getting-started__box"},[_c('img',{attrs:{"src":require("dashboard/assets/images/kata/channel-instagram.svg")}}),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.$t('GETTING_STARTED.CHANNEL.INSTAGRAM.TITLE')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('GETTING_STARTED.CHANNEL.INSTAGRAM.DESCRIPTION')))]),_vm._v(" "),(_vm.isAdmin)?_c('kata-button',{attrs:{"variant":"outline","disabled":!_vm.isConfirmed},on:{"click":function($event){_vm.onClickConnect('INSTAGRAM');
          _vm.$customGTM(
            'Event',
            'Getting Started - Connect Instagram',
            'Button',
            'Connect-instagram'
          );}}},[_vm._v("\n        "+_vm._s(_vm.$t('GETTING_STARTED.ACTION.CONNECT'))+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"getting-started__box"},[_c('img',{attrs:{"src":require("dashboard/assets/images/kata/channel-whatsapp.svg")}}),_vm._v(" "),_c('h2',[_vm._v(_vm._s(_vm.$t('GETTING_STARTED.CHANNEL.WHATSAPP.TITLE')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('GETTING_STARTED.CHANNEL.WHATSAPP.DESCRIPTION')))]),_vm._v(" "),_c('div',{staticClass:"channel-whatsapp"},[(_vm.isAdmin)?_c('kata-button',{attrs:{"variant":"outline","disabled":!_vm.isConfirmed},on:{"click":function($event){return _vm.onClickConnect('WHATSAPP')}}},[_vm._v("\n          "+_vm._s(_vm.$t('GETTING_STARTED.ACTION.CONNECT'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('GETTING_STARTED.CHANNEL.WHATSAPP.DONT_HAVE_ACCOUNT'))},on:{"click":function($event){return _vm.$customGTM(
              'Event',
              'Getting Started - WABA Registration',
              'Link Click',
              'Register Now'
            )}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }