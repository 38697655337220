<template>
  <div v-if="messageType === 'attachments'">
    <div v-for="(attachment, index) in attachments" :key="index">
      <bubble-instagram-image
        v-if="attachment === 'image'"
        :geturl="getMessageDetail(index)"
      />
      <bubble-instagram-video
        v-if="attachment === 'video'"
        :geturl="getMessageDetail(index)"
      />
      <bubble-instagram-file
        v-if="attachment === 'file'"
        :geturl="getMessageDetail(index)"
      />
    </div>
  </div>
  <div v-else-if="messageType === 'story'">
    <bubble-instagram-story
      :geturl="getMessageDetail()"
      :attachments="attachments"
    />
  </div>
  <div v-else-if="messageType === 'isUnsupported' || messageType === 'cards'">
    <bubble-instagram-unsupported :messagetype="messageType" />
  </div>
  <div v-else>
    <woot-loading-state v-if="isLoading" />
  </div>
</template>

<script>
import BubbleInstagramImage from './Image';
import BubbleInstagramVideo from './Video';
import BubbleInstagramFile from './File';
import BubbleInstagramStory from './Story';
import BubbleInstagramUnsupported from './Unsupported';
import ConversationApi from '../../../../../api/inbox/conversation';

export default {
  components: {
    BubbleInstagramImage,
    BubbleInstagramVideo,
    BubbleInstagramFile,
    BubbleInstagramUnsupported,
    BubbleInstagramStory,
  },
  props: {
    conversationId: {
      type: Number,
      required: true,
    },
    sourceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      messageType: null,
      attachments: null,
      url: null,
    };
  },
  created() {
    this.getMessageType();
  },
  methods: {
    async getMessageType() {
      try {
        this.isLoading = true;
        const res = await ConversationApi.getMessageType({
          conversationId: this.conversationId,
          sourceId: this.sourceId,
        });
        const { type, data } = res.data;
        this.messageType = type;
        this.attachments = data;
      } catch (err) {
        this.messageType = null;
        this.attachments = null;
      } finally {
        this.isLoading = false;
      }
    },
    async getMessageDetail(index) {
      const url = await ConversationApi.getMessageDetails({
        conversationId: this.conversationId,
        sourceId: this.sourceId,
        index,
      });
      return url;
    },
  },
};
</script>
