<template>
  <div class="bank-list">
    <BankCard
      v-for="bankItem in banksList"
      :key="bankItem.name"
      v-model="bank"
      :bank="bankItem"
      @input="value => $emit('change', value)"
    />
  </div>
</template>

<script>
import BankCard from './BankCard';
import { bankHelper } from 'dashboard/helper/bankHelper';
export default {
  name: 'BankList',
  components: {
    BankCard,
  },
  data() {
    return {
      banksList: bankHelper,
      bank: '',
    };
  },
  mounted() {
    const setDefaultBank = bankHelper[0].name;
    this.bank = setDefaultBank;
    this.$emit('change', setDefaultBank);
  },
};
</script>

<style lang="scss" scoped>
.bank-list {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
}
</style>
