export const state = {
  conversation: {
    instagram: {
      commentReply:
        process.env.WITH_CONVERSATION_INSTAGRAM_COMMENT_REPLY === 'true',
      iceBreaker:
        process.env.WITH_CONVERSATION_INSTAGRAM_ICE_BREAKER === 'true',
    },
  },
};

export const getters = {
  featureFlags: $state => {
    return $state;
  },
};

export default {
  namespaced: true,
  getters,
  state,
};
