<template>
  <button
    :type="type"
    class="button nice"
    :disabled="disabled"
    :class="variant"
    @click="onClick"
  >
    <i
      v-if="!isLoading && icon"
      class="icon"
      :class="buttonIconClass + ' ' + icon"
    />
    <spinner v-if="isLoading" />
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    buttonIconClass: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
button:disabled {
  background: var(--grey-04);
  color: var(--grey-02);
  border-color: var(--white);
}
</style>
