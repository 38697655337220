import Onboarding from './Onboarding';
import { frontendURL } from '../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('onboarding'),
      name: 'onboarding',
      component: Onboarding,
    },
  ],
};
