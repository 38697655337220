<template>
  <div class="banner" :class="variant">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  border: 1px solid var(--green-01-a);
  background-color: #eafdf5;
  padding: 18px;
  border-radius: 8px;

  * {
    font-size: 12px;
  }

  &.info {
    border-color: var(--indigo-04);
    background-color: var(--indigo-01);
  }

  &.success {
    border-color: var(--green-01-a);
    background-color: var(--green-01);
  }

  &.default {
    background-color: var(--greylight-02);
    border-color: var(--greylight-04);
  }

  &.alert {
    background-color: var(--red-light);
    border-color: var(--red-dark);
  }
}
</style>
