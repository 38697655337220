<template>
  <div class="step" :class="{ active: active }">
    <div class="border" />
    <div class="title">
      <div class="icon">
        <div class="check">
          <inline-svg
            :src="require('../../../assets/icons/ic_check.svg')"
            fill="#0ead69"
            width="16"
            height="16"
          />
        </div>
        <div class="doughnut" />
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'Step',
  components: { InlineSvg },
  props: ['active'],
};
</script>

<style lang="scss" scoped>
.step {
  text-align: left;
  font-size: 14px;
  flex: 1;
  color: #0c965a;

  .border {
    height: 4px;
    border-radius: 100px;
    background-color: #0ead69;
  }

  .title {
    display: flex;
    gap: 8px;
    align-items: center;

    .icon {
      .check,
      .doughnut {
        display: flex;
      }

      .doughnut {
        display: none;
      }
    }

    :first-letter {
      text-transform: uppercase;
    }

    text-transform: lowercase;
  }

  &.active:not(:last-child) {
    .border {
      background-color: #006fe6;
    }

    color: #0057b4;
    font-weight: 700;

    .icon {
      .check {
        display: none;
      }

      .doughnut {
        display: block;
        border-color: #006fe6;
      }
    }

    ~ .step {
      .border {
        background-color: #c6d0db;
      }

      .icon {
        .check {
          display: none;
        }

        .doughnut {
          display: block;
          border-color: #c6d0db;
        }
      }

      color: #6a7a8a;
    }
  }
}

.doughnut {
  border: 2px solid;
  border-radius: 100%;
  height: 16px;
  width: 16px;
}
</style>
