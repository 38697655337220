<template>
  <Banner :variant="variant">
    <div class="banner-content row" :class="variant">
      <div class="row" :class="iconVerticalPosition">
        <span class="icon" :class="iconClass" />
      </div>
      <div class="text-content">
        <slot />
      </div>
    </div>
  </Banner>
</template>

<script>
import Banner from './Banner';

export default {
  name: 'BannerIcon',
  components: { Banner },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    iconClass: {
      type: String,
      default: 'ion-checkmark-circled',
    },
    iconVerticalPosition: {
      type: String,
      default: 'align-middle',
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-content {
  gap: 18px;

  .text-content {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .icon {
    font-size: 20px;
  }

  &.info {
    .icon {
      color: #1f2d3d;
    }
  }

  &.success {
    .icon {
      color: #1f2d3d;
    }
  }

  &.default {
    .icon {
      color: var(--greymed-04);
    }
  }

  &.alert {
    .icon {
      color: var(--red-dark);
    }
  }
}
</style>
