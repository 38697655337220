<template>
  <div class="whatsapp-container">
    <div class="whatsapp-container__content">
      <p>{{ description }}</p>
      <h3>{{ title }}</h3>
      <slot />
    </div>
    <img src="~dashboard/assets/images/hero/whatsapp-illustration.svg" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    description: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.whatsapp {
  &-container {
    display: flex;
    &__content {
      flex: 1;
      h3 {
        display: block;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        margin-bottom: 24px;
      }
      p {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 24px;
        color: var(--grey-08);
      }
    }
    img {
      flex: 1;
      max-height: 300px;
    }
  }
}
.understand {
  padding: 20px 30px;
  border: 1px solid var(--grey-04);
  border-radius: 4px;
  margin-bottom: 0;
  input[type='checkbox'] {
    margin: 0;
  }
}
.error {
  .message {
    margin: 0;
    margin-top: 5px;
  }
}
</style>
