export const ACTIVE = 'active';
export const INACTIVE = 'inactive';
export const CREATE_FORM = 'create';
export const UPDATE_FORM = 'update';
export const VIEW_FORM = 'view';

const prepareToDisplay = data => {
  const {
    id,
    name,
    access_token,
    description,
    inboxes,
    outgoing_url,
    status,
  } = data;
  const booleanStatus = status === ACTIVE;
  return {
    credentials: {
      name,
      description,
    },
    agentBotId: id,
    selectedInboxes: inboxes,
    accessToken: access_token,
    webhookUrl: outgoing_url,
    status: booleanStatus,
  };
};

export default prepareToDisplay;
