<template>
  <div class="medium-12 column">
    <div class="row align-center">
      <div class="medium-4 column hero-container">
        <img src="~dashboard/assets/images/hero/hero-forgotpassword.svg" />
      </div>
      <div class="medium-8 column login">
        <div class="row align-center">
          <div class="small-8 medium-6 login__hero align-self-top">
            <img
              :src="globalConfig.logo"
              :alt="globalConfig.installationName"
              class="hero--logo"
            />
            <h2 v-if="!isFinished" class="hero__title">
              {{ $t('SET_NEW_PASSWORD.TITLE') }}
            </h2>
            <h2 v-else class="hero__title">
              {{ $t('SET_NEW_PASSWORD.PASSWORD_UPDATED') }}
            </h2>
            <p v-if="!isFinished" class="description">
              {{ $t('SET_NEW_PASSWORD.DESCRIPTION') }}
            </p>
          </div>
        </div>
        <div class="row align-center">
          <edit-password
            v-if="!isFinished"
            :reset-password-token="resetPasswordToken"
            :redirect-url="redirectUrl"
            :config="config"
            @set-is-finished="onSetIsFinished"
          />
          <edit-password-success v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import EditPassword from './EditPassword.vue';
import EditPasswordSuccess from './EditPasswordSuccess.vue';

export default {
  components: {
    EditPassword,
    EditPasswordSuccess,
  },
  mixins: [globalConfigMixin],
  props: {
    resetPasswordToken: String,
    redirectUrl: String,
    config: String,
  },
  data() {
    return {
      isFinished: false,
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  methods: {
    onSetIsFinished(isFinished) {
      this.isFinished = isFinished;
    },
  },
};
</script>

<style scoped lang="scss">
.description {
  margin-top: 24px;
}
</style>
