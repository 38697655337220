import Vue from 'vue';
import moment from 'moment';

Vue.filter('toCurrency', value => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('us-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    currencyDisplay: 'narrowSymbol',
  });
  return formatter.format(value);
});

Vue.filter('toCurrencyIDR', value => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('us-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter('toNumber', value => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('us-ID', {
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter('toBankAccount', value => {
  if (!value) return value;
  return value.toString().replace(/\d{4}(?=.)/g, '$& ');
});

Vue.filter('moment', (value, format) => {
  return moment(value).format(format);
});
