<template>
  <SubcriptionCardFreePlan
    v-if="getSubscriptionNotify.isTrial && getSubscriptionNotify.isExpired"
  />
  <SubcriptionCardFreeTrial
    v-else-if="
      getSubscriptionNotify.isTrial && !getSubscriptionNotify.isExpired
    "
  />
  <SubcriptionCardPlusPlan v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import SubcriptionCardFreePlan from './Plan/SubcriptionCardFreePlan';
import SubcriptionCardFreeTrial from './Plan/SubcriptionCardFreeTrial';
import SubcriptionCardPlusPlan from './Plan/SubcriptionCardPlusPlan';

export default {
  name: 'SubscriptionCardPlan',
  components: {
    SubcriptionCardPlusPlan,
    SubcriptionCardFreeTrial,
    SubcriptionCardFreePlan,
  },
  computed: {
    ...mapGetters({
      isSubscriptionActive: 'subscription/isSubscriptionActive',
      getSubscriptionNextBilling: 'subscription/getSubscriptionNextBilling',
      getSubscriptionQuota: 'subscription/getSubscriptionQuota',
      getSubscriptionInfoPlan: 'subscription/getSubscriptionInfoPlan',
      getCurrentSubscription: 'subscription/getCurrentSubscription',
      getSubscriptionNotify: 'subscription/getSubscriptionNotify',
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    handleClickCard() {
      this.$router.push(
        { name: 'billing_credits_plan' },
      );
    },
  },
};
</script>
