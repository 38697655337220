<template>
  <div class="message-metadata--instagram">
    <span v-if="isOutgoing && withLastSeen" :class="alignBubble">{{
      $t('INBOX_MGMT.ADD.INSTAGRAM.SEEN')
    }}</span>
    <img
      v-if="data.content_attributes && data.content_attributes.reaction"
      :class="withLastSeen && 'with-last-seen'"
      class="message-metadata--instagram__reaction"
      src="~dashboard/assets/icons/ic_love.svg"
    />
    <reaction v-if="isIncoming && isHoveredAction" :id="data.id" :data="data" />
  </div>
</template>

<script>
import { MESSAGE_TYPE } from 'shared/constants/messages';
import Reaction from './actions/Reaction';

export default {
  components: {
    Reaction,
  },
  props: {
    sender: {
      type: Object,
      default: () => ({}),
    },
    additionalAttributes: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    isHoveredAction: {
      type: Boolean,
      default: false,
    },
    messageType: {
      type: Number,
      default: 1,
    },
    sourceId: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    isIncoming() {
      return MESSAGE_TYPE.INCOMING === this.messageType;
    },
    isOutgoing() {
      return MESSAGE_TYPE.OUTGOING === this.messageType;
    },
    alignBubble() {
      return !this.messageType ? 'left' : 'right';
    },
    withLastSeen() {
      return (
        this.additionalAttributes.last_seen_message_id_by_contact ===
        this.sourceId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.message-metadata {
  align-items: flex-end;
  display: flex;

  &--instagram {
    &__reaction {
      margin-top: 5px;
      margin-bottom: 8px;

      &.with-last-seen {
        margin-top: -18px;
      }
    }

    & > span {
      display: block;
      color: var(--grey-07);
      margin: 4px 0;

      &.left {
        text-align: right;
      }
      &.right {
        text-align: left;
      }
    }
  }
}
</style>
