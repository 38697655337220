<template>
  <div class="small-9 whatsapp-wrapper">
    <div class="whatsapp-wrapper__title">
      <channel-header
        :title="$t('NEW_CHANNEL.WHATSAPP.TITLE')"
        icon="channel-whatsapp.svg"
      />
      <div class="whatsapp-wrapper__content">
        <wrapper
          :title="
            !inbox_name
              ? $t('NEW_CHANNEL.WHATSAPP.CREATE_INBOX')
              : $t('NEW_CHANNEL.WHATSAPP.API_CREDENTIAL')
          "
          :description="$t('NEW_CHANNEL.WHATSAPP.DESCRIPTION')"
        >
          <div class="row align-center">
            <div class="small-12 medium-12 column">
              <whatsapp-form v-if="inbox_name" :inbox-name="inbox_name" />
              <inbox-name-form v-else @on-change-inbox="onChangeInboxName" />
            </div>
          </div>
        </wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import ChannelHeader from '../../components/Header';
import Wrapper from './components/Wrapper';
import WhatsappForm from './components/WhatsappForm';
import InboxNameForm from './components/InboxNameForm';

export default {
  components: {
    ChannelHeader,
    Wrapper,
    WhatsappForm,
    InboxNameForm,
  },
  data() {
    return {
      inbox_name: '',
    };
  },
  methods: {
    onChangeInboxName({ inbox_name }) {
      this.$data.inbox_name = inbox_name;
    },
  },
};
</script>

<style lang="scss" scoped>
.whatsapp {
  &-wrapper {
    background: var(--white);
    padding: var(--space-medium);
    border: 1px solid var(--grey-03);
    position: relative;
    &__content {
      padding: 0 20px;
    }
  }
}
</style>
