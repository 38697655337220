<template>
  <div
    v-if="orders.length > 0 && orderSummary"
    class="plan-container with-wizard-footer"
  >
    <BannerOrderStatus
      :status="orderSummary.status"
      :due-date="orderSummary.due_date"
      :invoice-url="orderSummary.invoice_url"
    />
    <div class="row header">
      <h3>Review order</h3>
    </div>
    <div class="orders">
      <OrderDetail
        :orders="orders"
        :tax="orderSummary.orderable.tax"
        :tax-rate="orderSummary.orderable.tax_rate"
        :grand-total="orderSummary.orderable.total_amount"
      />
    </div>
    <div class="billing-contact">
      <BillingContact
        :billing-data="orderSummary.billing_info"
        :editable="false"
      />
    </div>
    <div>
      <PaymentMethodDetail
        :bank="orderSummary.payment_method"
        :paid-at="orderSummary.paid_at"
        :status="orderSummary.status"
        :total-amount="orderSummary.total_amount"
        :va-number="orderSummary.va_number"
      />
    </div>
  </div>
</template>

<script>
import OrderDetail from './OrderDetail';
import BannerOrderStatus from './BannerOrderStatus';
import PaymentMethodDetail from '../payment-method/PaymentMethodDetail';
import BillingContact from '../billing-contact/BillingContact';

export default {
  name: 'OrderReview',
  components: {
    OrderDetail,
    PaymentMethodDetail,
    BillingContact,
    BannerOrderStatus,
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
      required: true,
    },
    orderSummary: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-container {
  padding: 48px;

  .header {
    h3 {
      margin-bottom: 36px;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .orders,
  .billing-contact {
    margin-bottom: 24px;
  }
}
</style>
