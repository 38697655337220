<template>
  <div class="form-header">
    <h3 class="form-header__title">
      {{ renderTitle }}
    </h3>
    <div v-if="accessToken" class="form-header__status">
      <img
        v-if="computedStep === 1"
        src="~dashboard/assets/icons/ic_info.svg"
      />
      <img
        v-if="computedStep === 2"
        src="~dashboard/assets/icons/ic_info_active.svg"
      />
      <img
        v-if="computedStep === 3"
        src="~dashboard/assets/icons/ic_success.svg"
      />
      <span>{{ renderStep }}</span> {{ renderStatusStep }}
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/require-prop-types */
export default {
  props: {
    formType: {
      type: String,
      default: '',
      required: true,
    },
    accessToken: {
      type: String,
      default: '',
    },
    status: {
      default: null,
    },
    webhookUrl: {
      default: '',
    },
  },
  computed: {
    computedStep() {
      if (this.webhookUrl === null || this.webhookUrl === '') {
        return 1;
      }
      if (!this.status) {
        return 2;
      }
      if (this.status) {
        return 3;
      }
      return 0;
    },
    renderStep() {
      return `Step ${this.computedStep} of 3`;
    },
    renderStatusStep() {
      switch (this.computedStep) {
        case 1:
          return this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.STATUS.WAITING');
        case 2:
          return this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.STATUS.READY');
        case 3:
          return this.$t('NEW_INTEGRATION_APPS.AGENT_BOT.STATUS.ACTIVE');
        default:
          return '';
      }
    },
    renderTitle() {
      return `${this.formType} configuration`;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-header {
  margin-bottom: 24px;
  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin: 8px 0px;
    color: var(--grey-09);
    text-transform: capitalize;
  }
  &__status {
    font-size: 11px;
    line-height: 17px;
    color: var(--greymed-04);
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
    span {
      display: inline-flex;
      margin-right: 8px;
      font-weight: bold;
    }
  }
}
</style>
