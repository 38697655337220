<template>
  <div class="row">
    <div class="main-content">
      <h3 class="title">
        Billing contact
      </h3>
      <div class="contact">
        <p class="name">
          {{ data.first_name + ' ' + data.last_name }}
        </p>
        <p>
          {{ data.email }}; additionals:
          {{ data.additional_emails && data.additional_emails.toString() }}
        </p>
        <p>{{ data.company_name }}</p>
        <p>
          {{ data.address }}
        </p>
        <p>NPWP Number: {{ data.npwp }}</p>
      </div>
    </div>

    <div v-if="editable" class="medium-12">
      <woot-button variant="link" @click="openModal()">
        Edit
      </woot-button>
    </div>
  </div>
</template>

<script>
import WootButton from '../../../../components/ui/WootButton';

export default {
  name: 'BillingContactFilled',
  components: {
    WootButton,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    openModal() {
      bus.$emit('openModalBillingInfo');
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  h3 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  margin-bottom: 8px;
}

.contact {
  margin-bottom: 12px;
  color: #6a7a8a;

  .name {
    font-weight: 700;
  }

  p {
    margin-bottom: 4px;
  }
}
</style>
