<template>
  <div v-if="bank" class="payment-detail-card row flex-dir-column">
    <div class="row justify-space-between align-middle">
      <div class="details">
        <div class="name">Transfer to {{ bank.alias }} Virtual Account</div>
        <div class="description">
          Transfer {{ totalAmount | toCurrency }} to the following bank
          information:
        </div>
      </div>
      <div>
        <img class="badge-plan" :src="bank.imagePath" alt="bank" />
      </div>
    </div>
    <div class="row info justify-space-between align-middle">
      <div class="name">
        Account number
      </div>
      <div class="value">
        {{ vaNumber | toBankAccount }}
        <button class="copy-btn" @click="handleCopy(vaNumber)">
          <span>
            <img
              class="badge-plan"
              src="~dashboard/assets/images/copy.svg"
              alt="copy"
            />
          </span>
        </button>
      </div>
    </div>
    <div class="row info justify-space-between align-middle">
      <div class="name">
        Beneficiary
      </div>
      <div class="value">
        {{ beneficiary }}
        <button class="copy-btn" @click="handleCopy(beneficiary)">
          <span>
            <img
              class="badge-plan"
              src="~dashboard/assets/images/copy.svg"
              alt="copy"
            />
          </span>
        </button>
      </div>
    </div>
    <div class="row banner-container">
      <Banner variant="default">
        <div class="banner-content row align-middle">
          <span class="icon ion-information-circled" />
          <p>
            Your payment will be verified automatically. Please transfer from a
            {{ bank.alias }} account as transfers from other banks will be
            rejected. Use Use mobile or internet banking for best results.
            <a
              href="https://see-spaces.sgp1.digitaloceanspaces.com/omnichat/How-to-transfer-xendit.pdf"
              target="_blank"
            >How to transfer?</a>
          </p>
        </div>
      </Banner>
    </div>
  </div>
</template>

<script>
import Banner from 'dashboard/components/widgets/Banner';

export default {
  name: 'BankPaymentDetailPending',
  components: {
    Banner,
  },
  props: {
    bank: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    totalAmount: {
      type: Number,
      default: 0,
    },
    vaNumber: {
      type: String,
      default: '3426448872689096',
    },
    beneficiary: {
      type: String,
      default: 'PT YesBoss Group Indonesia',
    },
  },
  methods: {
    handleCopy(textToCopy) {
      const TempText = document.createElement('input');
      TempText.value = textToCopy;
      document.body.appendChild(TempText);
      TempText.select();

      document.execCommand('copy');
      document.body.removeChild(TempText);
      bus.$emit('newToastMessage', `Copied text: ${textToCopy}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-detail-card {
  border: 1px solid #e5eaef;
  border-radius: 8px;
  padding: 32px;
  font-size: 12px;

  .details {
    font-size: 14px;
    margin-bottom: 24px;

    .name {
      font-weight: 700;
      margin-bottom: 4px;
    }
  }

  .info {
    font-weight: 700;
    margin-bottom: 8px;

    .value {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .copy-btn {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .banner-container {
    margin-top: 16px;

    .banner {
      flex: 1;
    }

    .banner-content {
      gap: 18px;

      p {
        margin: 0;
        flex: 1;
      }

      .icon {
        font-size: 20px;
        color: #6a7a8a;
      }
    }
  }
}
</style>
