<template>
  <div class="mau-list">
    <div v-for="item in mauList" :key="item">
      <input
        :id="`mau-${item}}`"
        type="radio"
        name="mau"
        :value="item"
        :checked="mau === item && !isCustom"
        @change="handleChangeMau"
      />
      <label :for="`mau-${item}}`">{{ item | toNumber }}</label>
    </div>
    <div>
      <input
        id="mau-custom"
        type="radio"
        name="mau"
        value="custom"
        @change="handleChangeMau"
      />
      <label for="mau-custom">Custom</label>
    </div>
    <div v-if="isCustom">
      <div class="input-group">
        <span class="input-group-label">MAU</span>
        <input
          v-model.number="mau"
          type="number"
          class="input-group-field"
          :class="{
            error:
              !$v.mau.mustBeMultiple || !$v.mau.requiredZero || !$v.mau.required,
          }"
          @input="handleChangeMauCustom"
        />
      </div>

      <div
        class="mau-info"
        :class="{
          error:
            !$v.mau.mustBeMultiple || !$v.mau.requiredZero || !$v.mau.required,
        }"
      >
        <span class="icon ion-information-circled" />
        <p>Must be in multiples of {{ multipleMau }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mauList } from '../../../../helper/mauHelper';
import { required } from 'vuelidate/lib/validators';

const multipleMau = 500;
const mustBeMultiple = value => value % multipleMau === 0;
const requiredZero = value => parseInt(value, 10) !== 0;

export default {
  name: 'MauFormList',
  components: {},
  data() {
    return {
      mauList,
      isCustom: false,
      multipleMau,
      pricePerMau: 500,
      mau: 500,
    };
  },
  validations: {
    mau: {
      required,
      mustBeMultiple,
      requiredZero,
    },
    form: ['mau'],
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$emit('change', this.$data);
    },
    validate() {
      const isValid = !this.$v.form.$invalid;
      this.$v.form.$touch();
      return isValid;
    },
    handleChangeMau(e) {
      const {
        target: { value },
      } = e;
      if (value === 'custom') {
        this.isCustom = true;
        this.mau = 500000;
      } else {
        this.isCustom = false;
        this.mau = Number(value);
      }
      this.validate();
      this.$emit('change', this.$data);
    },
    handleChangeMauCustom(e) {
      const {
        target: { value },
      } = e;
      this.isValid = value % this.multipleMau === 0;
      this.validate();
      this.$emit('change', this.$data);
    },
  },
};
</script>

<style lang="scss" scoped>
.mau-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
  grid-gap: 24px;

  .mau-info {
    display: flex;
    font-size: 12px;
    color: #6e4ee4;
    gap: 10px;
    align-items: center;

    &.error {
      color: #ff6150;
    }

    .icon {
      font-size: 20px;
    }

    p {
      flex: 1;
      margin: 0;
    }
  }

  .input-group {
    margin: 0;

    .input-group-label {
      background: none;
    }

    input {
      border-radius: 0 0.5rem 0.5rem 0 !important;

      &.error {
        border-color: #ff6150;
      }
    }
  }
}
</style>
