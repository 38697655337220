<template>
  <div class="getting-started__wrapper">
    <div class="banner-wrapper">
      <confirmation v-if="isConfirmed !== undefined && !isConfirmed" />
      <banner-trial-warning
        v-if="
          getSubscriptionNotify.isTrial && getSubscriptionNotify.isInDueDays
        "
      />
      <banner-mau-warning
        v-if="
          getCurrentSubscription.plan !== 'Trial' &&
            getSubscriptionNotify.isMauLimit
        "
      />
      <banner-plan-warning
        v-if="
          !getSubscriptionNotify.isTrial &&
            (getSubscriptionNotify.isExpired ||
              getSubscriptionNotify.isInDueDays)
        "
      />
    </div>
    <div class="getting-started__box welcome">
      <div class="welcome-item">
        <h1>{{ $t('GETTING_STARTED.WELCOME.TITLE') }}</h1>
        <p>{{ $t('GETTING_STARTED.WELCOME.DESCRIPTION') }}</p>
      </div>
      <img
        src="~dashboard/assets/images/getting-started/getting-started-welcome-new.svg"
      />
    </div>
    <div class="getting-started__channel">
      <div class="getting-started__box">
        <img src="~dashboard/assets/images/kata/channel-instagram.svg" />
        <h2>{{ $t('GETTING_STARTED.CHANNEL.INSTAGRAM.TITLE') }}</h2>
        <p>{{ $t('GETTING_STARTED.CHANNEL.INSTAGRAM.DESCRIPTION') }}</p>
        <kata-button
          v-if="isAdmin"
          variant="outline"
          :disabled="!isConfirmed"
          @click="
            onClickConnect('INSTAGRAM');
            $customGTM(
              'Event',
              'Getting Started - Connect Instagram',
              'Button',
              'Connect-instagram',
            );
          "
        >
          {{ $t('GETTING_STARTED.ACTION.CONNECT') }}
        </kata-button>
      </div>
      <div class="getting-started__box">
        <img src="~dashboard/assets/images/kata/channel-whatsapp.svg" />
        <h2>{{ $t('GETTING_STARTED.CHANNEL.WHATSAPP.TITLE') }}</h2>
        <p>{{ $t('GETTING_STARTED.CHANNEL.WHATSAPP.DESCRIPTION') }}</p>
        <div class="channel-whatsapp">
          <kata-button
            v-if="isAdmin"
            variant="outline"
            :disabled="!isConfirmed"
            @click="onClickConnect('WHATSAPP')"
          >
            {{ $t('GETTING_STARTED.ACTION.CONNECT') }}
          </kata-button>
          <span
            @click="
              $customGTM(
                'Event',
                'Getting Started - WABA Registration',
                'Link Click',
                'Register Now',
              )
            "
            v-html="$t('GETTING_STARTED.CHANNEL.WHATSAPP.DONT_HAVE_ACCOUNT')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import accountMixin from 'dashboard/mixins/account';
import adminMixin from 'dashboard/mixins/isAdmin';
import KataButton from 'dashboard/components/buttons/Button';
import Confirmation from './Confirmation';
import BannerTrialWarning from '../BannerTrialWarning.vue';
import BannerMauWarning from '../BannerMauWarning.vue';
import BannerPlanWarning from '../BannerPlanWarning.vue';

export default {
  components: {
    KataButton,
    Confirmation,
    BannerTrialWarning,
    BannerMauWarning,
    BannerPlanWarning,
  },
  mixins: [accountMixin, adminMixin],
  data() {
    return {
      trialStatus: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      getSubscriptionNotify: 'subscription/getSubscriptionNotify',
      getCurrentSubscription: 'subscription/getCurrentSubscription',
    }),
    isConfirmed() {
      return this.currentUser.confirmed;
    },
    isAdmin() {
      return this.currentUserRole === 'administrator';
    },
  },
  mounted() {
    this.$customGTM('Homepage', 'Getting Started - Homepage', null, null);
    this.$store.dispatch('subscription/get');
  },
  methods: {
    onClickConnect(channel) {
      switch (channel) {
        case 'INSTAGRAM':
          return this.$router.push(
            `/app/accounts/${this.accountId}/settings/inboxes/new/instagram`,
          );
        case 'WHATSAPP':
          return this.$router.push(
            `/app/accounts/${this.accountId}/settings/inboxes/new/whatsapp`,
          );
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin heading($fontSize, $lineHeight) {
  color: var(--grey-08);
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: bold;
}
.banner-wrapper {
  margin-top: -40px;
}

.getting {
  &-started {
    &__wrapper {
      padding: 80px 40px;
      flex: 1;
      overflow-y: auto;
      h1 {
        @include heading(35px, 40px);
        margin-bottom: 20px;
      }
      h2 {
        @include heading(29px, 32px);
        margin-bottom: 8px;
      }
      p {
        color: var(--grey-07);
        font-size: 16px;
        line-height: 24px;
      }
      .welcome {
        position: relative;
        padding: 0;
        display: flex;
        align-items: center;
        &-item {
          flex: 1;
          padding: 48px 40px;
        }
        img {
          flex: 1;
        }
      }
    }
    &__box {
      padding: 48px 40px;
      border: 1px solid var(--grey-04);
      border-radius: 8px;
      background: var(--white);
    }
    &__channel {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      img {
        margin-bottom: 24px;
        width: 64px;
        height: 64px;
      }
      button {
        margin-top: 30px;
      }
      .channel {
        &-whatsapp {
          display: flex;
          align-items: center;
          margin-top: 40px;
          button {
            margin: 0;
          }
          span {
            color: var(--grey-07);
            font-size: 14px;
            line-height: 24px;
            display: block;
            flex: 1;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
