<template>
  <div>
    <billing-contact :billing-data="billingInfo" />
    <woot-modal
      :show.sync="showBillingContact"
      :on-close="hideBillingContact"
      :close-on-backdrop-click="false"
    >
      <add-billing-info :billing-data="billingInfo" />
    </woot-modal>
  </div>
</template>

<script>
import AddBillingInfo from '../../../../routes/dashboard/settings/billing-credits/components/AddBillingInfo';
import BillingContact from '../billing-contact/BillingContact';
import { mapGetters } from 'vuex';

export default {
  name: 'BillingContactPreference',
  components: { AddBillingInfo, BillingContact },
  data() {
    return {
      showBillingContact: false,
    };
  },
  computed: {
    ...mapGetters({
      billingInfo: 'subscription/getBillingInfo',
    }),
  },
  mounted() {
    this.initBilling();
    bus.$on('openModalBillingInfo', () => {
      this.showBilingContact();
    });
    bus.$on('closeModalBillingInfo', () => {
      this.hideBillingContact();
    });
  },
  methods: {
    showBilingContact() {
      this.showBillingContact = true;
    },
    hideBillingContact() {
      this.showBillingContact = false;
    },
    async initBilling() {
      await this.$store.dispatch('subscription/getBillingInfo');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  padding: 24px;
}
.modal-split {
  display: flex;
  margin-right: 8px;
}
.billing-title {
  margin-bottom: 42px;
}
</style>
