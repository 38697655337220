<template>
  <div class="quickreply">
    <woot-modal :show.sync="showAdd" :on-close="hideAddModal">
      <modal :show.sync="show" :on-close="hideAddModal">
        <quick-reply-form
          :submit-form="addQuickReply"
          :loading="loading"
          type="add"
        />
      </modal>
    </woot-modal>
    <div class="quickreply__header">
      <div class="search-wrap">
        <i class="ion-ios-search-strong search-icon" />
        <input
          type="text"
          class="contact-search"
          @input="onInputSearch"
          @keyup.enter="submitSearch"
        />
        <woot-submit-button
          :button-text="$t('CONTACTS_PAGE.SEARCH_BUTTON')"
          :loading="false"
          class="submit-button"
          :button-class="clearButtonClass"
          @click="submitSearch"
        />
      </div>

      <div class="quickreply__submit">
        <button
          class="button button-delete"
          :disabled="quickReply.length <= 0"
          @click="openDeletePopup"
        >
          <span class="quickreply__submit-content">
            {{ $t('QUICK_REPLY.FORM.DELETE_ALL.LABEL') }}
          </span>
        </button>
        <button class="button" @click="openAddModal">
          <span class="quickreply__submit-content">
            <img src="~dashboard/assets/icons/ic_plus.svg" alt="" />
            {{ $t('QUICK_REPLY.TITLE') }}
          </span>
        </button>
      </div>
    </div>
    <quick-reply-table
      v-if="dataQuickReply.length"
      :inbox-id="inboxId"
      :quick-reply="dataQuickReply"
    />
    <div v-else class="quickreply__empty">
      <img src="~dashboard/assets/icons/ic_cloud.svg" alt="" />
      <span>{{ $t('QUICK_REPLY.EMPTY') }}</span>
    </div>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('QUICK_REPLY.FORM.DELETE_ALL.CONFIRM_TITLE')"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import QuickReplyTable from './QuickReplyTable.vue';
import Modal from '../../../../../../components/Modal.vue';
import QuickReplyForm from './QuickReplyForm.vue';

export default {
  components: { QuickReplyTable, Modal, QuickReplyForm },
  mixins: [alertMixin],
  props: {
    inboxId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: true,
      loading: false,
      showAdd: false,
      showDeleteConfirmationPopup: false,
      searchQuery: '',
      dataQuickReply: [],
    };
  },

  computed: {
    ...mapGetters({
      quickReply: 'inboxes/getQuickReply',
    }),
    deleteConfirmText() {
      return `${this.$t('QUICK_REPLY.FORM.DELETE_ALL.CONFIRM_YES')}`;
    },
    deleteRejectText() {
      return `${this.$t('QUICK_REPLY.FORM.DELETE_ALL.CONFIRM_NO')}`;
    },
    deleteMessage() {
      return `${this.$t('QUICK_REPLY.FORM.DELETE_ALL.CONFIRM_MESSAGE')} ?`;
    },
    clearButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
  },
  watch: {
    quickReply() {
      this.searchQuery = '';
      this.dataQuickReply = this.quickReply;
    },
  },

  mounted() {
    this.$store.dispatch('inboxes/getQuickReplyAction', this.inboxId);
  },
  methods: {
    openAddModal() {
      this.showAdd = true;
    },
    hideAddModal() {
      this.showAdd = false;
    },
    openDeletePopup() {
      this.showDeleteConfirmationPopup = true;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteAllQuickReplies();
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    deleteAllQuickReplies() {
      this.$store
        .dispatch('inboxes/deleteAllQuickReplyAction', this.inboxId)
        .then(() => {
          this.showAlert(
            this.$t('QUICK_REPLY.FORM.DELETE_ALL.SUCCESS_MESSAGE'),
          );
        })
        .catch(() => {
          this.showAlert(this.$t('QUICK_REPLY.FORM.DELETE_ALL.ERROR_MESSAGE'));
        });
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      this.searchQuery = newQuery;
    },
    submitSearch() {
      if (this.searchQuery) {
        const data = [...this.quickReply];
        // eslint-disable-next-line prettier/prettier
        this.dataQuickReply = data.filter(item =>
          item.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
        );
      } else {
        this.dataQuickReply = this.quickReply;
      }
    },
    addQuickReply(name, contents) {
      const data = {
        inboxId: this.inboxId,
        name,
        contents,
      };

      this.loading = true;
      this.$store
        .dispatch('inboxes/addQuickReplyAction', data)
        .then(() => {
          this.loading = false;
          this.showAlert(this.$t('QUICK_REPLY.FORM.ADD.SUCCESS_MESSAGE'));
          this.hideAddModal();
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(this.$t('QUICK_REPLY.FORM.ADD.ERROR_MESSAGE'));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-delete {
  background: white;
  color: red;
  border: 1px solid red;
  margin-right: 10px;
}
.quickreply {
  padding: 24px;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__submit {
    display: flex;
    &-content {
      display: flex;

      img {
        margin-right: 10px;
      }
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;

    span {
      margin-top: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.search-wrap {
  width: 400px;
  height: 3.8rem;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: var(--space-small);

  .search-icon {
    position: absolute;
    top: 1px;
    left: var(--space-one);
    height: 3.8rem;
    line-height: 3.6rem;
    font-size: var(--font-size-medium);
    color: var(--b-700);
  }
  .contact-search {
    margin: 0;
    height: 3.8rem;
    width: 100%;
    padding-left: var(--space-large);
    padding-right: 6rem;
    border-color: var(--s-100);
  }
}

.quickreply-table {
  margin-top: -1px;

  > thead {
    border-bottom: 1px solid var(--color-border);
    background: white;

    > th:first-child {
      padding-left: var(--space-medium);
      width: 30%;
    }
  }

  > tbody {
    > tr {
      cursor: pointer;

      &:hover {
        background: var(--b-50);
      }

      &.is-active {
        background: var(--b-100);
      }

      > td {
        padding: var(--space-slab);

        &:first-child {
          padding-left: var(--space-medium);
        }

        &.conversation-count-item {
          padding-left: var(--space-medium);
        }
      }
    }
  }
  .row-main-info {
    display: flex;
    align-items: center;

    .user-thumbnail-box {
      margin-right: var(--space-small);
    }

    .user-name {
      font-size: var(--font-size-small);
      margin: 0;
      text-transform: capitalize;
    }

    .user-email {
      margin: 0;
    }
  }
}

.submit-button {
  margin-left: var(--space-small);
  height: 3.2rem;
  right: var(--space-smaller);
  position: absolute;
  padding: 0 var(--space-small);
  transition: transform 100ms linear;
  opacity: 0;
  transform: translateX(-1px);
  visibility: hidden;
}

.submit-button.show {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}
</style>
