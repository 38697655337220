/* eslint no-console: 0 */
/* global axios */
/* eslint no-undef: "error" */

import endPoints from './endPoints';

export default {
  sendEmailConfirmation() {
    const urlData = endPoints('sendEmailConfirmation');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .post(urlData.url)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
};
