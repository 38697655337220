<template>
  <div v-if="attachments">
    <bubble-instagram-video v-if="attachments === 'video'" :geturl="geturl" />
    <bubble-instagram-image v-else :geturl="geturl" />
  </div>
  <div v-else>
    <span>
      {{ $t('CONVERSATION.STORY.DELETED') }}
    </span>
  </div>
</template>

<script>
import BubbleInstagramImage from './Image';
import BubbleInstagramVideo from './Video';

export default {
  components: {
    BubbleInstagramImage,
    BubbleInstagramVideo,
  },
  props: {
    geturl: {
      type: Promise,
      required: true,
    },
    attachments: {
      type: [String, Boolean],
      required: true,
    },
  },
};
</script>
