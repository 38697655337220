<template>
  <label>
    <span v-if="label" class="input-label">{{ label }}</span>
    <div class="input-container">
      <!-- <masked-input
        :mask="mask"
        :value="value"
        :type="typeComputed"
        :placeholder="placeholder"
        :class="inputClassName"
        :disabled="disabled"
        @input="onChange"
        @blur="onBlur"
        @keypress="onKeypress"
      /> -->
    </div>
    <p v-if="helpText" class="help-text"></p>
    <span v-if="error" class="message">
      {{ error }}
    </span>
  </label>
</template>

<script>
// import MaskedInput from 'vue-masked-input';
export default {
  name: 'InputMask',
  // components: {
  //   MaskedInput,
  // },
  props: {
    mask: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputClassName() {
      let className = 'input-container__input';
      if (this.icon) {
        return `${className} with-icon`;
      }
      return className;
    },
    typeComputed() {
      if (this.type === 'password') {
        if (this.showPassword) {
          return 'text';
        }
        return this.type;
      }
      return this.type;
    },
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value);
    },
    onBlur(e) {
      this.$emit('blur', e.target.value);
    },
    onKeypress(e) {
      this.$emit('keypress', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  &-container {
    position: relative;
    &__input {
      &.with-icon {
        padding-left: var(--space-larger);
      }
    }
  }
  &-label {
    margin-bottom: 8px;
    display: block;
  }
}
</style>
