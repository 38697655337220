<template>
  <label>
    <span v-if="label" class="input-label">{{ label }}</span>
    <div class="input-container">
      <div v-if="icon" class="input-container__icon">
        <img :src="require(`../../../assets/icons/${icon}`)" />
      </div>
      <input
        :value="value"
        :type="typeComputed"
        :placeholder="placeholder"
        :class="inputClassName"
        :disabled="disabled"
        @input="onChange"
        @blur="onBlur"
        @keypress="onKeypress"
      />
      <div
        v-if="type === 'password'"
        class="input-container__show"
        @click="showPassword = !showPassword"
      >
        <img
          v-if="showPassword"
          src="~dashboard/assets/icons/ic_eye_show.svg"
        />
        <img v-else src="~dashboard/assets/icons/ic_eye_hide.svg" />
      </div>
    </div>
    <p v-if="helpText" class="help-text"></p>
    <span v-if="error" class="message">
      {{ error }}
    </span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    inputClassName() {
      let className = 'input-container__input';
      if (this.icon) {
        return `${className} with-icon`;
      }
      return className;
    },
    typeComputed() {
      if (this.type === 'password') {
        if (this.showPassword) {
          return 'text';
        }
        return this.type;
      }
      return this.type;
    },
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value);
    },
    onBlur(e) {
      this.$emit('blur', e.target.value);
    },
    onKeypress(e) {
      this.$emit('keypress', e);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin iconPosition {
  position: absolute;
  top: 0;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input {
  &-container {
    position: relative;
    &__icon {
      @include iconPosition;
      left: 0;
    }
    &__show {
      @include iconPosition;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
    }
    &__input {
      &.with-icon {
        padding-left: var(--space-larger);
      }
    }
  }
  &-label {
    margin-bottom: 8px;
    display: block;
  }
}
</style>
