var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"instagram-final-setup__wrapper"},[_c('div',{staticClass:"instagram-final-setup__wrapper-content"},[_c('p',[_vm._v(_vm._s(_vm.$t('NEW_CHANNEL.FINAL_STEP.DESCRIPTION')))]),_vm._v(" "),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('NEW_CHANNEL.FINAL_STEP.FACEBOOK_PAGES.SELECT')))]),_vm._v(" "),_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();return _vm.finishSetup()}}},[_c('div',{staticClass:"small-12 form-group"},[_c('div',{staticClass:"row"},[(!_vm.isFetchingPages)?_c('div',{staticClass:"small-12 form-group"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12 align-self-top dropdown",class:_vm.$v.form.instagram_id.$error && 'error'},[_c('dropdown',{attrs:{"options":_vm.getPagesOptions,"placeholder":_vm.$t('NEW_CHANNEL.FINAL_STEP.FACEBOOK_PAGES.CHOOSE')},on:{"click":_vm.onSelectDropdown}}),_vm._v(" "),(_vm.$v.form.instagram_id.$error)?_c('span',{staticClass:"message"},[_vm._v("\n                  "+_vm._s(_vm.$t('NEW_CHANNEL.FINAL_STEP.FACEBOOK_PAGES.ERROR'))+"\n                ")]):_vm._e()],1)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"small-12 form-group"},[_c('woot-input',{class:{ error: _vm.$v.form.inbox_name.$error },attrs:{"label":_vm.$t('NEW_CHANNEL.FINAL_STEP.INBOX_NAME.LABEL'),"type":"text","placeholder":_vm.$t('NEW_CHANNEL.FINAL_STEP.INBOX_NAME.LABEL'),"error":_vm.$v.form.inbox_name.$error
                  ? _vm.$t('NEW_CHANNEL.FINAL_STEP.INBOX_NAME.ERROR')
                  : ''},on:{"blur":function () {
                  _vm.$customGTM(
                    'Event',
                    "IGDM - Create Inbox - Inbox Details - Inbox Name",
                    'Text',
                    "Create Inbox - Inbox Details - Inbox Name"
                  );
                  _vm.$v.form.inbox_name.$touch;
                }},model:{value:(_vm.form.inbox_name),callback:function ($$v) {_vm.$set(_vm.form, "inbox_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.inbox_name"}})],1),_vm._v(" "),_c('div',{staticClass:"small-12 form-group"},[_c('button',{staticClass:"button nice full-width d-block",attrs:{"data-testid":"submit_button","type":"submit","disabled":_vm.$v.form.instagram_id.$invalid ||
                _vm.$v.form.inbox_name.$invalid ||
                _vm.isLoading}},[_vm._v("\n              "+_vm._s(_vm.$t('NEW_CHANNEL.FINAL_STEP.CREATE_INBOX'))+"\n            ")])])])])])]),_vm._v(" "),_c('img',{attrs:{"src":require("dashboard/assets/images/hero/instagram-second-step.png")}})])}
var staticRenderFns = []

export { render, staticRenderFns }