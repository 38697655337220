<template>
  <div class="banner-container">
    <Banner v-if="status === 'pending' && dueDate" variant="info">
      <div class="banner-content row align-middle">
        <span class="icon ion-android-time" />
        <p>
          Transfer the payment before:
          <span class="countdown">
            <Countdown :end-time="new Date(dueDate).getTime()" />
          </span>
        </p>
      </div>
    </Banner>
    <Banner v-if="status === 'cancelled'" variant="info">
      <div class="banner-content row align-middle">
        <span class="icon ion-information-circled" />
        <p>Your order has been cancelled</p>
      </div>
    </Banner>
    <Banner v-else-if="status === 'paid'" variant="success">
      <div class="banner-content row align-middle">
        <span class="icon ion-checkmark-circled" />
        <p>
          Payment successful.
          <a v-if="invoiceUrl" :href="invoiceUrl">Download invoice</a>
        </p>
      </div>
    </Banner>
    <Banner v-else-if="status === 'failed'" variant="alert">
      <div class="banner-content row align-middle">
        <span class="icon ion-information-circled" />
        <p>
          Your payment has failed because it is past the payment time window. Do
          you still want to continue with the subscription?
          <router-link
            :to="{
              name: 'billing_credits',
            }"
          >
            Retry
          </router-link>
        </p>
      </div>
    </Banner>
  </div>
</template>

<script>
import Banner from 'dashboard/components/widgets/Banner';
import Countdown from './Countdown';

export default {
  name: 'BannerOrderStatus',
  components: {
    Countdown,
    Banner,
  },
  props: {
    status: {
      required: true,
      type: String,
    },
    dueDate: {
      type: String,
      default: '',
    },
    invoiceUrl: {
      type: String,
      default: '#',
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-container {
  margin-bottom: 24px;

  .banner-content {
    gap: 18px;

    p {
      margin: 0;
      flex: 1;
    }
  }

  .icon {
    font-size: 20px;
  }

  .info {
    .icon {
      color: #967fec;
    }
  }

  .default {
    .icon {
      color: #6a7a8a;
    }
  }

  .alert {
    .icon {
      color: #ff6150;
    }
  }

  .success {
    .icon {
      color: #0ead69;
    }
  }

  .countdown {
    font-weight: 700;
  }
}
</style>
