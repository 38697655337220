<template>
  <div class="medium-12 column">
    <div class="row">
      <div class="small-12 align-self-top">
        <span class="onboarding-title">{{
          $t('NEW_ONBOARDING.TELL.BUSINESS')
        }}</span>
      </div>
    </div>
    <div v-if="industryItem && employeeItem" class="row">
      <div class="small-12 form-group">
        <woot-input
          v-model.trim="form.account_name"
          :label="$t('NEW_ONBOARDING.BUSINESS.NAME.LABEL')"
          type="text"
          :class="{ error: $v.form.account_name.$error }"
          :placeholder="$t('NEW_ONBOARDING.BUSINESS.NAME.PLACEHOLDER')"
          :error="
            $v.form.account_name.$error
              ? $t('NEW_ONBOARDING.BUSINESS.NAME.ERROR')
              : ''
          "
          @blur="$v.form.account_name.$touch"
        />
      </div>
      <div class="small-12 form-group">
        <label>{{ $t('NEW_ONBOARDING.BUSINESS.INDUSTRY.LABEL') }}</label>
        <div class="row">
          <div class="small-12">
            <div class="row">
              <dropdown
                :options="industries"
                :placeholder="
                  $t('NEW_ONBOARDING.BUSINESS.INDUSTRY.PLACEHOLDER')
                "
                @click="changeIndustry($event)"
              />
            </div>
            <div class="row">
              <div
                class="small-12 align-self-top"
                :class="$v.form.industry.onboarding_answer_id.$error && 'error'"
              >
                <span
                  v-if="$v.form.industry.onboarding_answer_id.$error"
                  class="message"
                >
                  {{ $t('NEW_ONBOARDING.BUSINESS.INDUSTRY.ERROR') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="otherIsSelected" class="row">
          <div class="small-12 align-self-top">
            <woot-input
              v-model.trim="form.industry.free_text"
              type="text"
              :class="{ error: $v.form.industry.free_text.$error }"
              :placeholder="$t('NEW_ONBOARDING.BUSINESS.OTHERS.PLACEHOLDER')"
              :error="
                $v.form.industry.free_text.$error
                  ? $t('NEW_ONBOARDING.BUSINESS.INDUSTRY.ERROR')
                  : ''
              "
              @blur="$v.form.industry.free_text.$touch"
            />
          </div>
        </div>
      </div>
      <div class="small-12 form-group">
        <label>{{ $t('NEW_ONBOARDING.BUSINESS.EMPLOYEE.LABEL') }}</label>
        <dropdown
          :options="employees"
          :placeholder="$t('NEW_ONBOARDING.BUSINESS.EMPLOYEE.PLACEHOLDER')"
          @click="selectEmployeeNumber($event)"
        />
        <div class="row">
          <div
            class="small-12 align-self-top"
            :class="{
              error: $v.form.employee_number.onboarding_answer_id.$error,
            }"
          >
            <span
              v-if="$v.form.employee_number.onboarding_answer_id.$error"
              class="message"
            >
              {{ $t('NEW_ONBOARDING.BUSINESS.EMPLOYEE.ERROR') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Dropdown from 'dashboard/components/kata/dropdown/Dropdown';

function requiredIfOthers(value) {
  return !this.$data.otherIsSelected || required(value);
}

export default {
  components: {
    Dropdown,
  },
  props: {
    industryItem: {
      type: Object,
      required: false,
      default: () => {},
    },
    employeeItem: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        account_name: '',
        industry: {
          free_text: '',
          onboarding_question_id: '',
          onboarding_answer_id: '',
          onboarding_answer_label: '',
        },
        employee_number: {
          onboarding_question_id: '',
          onboarding_answer_id: '',
          onboarding_answer_label: '',
        },
      },
      industry: 0,
      otherIsSelected: false,
      freeTextCategory: 'FREE_TEXT',
    };
  },
  computed: {
    employees() {
      return this.employeeItem.answers.map(({ answer, id }) => ({
        label: answer,
        value: id,
      }));
    },
    industries() {
      return this.industryItem.answers.map(({ answer, id }) => ({
        label: answer,
        value: id,
      }));
    },
  },
  validations: {
    form: {
      account_name: {
        required,
      },
      industry: {
        onboarding_question_id: {
          required,
        },
        onboarding_answer_id: {
          required,
        },
        free_text: {
          required: requiredIfOthers,
        },
      },
      employee_number: {
        onboarding_question_id: {
          required,
        },
        onboarding_answer_id: {
          required,
        },
      },
    },
  },
  methods: {
    validate() {
      const isValid = !this.$v.form.$invalid;
      this.$v.form.$touch();
      this.$emit('on-validate', { business: this.$data.form }, isValid);
      return isValid;
    },
    changeIndustry(industry) {
      const industry_item = this.industries.filter(
        indst => indst.value === industry,
      )[0];
      this.form.industry.onboarding_question_id = this.industryItem.id;
      this.form.industry.onboarding_answer_id = industry;
      this.form.industry.onboarding_answer_label = industry_item.label;
    },
    selectEmployeeNumber(employee) {
      const employee_item = this.employees.filter(
        empl => empl.value === employee,
      )[0];
      if (this.employeeItem && employee) {
        this.form.employee_number.onboarding_question_id = this.employeeItem.id;
        this.form.employee_number.onboarding_answer_id = employee;
        this.form.employee_number.onboarding_answer_label = employee_item.label;
      }
    },
    renderAnswer(answer) {
      if (answer === this.freeTextCategory) {
        return this.$t('NEW_ONBOARDING.BUSINESS.INDUSTRY.OPTIONS.OTHERS');
      }
      return answer;
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  margin-top: 10px !important;
}
.industry {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    flex: 50%;
  }
}
</style>
