<template>
  <div class="banner messenger-policy--banner">
    <span>
      {{ title }}
      <router-link
        v-if="showLink"
        :to="`/app/accounts/${accountId}/settings/billing`"
        class="link"
      >
        here
      </router-link>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    showLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
};
</script>

<style scoped lang="scss">
.link {
  line-height: inherit;
  cursor: pointer;
  color: var(--w-800) !important;
}
.banner {
  background: var(--b-500);
  color: var(--white);
  font-size: var(--font-size-mini);
  padding: var(--space-slab) var(--space-normal);
  text-align: center;
  position: relative;

  a {
    text-decoration: underline;
    color: var(--white);
    font-size: var(--font-size-mini);
  }

  &.messenger-policy--banner {
    background: var(--r-400);
  }

  .banner-close-button {
    cursor: pointer;
    margin-left: var(--space--two);
    color: var(--white);
  }
}
</style>
