import { frontendURL } from 'dashboard/helper/URLHelper';
import SettingsContent from '../Wrapper';
import BillingCredits from './BillingCredits';
import BillingPlan from './BillingPlan';
import BillingPlanRenewal from './BillingPlanRenewal';
import BillingMau from './BillingMau';
import BillingOrderDetail from './BillingOrderDetail';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/billing'),
      roles: ['administrator'],
      component: SettingsContent,
      props: {
        headerTitle: 'Billing & Credits',
        icon: 'ion-person-stalker',
        showNewButton: false,
        showBackButton: true,
        backUrl: '',
      },
      children: [
        {
          path: '',
          name: 'billing_credits',
          component: BillingCredits,
          roles: ['administrator'],
        },
        {
          path: 'order/:orderId',
          name: 'billing_credits_order_detail',
          component: BillingOrderDetail,
          roles: ['administrator'],
        },
        {
          path: 'plan',
          name: 'billing_credits_plan',
          component: BillingPlan,
          roles: ['administrator'],
        },
        {
          path: 'plan/renewal/:renewalPlanId',
          name: 'billing_credits_plan_renewal',
          component: BillingPlanRenewal,
          roles: ['administrator'],
        },
        {
          path: 'mau',
          name: 'billing_credits_mau',
          component: BillingMau,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
