<template>
  <div class="column box-content billing-plan">
    <div class="container">
      <woot-loading-state v-show="isFetchOrder" />
      <form-wizard v-show="!isFetchOrder" ref="wizard" title="" subtitle="">
        <template slot="step" scope="props" class="header-container">
          <Step :active="props.tab.active">
            {{ props.tab.title }}
          </Step>
        </template>

        <tab-content
          title="select payment method"
          :before-change="onSubmit"
          :lazy="true"
        >
          <OrderConfirmation
            v-if="activeIndex === 0"
            ref="step-order-confirmation"
            title="Renew subscription plan"
            :orders="form.orders"
            @on-validate="mergePartialData"
            @change="handleChangeOrderConfirmation"
          />
        </tab-content>

        <tab-content title="review order" :lazy="true">
          <OrderReview
            v-if="activeIndex === 1 && orderSummary"
            ref="step-order-review"
            :order-summary="orderSummary"
            :orders="form.orders"
          />
        </tab-content>

        <template slot="footer" slot-scope="state">
          <div class="wizard-footer">
            <div v-if="!state.isLastStep" class="row justify-space-between">
              <woot-button
                class-names="nice outline"
                color-scheme=""
                icon="ion-arrow-left-c"
                @click.native="$router.push({ name: 'billing_credits' })"
              >
                Back
              </woot-button>
              <woot-button
                :disabled="!isConfirmOrderValid"
                :is-loading="isLoadingSubmit"
                @click.native="state.nextTab()"
              >
                Confirm order
              </woot-button>
            </div>
            <div v-else>
              <div
                v-if="orderSummary.status === 'pending'"
                class="row align-right"
              >
                <woot-button
                  class-names="nice outline"
                  color-scheme=""
                  @click="handleCancelOrder(true)"
                >
                  Cancel order
                </woot-button>
                <woot-button @click="$router.go(0)">
                  Reload page
                </woot-button>
              </div>
            </div>
          </div>
        </template>
      </form-wizard>
    </div>

    <AlertModal
      :show="showModalCancelOrder"
      message="Cancel order? "
      reject-text="Don’t Cancel"
      confirm-text="Cancel"
      @on-confirm="handleConfirmCancelOrderModal"
      @on-close="handleCancelOrder(false)"
    />
  </div>
</template>

<script>
import OrderConfirmation from 'dashboard/modules/billing/components/orders/OrderConfirmation';
import OrderReview from 'dashboard/modules/billing/components/orders/OrderReview';
import Step from 'dashboard/modules/billing/components/Step';
import WootButton from 'dashboard/components/ui/WootButton';
import AlertModal from 'dashboard/components/widgets/modal/AlertModal';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { FormWizard, TabContent } from 'vue-form-wizard';
import PlansAPI from '../../../../api/billing/plans';
import TrialInfoAPI from 'dashboard/api/trialInfo';
import ordersMixin from '../../../../../shared/mixins/ordersMixin';

export default {
  name: 'BillingPlanRenewal',
  components: {
    OrderConfirmation,
    OrderReview,
    Step,
    FormWizard,
    TabContent,
    WootButton,
    AlertModal,
  },
  mixins: [ordersMixin],
  data() {
    return {
      form: {
        bank: '',
        orders: [],
        plan: {},
      },
      isConfirmOrderValid: false,
      orderSummary: {},
      isLoadingSubmit: false,
      showModalCancelOrder: false,
      activeIndex: 0,
      isFetchOrder: false,
    };
  },
  watch: {
    $route() {
      this.$refs.wizard.reset();
    },
    '$route.params.renewalPlanId': {
      handler: function(renewalPlanId) {
        this.fetchPlans(renewalPlanId);
        this.checkHasInvoice();
      },
      deep: true,
      immediate: true,
    },
  },
  activated() {
    this.init();
    this.checkHasInvoice();
  },
  methods: {
    init() {
      this.$watch(
        () => {
          return this.$refs.wizard.activeTabIndex;
        },
        index => {
          this.activeIndex = index;
          const withFooter = this.$el.querySelector('.with-wizard-footer');
          if (withFooter) withFooter.scrollTop = 0;
        },
      );
    },
    async fetchPlans(planId) {
      try {
        if (!planId) return;
        const request = await PlansAPI.get();
        let plans = request.data.data;
        if (!Array.isArray(plans) && plans.length === 0) {
          throw new Error('plans is empty');
        }
        const planDetail = plans.find(plan => plan.id === Number(planId));
        if (!planDetail) throw new Error('plan detail not found');
        const order = this.generateOrderPlan(
          planDetail.name,
          planDetail.mau_quota,
          planDetail.agent_quota,
          planDetail.price,
        );
        this.form.orders = [{ ...order }];
        this.form.plan = planDetail;
      } catch (e) {
        bus.$emit('newToastMessage', e.message);
        await this.$router.push({ name: 'billing_credits' });
      }
    },
    async checkHasInvoice() {
      try {
        this.isFetchOrder = true;
        const orderId = this.$route.query.orderId;
        if (!orderId) return;
        await this.fetchOrderReview(orderId);
        this.activeIndex = 1;
        this.$refs.wizard.changeTab(0, 1);
      } catch (e) {
        console.error(e);
      } finally {
        this.isFetchOrder = false;
      }
    },
    mergePartialData(model, isValid) {
      if (isValid) {
        this.form = { ...this.form, ...model };
      }
      this.isConfirmOrderValid = isValid;
    },
    handleChangeOrderConfirmation(bank) {
      this.form.bank = bank;
    },
    async handleCancelOrder(status) {
      this.showModalCancelOrder = status;
    },
    async handleConfirmCancelOrderModal() {
      try {
        const payload = {
          id: this.orderSummary.id,
        };
        await TrialInfoAPI.cancelOrderAccountPlanOrder(payload);
        await this.$router.push({ name: 'billing_credits' });
        this.showModalCancelOrder = false;
      } catch (e) {
        bus.$emit('newToastMessage', e.message);
      }
    },
    async onSubmit() {
      try {
        this.isLoadingSubmit = true;
        const isValid = this.$refs['step-order-confirmation'].validate();
        if (!isValid) throw new Error('step-order-confirmation not valid');
        const payload = {
          payment_method: this.form.bank,
          plan_id: this.form.plan.id,
        };
        const req = await TrialInfoAPI.upgradeAccountPlanOrder(payload);
        const orderId = req.data.plan_order.id;
        await this.fetchOrderReview(orderId);
        await this.$router.replace({
          query: { orderId },
        });
        this.$refs.wizard.changeTab(0, 1);
        return true;
      } catch (e) {
        bus.$emit('newToastMessage', e.message);
        return false;
      } finally {
        this.isLoadingSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box-content {
  overflow: auto;

  &.billing-plan {
    padding: 40px 64px;

    .container {
      overflow: hidden;
      background-color: var(--white);
      border: 1px solid #e5eaef;
      border-radius: 12px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
<style lang="scss">
.billing-plan {
  .vue-form-wizard {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;

    .wizard-navigation {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .wizard-header,
    .wizard-card-footer {
      padding: 0;
    }

    .wizard-nav {
      &.wizard-nav-pills {
        margin: 0;
        padding: 32px 48px;
        background-color: #f5f6f8;
        display: flex !important;
        gap: 4px;
      }
    }

    .wizard-tab-content {
      overflow: auto;
      padding: 0;
      flex: 1 1 auto;
      height: 0;
    }

    .wizard-card-footer {
      .wizard-footer {
        border-top: 1px solid #eff2f5;
        padding: 24px 48px;

        .row {
          gap: 10px;
        }
      }
    }
  }
}
</style>
