<!-- eslint-disable prettier/prettier -->
<template>
  <div class="invoices-wrapper">
    <woot-loading-state v-if="isLoading" />
    <template v-else>
      <div class="invoices-wrapper__list">
        <table class="invoices-list__table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Invoice Total</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="orders.data.length > 0">
            <tr v-for="(data, index) in orders.data" :key="index">
              <td>{{ data.date | moment('DD MMMM, YYYY') }}</td>
              <td>
                {{ data.description }}
                {{ renderDuration(data.type, data.duration) }}
              </td>
              <td>{{ data.total_amount | toCurrency }}</td>
              <td><pill :status="data.status" /></td>
              <td>
                <router-link
                  :to="`/app/accounts/${accountId}/settings/billing/order/${data.id}`"
                >
                  View details
                </router-link>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">
                <invoice-empty
                  title="No invoice to show"
                  content="Every invoice of your purchase will appear here."
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <agent-bot-list-pagination
        :current-page="orders.current_page"
        :page-size="orders.per_page"
        :total-count="orders.total_record"
        :on-page-change="handlePageChange"
        :with-limit="true"
        :limit="limit"
        :on-limit-page="handleLimitChange"
      />
    </template>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import OrdersAPI from 'dashboard/api/orders';
import InvoiceEmpty from './InvoiceEmpty.vue';
import Pill from '../components/Pill.vue';
import { mapGetters } from 'vuex';
import AgentBotListPagination from '../../integrations/agent-bot/components/AgentBotListPagination.vue';

export default {
  components: { InvoiceEmpty, Pill, AgentBotListPagination },
  mixins: [alertMixin],
  data() {
    return {
      orders: {
        data: [],
        per_page: 0,
        current_page: 0,
        total_record: 0,
      },
      isLoading: true,
      limit: 10,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders(page = 1, limit = this.limit) {
      this.isLoading = true;
      try {
        const req = await OrdersAPI.get({ params: { per_page: limit, page } });
        this.orders = req.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.showAlert('Error get list of invoices!');
      }
    },
    handlePageChange(page) {
      this.getOrders(page);
    },
    handleLimitChange(limit) {
      this.limit = limit;
      this.getOrders(1, limit);
    },
    renderDuration(type, duration) {
      if (type === 'PlanOrder') {
        switch (duration) {
          case 30:
            return '(Monthly)';
          default:
            return '';
        }
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.invoices {
  &-wrapper {
    &__list {
      background: var(--white);
      border: 1px solid var(--grey-04);
      border-radius: 8px;
      margin-top: 16px;
    }
  }
  &-list {
    &__table {
      margin: 0;
      thead,
      tbody {
        tr {
          th,
          td {
            padding: 12px 16px;
            color: var(--greydark-02);
          }
        }
      }
      thead {
        tr {
          border-bottom: 1px solid var(--grey-04);
          th {
            text-transform: capitalize;
            font-weight: bold;
            padding: 12px 16px;
          }
        }
      }
      tbody {
        tr {
          .agent-bot-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 50ch;
          }
          border-bottom: 1px solid var(--grey-04);
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
