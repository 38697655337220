var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"instagram-step__fourth"},[_c('div',{staticClass:"instagram-step__fourth-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12 form-group"},[_c('woot-input',{class:{ error: _vm.$v.form.instagram_account.$error },attrs:{"label":_vm.$t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.INSTAGRAM_ACCOUNT.LABEL'),"type":"text","placeholder":_vm.$t(
              'NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.INSTAGRAM_ACCOUNT.PLACEHOLDER'
            ),"error":_vm.$v.form.instagram_account.$error && _vm.form.instagram_account === ''
              ? _vm.$t(
                  'NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.INSTAGRAM_ACCOUNT.ERROR_REQUIRED'
                )
              : _vm.$v.form.instagram_account.$error &&
                _vm.form.instagram_account.length > 30
              ? _vm.$t(
                  'NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.INSTAGRAM_ACCOUNT.ERROR_MAXLENGTH'
                )
              : ''},on:{"blur":_vm.$v.form.instagram_account.$touch},model:{value:(_vm.form.instagram_account),callback:function ($$v) {_vm.$set(_vm.form, "instagram_account", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.instagram_account"}})],1),_vm._v(" "),_vm._l((_vm.onboarding.radio),function(value,name){return _c('div',{key:name,staticClass:"small-12 form-group"},[_c('label',[_vm._v(_vm._s(_vm.onboarding.radio[name].text))]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12 align-self-top",class:_vm.$v.form[name].onboarding_answer_id.$error && 'error'},[_c('div',{staticClass:"form-wrapper"},[_vm._l((_vm.onboarding.radio[name].answers),function(item){return _c('div',{key:item.id,staticClass:"form-item"},[_c('input',{attrs:{"id":'step-4-' + item.id,"type":"radio","name":name},domProps:{"value":item.id},on:{"change":function($event){return _vm.onChangeRadioButton(
                          item,
                          _vm.onboarding.radio[name].id,
                          name
                        )}}}),_vm._v(" "),_c('label',{attrs:{"for":'step-4-' + item.id}},[_vm._v(_vm._s(_vm.renderAnswer(item.answer)))])])}),_vm._v(" "),(_vm.$v.form[name].onboarding_answer_id.$error)?_c('span',{staticClass:"message"},[_vm._v("\n                    "+_vm._s(_vm.$t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.ERROR'))+"\n                  ")]):_vm._e()],2)]),_vm._v(" "),(_vm.condition[name])?_c('div',{staticClass:"small-12 align-self-top"},[_c('woot-input',{class:{ error: _vm.$v.form[name].free_text.$error },attrs:{"type":"text","error":_vm.$v.form[name].free_text.$error
                      ? _vm.$t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.ERROR')
                      : ''},on:{"blur":_vm.$v.form[name].free_text.$touch},model:{value:(_vm.form[name].free_text),callback:function ($$v) {_vm.$set(_vm.form[name], "free_text", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form[name].free_text"}})],1):_vm._e()])])])])}),_vm._v(" "),_vm._l((_vm.onboarding.dropdown),function(value,name){return _c('div',{key:name,staticClass:"small-12 form-group"},[_c('label',[_vm._v(_vm._s(_vm.onboarding.dropdown[name].text))]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12 align-self-top",class:_vm.$v.form[name].onboarding_answer_id.$error && 'error'},[_c('dropdown',{attrs:{"options":_vm.renderDropdown(_vm.onboarding.dropdown[name].answers),"placeholder":_vm.$t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.DROPDOWN')},on:{"click":function (id) {
                      _vm.onSelectDropdown(
                        id,
                        name,
                        _vm.onboarding.dropdown[name].id
                      );
                    }}}),_vm._v(" "),(_vm.$v.form[name].onboarding_answer_id.$error)?_c('span',{staticClass:"message"},[_vm._v("\n                  "+_vm._s(_vm.$t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.ERROR'))+"\n                ")]):_vm._e()],1)])])])])}),_vm._v(" "),_vm._l((_vm.onboarding.checkbox),function(value,name){return _c('div',{key:name,staticClass:"small-12 form-group"},[_c('label',[_vm._v(_vm._s(_vm.onboarding.checkbox[name].text))]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"small-12 align-self-top",class:_vm.$v.form[name].onboarding_answer_id.$error && 'error'},[_c('div',{staticClass:"form-wrapper"},[_vm._l((_vm.onboarding.checkbox[name].answers),function(item){return _c('div',{key:item.id,staticClass:"form-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[name].onboarding_answer_id),expression:"form[name].onboarding_answer_id"}],attrs:{"id":item.id,"type":"checkbox","name":item.id},domProps:{"value":item.id,"checked":Array.isArray(_vm.form[name].onboarding_answer_id)?_vm._i(_vm.form[name].onboarding_answer_id,item.id)>-1:(_vm.form[name].onboarding_answer_id)},on:{"change":[function($event){var $$a=_vm.form[name].onboarding_answer_id,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form[name], "onboarding_answer_id", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form[name], "onboarding_answer_id", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form[name], "onboarding_answer_id", $$c)}},function () {
                          _vm.onChangeCheck(_vm.onboarding.checkbox[name].id, name);
                        }]}}),_vm._v(" "),_c('label',{attrs:{"for":item.id}},[_vm._v(_vm._s(_vm.renderAnswer(item.answer)))])])}),_vm._v(" "),(_vm.$v.form[name].onboarding_answer_id.$error)?_c('span',{staticClass:"message"},[_vm._v("\n                    "+_vm._s(_vm.$t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.ERROR'))+"\n                  ")]):_vm._e()],2)])])])])])})],2)]),_vm._v(" "),_c('img',{attrs:{"src":require("dashboard/assets/images/hero/instagram-fourth-step.png")}})])}
var staticRenderFns = []

export { render, staticRenderFns }