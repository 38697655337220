var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-text__wrap"},[_c('a',{attrs:{"target":"_blank","href":'https://maps.google.com/?q=' + _vm.latitude + ',' + _vm.longitude}},[_c('GmapMap',{staticStyle:{"width":"400px","height":"300px"},attrs:{"center":{ lat: _vm.latitude, lng: _vm.longitude },"zoom":15,"map-type-id":"roadmap","options":{
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        clickableIcons: false,
        gestureHandling: 'none',
      }}},[_c('GmapMarker',{attrs:{"position":{ lat: _vm.latitude, lng: _vm.longitude },"clickable":false,"draggable":false},on:{"click":function($event){_vm.center = { lat: _vm.latitude, lng: _vm.longitude }}}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }