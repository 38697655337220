<template>
  <label class="switch" :class="classObject">
    <input
      :id="id"
      class="switch-input"
      :name="name"
      :disabled="disabled"
      type="checkbox"
      :checked="checked"
      @input="onChangeEvent"
    />
    <div class="switch-paddle" :for="name">
      <span class="show-for-sr">on off</span>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    isFullwidth: Boolean,
    type: String,
    size: String,
    checked: Boolean,
    name: String,
    id: String,
  },
  computed: {
    classObject() {
      const { type, size, checked } = this;
      return {
        [`is-${type}`]: type,
        [`${size}`]: size,
        checked,
      };
    },
  },
  methods: {
    onChangeEvent(event) {
      this.$emit('input-change', event.target.checked);
    },
  },
};
</script>
