<template>
  <div class="message-text__wrap">
    <a
      target="_blank"
      :href="'https://maps.google.com/?q=' + latitude + ',' + longitude"
    >
      <GmapMap
        :center="{ lat: latitude, lng: longitude }"
        :zoom="15"
        map-type-id="roadmap"
        :options="{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: false,
          clickableIcons: false,
          gestureHandling: 'none',
        }"
        style="width: 400px; height: 300px"
      >
        <GmapMarker
          :position="{ lat: latitude, lng: longitude }"
          :clickable="false"
          :draggable="false"
          @click="center = { lat: latitude, lng: longitude }"
        />
      </GmapMap>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    longitude: {
      type: Number,
      required: true,
    },
    latitude: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    address: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
  },
};
</script>
