<template>
  <div class="medium-12 column">
    <div class="row align-center">
      <div class="medium-8 column login">
        <div class="row align-center">
          <div class="small-8 medium-6 login__hero align-self-top">
            <img
              :src="globalConfig.logo"
              :alt="globalConfig.installationName"
              class="hero__logo"
            />
            <h2 class="hero__title">
              {{
                useInstallationName(
                  $t('LOGIN.TITLE'),
                  globalConfig.installationName,
                )
              }}
            </h2>
          </div>
        </div>
        <div class="row align-center">
          <div class="small-8 medium-6 column">
            <div class="row align-center">
              <div v-if="!email" class="small-12 medium-12 column">
                <form
                  class="login-box column align-self-top"
                  @submit.prevent="login()"
                >
                  <div class="column log-in-form">
                    <woot-input
                      v-model.trim="credentials.email"
                      type="email"
                      icon="ic_message.svg"
                      :class="{ error: $v.credentials.email.$error }"
                      :placeholder="$t('LOGIN.EMAIL.PLACEHOLDER')"
                      :error="
                        $v.credentials.email.$error
                          ? $t('LOGIN.EMAIL.ERROR')
                          : ''
                      "
                      @blur="$v.credentials.email.$touch"
                    />
                    <woot-input
                      v-model.trim="credentials.password"
                      type="password"
                      icon="ic_lock.svg"
                      :class="{ error: $v.credentials.password.$error }"
                      :placeholder="$t('LOGIN.PASSWORD.PLACEHOLDER')"
                      :error="
                        $v.credentials.password.$error
                          ? $t('LOGIN.PASSWORD.ERROR')
                          : ''
                      "
                      @blur="$v.credentials.password.$touch"
                    />
                    <div class="forgot-password">
                      <router-link to="auth/reset/password">
                        {{ $t('LOGIN.FORGOT_PASSWORD') }}
                      </router-link>
                    </div>
                    <woot-submit-button
                      :disabled="loginApi.showLoading"
                      :button-text="$t('LOGIN.SUBMIT')"
                      :loading="loginApi.showLoading"
                      button-class="expanded"
                    >
                    </woot-submit-button>
                  </div>
                </form>
                <div class="column signin__footer">
                  <p v-if="showSignupLink()">
                    {{ $t('LOGIN.DONT_HAVE_ACCOUNT') }}
                    <router-link to="auth/signup">
                      {{ $t('LOGIN.CREATE_NEW_ACCOUNT') }}
                    </router-link>
                  </p>
                </div>
              </div>
              <woot-spinner v-else size="" />
            </div>
          </div>
        </div>
      </div>
      <div class="medium-4 column hero-container">
        <img src="~dashboard/assets/images/hero/hero-login.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import WootSubmitButton from '../../components/buttons/FormSubmitButton';
import { mapGetters } from 'vuex';

export default {
  components: {
    WootSubmitButton,
  },
  mixins: [globalConfigMixin],
  props: {
    ssoAuthToken: { type: String, default: '' },
    redirectUrl: { type: String, default: '' },
    config: { type: String, default: '' },
    email: { type: String, default: '' },
  },
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        email: '',
        password: '',
      },
      loginApi: {
        message: '',
        showLoading: false,
      },
      error: '',
    };
  },
  validations: {
    credentials: {
      password: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  created() {
    if (this.ssoAuthToken) {
      this.login();
    }
  },
  mounted() {
    this.$customGTM('Event', `Homepage-Login`, 'Login', `Login`);
    this.$customGTM(
      'Event',
      `Homepage-Login`,
      'Forgot Password',
      `Forgot Password`,
    );
    this.$customGTM(
      'Event',
      `Homepage-Login`,
      'Create Account',
      `Create Account`,
    );
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.loginApi.showLoading = false;
      this.loginApi.message = message;
      bus.$emit('newToastMessage', this.loginApi.message);
    },
    showSignupLink() {
      return window.kataOmnichatConfig.signupEnabled === 'true';
    },
    login() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loginApi.showLoading = true;
      const credentials = {
        email: this.email ? this.email : this.credentials.email,
        password: this.credentials.password,
        sso_auth_token: this.ssoAuthToken,
      };
      this.$store
        .dispatch('login', credentials)
        .then(() => {
          this.showAlert(this.$t('LOGIN.API.SUCCESS_MESSAGE'));
        })
        .catch(response => {
          // Reset URL Params if the authentication is invalid
          if (this.email) {
            window.location = '/app/login';
          }

          if (response && response.status === 401) {
            const { errors } = response.data;
            const hasAuthErrorMsg =
              errors &&
              errors.length &&
              errors[0] &&
              typeof errors[0] === 'string';
            if (hasAuthErrorMsg) {
              this.showAlert(errors[0]);
            } else {
              this.showAlert(this.$t('LOGIN.API.UNAUTH'));
            }
            return;
          }
          this.showAlert(this.$t('LOGIN.API.ERROR_MESSAGE'));
        });
    },
  },
};
</script>
