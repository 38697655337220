/* global axios */
import ApiClient from './ApiClient';

class Inboxes extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  getCommentActivation(inboxId) {
    return axios.get(`${this.url}/${inboxId}/instagram_comments`);
  }

  setCommentActivation({ inboxId, data }) {
    return axios.put(`${this.url}/${inboxId}/instagram_comments`, {
      comment_enabled: data,
    });
  }

  getAssignableAgents(inboxId) {
    return axios.get(`${this.url}/${inboxId}/assignable_agents`);
  }

  getCampaigns(inboxId) {
    return axios.get(`${this.url}/${inboxId}/campaigns`);
  }

  getWhatsappCredits(inboxId, params) {
    return axios.get(`${this.url}/${inboxId}/whatsapp_credit`, {
      params,
    });
  }
}

export default new Inboxes();
