<template>
  <div class="banner-container" :class="variant">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ConversationAlert',
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style scoped lang="scss">
.banner-container {
  background: var(--b-500);
  color: var(--white);
  font-size: var(--font-size-mini);
  padding: var(--space-slab) var(--space-normal);
  text-align: center;
  position: relative;

  .link {
    line-height: inherit;
    cursor: pointer;
    color: var(--w-800) !important;
  }

  a {
    text-decoration: underline;
    color: var(--white);
    font-size: var(--font-size-mini);
  }

  &.alert {
    color: var(--white);
    background: var(--r-400);
  }
}
</style>
