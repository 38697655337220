<template>
  <div class="settings--content">
    <woot-loading-state v-if="getUIFlags.isFetching" />
    <div v-else class="card row flex-dir-row align-center-middle">
      <div class="small-8">
        <img
          class="badge-plan"
          src="~dashboard/assets/images/channels/whatsapp-rounded.svg"
        />
        <h5 class="title">
          {{ $t('INBOX_MGMT.CREDITS.WHATSAPP_CREDITS') }}
        </h5>
        <p class="description">
          {{ getInboxesWhatsappCredit.credit | toNumber }}
          {{ $t('INBOX_MGMT.CREDITS.CREDITS_REMAINING') }}
        </p>
      </div>
      <div class="small-4 text-right">
        <woot-button type="button" size="small" @click="openLink">
          Top up
        </woot-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Credits',
  computed: {
    ...mapGetters({
      getInboxesWhatsappCredit: 'inboxes/getInboxesWhatsappCredit',
      getUIFlags: 'inboxes/getUIFlags',
      inboxes: 'inboxes/getInbox',
    }),
  },
  mounted() {
    const currentInboxId = this.$route.params.inboxId;
    const inbox = this.inboxes(currentInboxId);
    this.$store.dispatch('inboxes/getWhatsappCredits', { inboxId: inbox.id });
  },
  methods: {
    openLink() {
      const { company_id: companyID } = this.getInboxesWhatsappCredit;
      if (!companyID) return;
      const win = window.open(
        `https://business.kata.ai/company/${companyID}/credits/topup`,
        '_blank',
        'noopener',
      );
      win.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.settings--content {
  margin-top: 32px;
}

.card {
  border: 1px solid #e5eaef;
  background: #fbfbfd;
  font-size: 14px;
  padding: 32px;
  border-radius: 12px;

  .title {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 700;
  }

  .description {
    color: #6a7a8a;
  }
}
</style>
