<template>
  <div class="row order">
    <div class="icon-container medium-1 ">
      <img class="badge-plan" :src="image" />
    </div>
    <div class="row medium-11 justify-space-between align-middle">
      <div class="item">
        <div class="name">
          {{ title }}
        </div>
        <div class="description">
          {{ description }}
        </div>
      </div>
      <div class="price">
        {{ price | toCurrency }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderItem',
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    price: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  margin-bottom: 20px;

  .name,
  .price {
    font-weight: 700;
  }

  .description {
    color: #6a7a8a;
  }
}
</style>
