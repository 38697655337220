/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
const MyGTM = {
  install(Vue) {
    Vue.prototype.$customGTM = function(
      event,
      eventCategory,
      eventAction,
      eventLabel,
      value = null,
    ) {
      this.$gtm.trackEvent({
        event, // Event type [default = 'interaction'] (Optional)
        category: eventCategory,
        action: eventAction,
        label: eventLabel,
        value,
        stepName: undefined,
        stepNumber: undefined,
        noninteraction: false, // Optional
      });
    };
    Vue.prototype.$customGTMStep = function(event, stepName, stepNumber) {
      this.$gtm.trackEvent({
        event,
        stepName,
        stepNumber,
        noninteraction: false, // Optional
      });
    };
  },
};

export default MyGTM;
