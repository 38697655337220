import { render, staticRenderFns } from "./AgentBotListPagination.vue?vue&type=template&id=5514fbbd&scoped=true&"
import script from "./AgentBotListPagination.vue?vue&type=script&lang=js&"
export * from "./AgentBotListPagination.vue?vue&type=script&lang=js&"
import style0 from "./AgentBotListPagination.vue?vue&type=style&index=0&id=5514fbbd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5514fbbd",
  null
  
)

export default component.exports