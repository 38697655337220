<template>
  <label class="bank-card" :class="{ active: bank.name === value }">
    <img class="badge-plan" :src="bank.imagePath" />
    <input
      type="radio"
      name="bank"
      :value="bank.name"
      :checked="value && bank.name === value"
      @change="$emit('input', bank.name)"
    />
  </label>
</template>

<script>
export default {
  name: 'BankCard',
  props: {
    bank: {
      type: Object,
      default: () => ({
        name: '',
        alias: '',
        imagePath: '',
      }),
    },
    value: {
      type: String,
      require: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bank-card {
  border: 1px solid #e5eaef;
  padding: 18px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;

  &.active {
    background-color: #ebf5ff;
    border-color: #006fe6;
  }
}
</style>
