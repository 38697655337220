<template>
  <div class="integrations-empty__wrapper">
    <img src="~dashboard/assets/images/empty_state.svg" />
    <span class="integrations-empty__title">{{ title }}</span>
    <span class="integrations-empty__content">{{ content }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    content: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.integrations {
  &-empty {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 40px 0;
      max-width: 200px;
      text-align: center;
      margin: 0 auto;
    }
    &__title {
      display: block;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin: 16px 0px;
      color: #24282d;
    }
    &__content {
      display: block;
      font-size: 12px;
      line-height: 18px;
      color: var(--greymed-04);
    }
  }
}
</style>
