<template>
  <div
    class="dropdown"
    :class="computedClass"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div class="selected" :class="{ open: open }" @click="handleOpenDropdown">
      <flag v-if="withFlag" :iso="renderSelectedFlag" />
      {{ renderSelectedOption || placeholder }}
    </div>
    <div class="items" :class="{ hide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        class="item"
        @click="onSelectOption(option.value)"
      >
        <flag v-if="withFlag" :iso="option.flag" /> {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable func-names */
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    withFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value || null,
      open: false,
    };
  },
  computed: {
    renderSelectedOption() {
      if (Array.isArray(this.options) && this.options.length && this.selected) {
        const selected = this.options.find(
          item => item.value === this.selected,
        );
        return selected.label;
      }
      return this.selected;
    },
    renderSelectedFlag() {
      if (Array.isArray(this.options) && this.options.length && this.selected) {
        const selected = this.options.find(
          item => item.value === this.selected,
        );
        return selected.flag;
      }

      return 'ID';
    },
    computedClass() {
      return this.disabled ? 'disabled' : '';
    },
  },
  watch: {
    options: function(val) {
      if (Array.isArray(val) && !val.length) {
        this.$data.selected = null;
      }
    },
  },
  mounted() {
    if (this.selected) {
      this.$emit('click', this.selected);
    }
  },
  methods: {
    onSelectOption(option) {
      this.selected = option;
      this.open = false;
      this.$emit('click', option);
    },
    handleOpenDropdown() {
      if (!this.disabled) {
        this.open = !this.open;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 3.9rem;
  line-height: 3.9rem;
  font-size: 14px;

  &.disabled {
    .selected {
      background-color: var(--grey-02);
    }
  }

  .selected {
    background-color: var(--white);
    border-radius: 4px;
    border: 1px solid var(--grey-04);
    color: var(--grey-08);
    padding-left: 12px;
    cursor: pointer;
    user-select: none;
    height: 3.9rem;

    &.open {
      border: 1px solid var(--grey-04);
    }

    &:after {
      position: absolute;
      content: '';
      top: 14px;
      right: 1.5em;
      border-radius: 0px;
      display: inline-block;
      width: 1px;
      height: 1px;
      box-shadow: 1px 4px rgba(51, 62, 78, 0.37), 1px 5px rgba(48, 63, 79, 0.62),
        1px 10px rgba(50, 63, 79, 0.62), 1px 11px rgba(48, 64, 77, 0.38),
        2px 3px rgba(51, 62, 78, 0.37), 2px 4px rgba(49, 63, 78, 1),
        2px 5px rgba(48, 63, 79, 0.62), 2px 10px rgba(50, 63, 79, 0.62),
        2px 11px rgba(49, 63, 78, 1), 2px 12px rgba(48, 64, 77, 0.38),
        3px 2px rgba(51, 62, 78, 0.37), 3px 3px rgba(49, 63, 78, 1),
        3px 4px rgba(49, 63, 78, 1), 3px 5px rgba(48, 63, 79, 0.62),
        3px 10px rgba(50, 63, 79, 0.62), 3px 11px rgba(49, 63, 78, 1),
        3px 12px rgba(49, 63, 78, 1), 3px 13px rgba(48, 64, 77, 0.31),
        4px 2px rgba(48, 64, 77, 0.31), 4px 3px rgba(49, 63, 78, 1),
        4px 4px rgba(49, 63, 78, 1), 4px 5px rgba(48, 63, 79, 0.62),
        4px 10px rgba(50, 63, 79, 0.62), 4px 11px rgba(49, 63, 78, 1),
        4px 12px rgba(49, 63, 78, 1), 4px 13px rgba(48, 64, 77, 0.38),
        5px 3px rgba(48, 62, 78, 0.37), 5px 4px rgba(49, 63, 78, 1),
        5px 5px rgba(48, 63, 79, 0.62), 5px 10px rgba(50, 63, 79, 0.62),
        5px 11px rgba(49, 63, 78, 1), 5px 12px rgba(48, 64, 77, 0.38),
        6px 4px rgba(48, 62, 78, 0.37), 6px 5px rgba(48, 63, 79, 0.62),
        6px 10px rgba(50, 63, 79, 0.62), 6px 11px rgba(48, 64, 77, 0.38);
    }
  }

  .items {
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0px 4px 8px rgba(137, 148, 159, 0.32);
    left: 0;
    right: 0;
    top: calc(100% + 12px);
    z-index: 1;
    padding: 8px 0;
    max-height: 200px;
    overflow-y: auto;

    .item {
      color: var(--grey-08);
      padding-left: 1em;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: var(--grey-03);
      }
    }

    &.hide {
      display: none;
    }
  }
}
</style>
