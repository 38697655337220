export const mauList = [
  500,
  1000,
  5000,
  10000,
  25000,
  50000,
  100000,
  150000,
  200000,
  250000,
];
