<template>
  <div class="integrations-inboxes">
    <div
      v-for="(data, index) in inboxes"
      :key="index"
      class="integrations-inboxes__list"
    >
      <i class="inbox-icon" :class="computedInboxClass(data.channel_type)" />
      <span class="inbox-name">{{ data.name }}</span>
    </div>
  </div>
</template>

<script>
import { getInboxClassByType } from 'dashboard/helper/inbox';

export default {
  props: {
    inboxes: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  methods: {
    computedInboxClass(channelType) {
      const classByType = getInboxClassByType(channelType);
      return classByType;
    },
  },
};
</script>

<style lang="scss" scoped>
.inbox-name {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
