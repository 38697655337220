/* global axios */

import ApiClient from './ApiClient';

class Orders extends ApiClient {
  constructor() {
    super('orders', {
      accountScoped: true,
    });
  }

  get(options) {
    return axios.get(`${this.url}`, options).catch(error => {
      if (error.response) {
        throw Error(error.response.data.message);
      }
      throw error;
    });
  }

  getId(id, data) {
    return axios.get(`${this.url}/${id}`, data).catch(error => {
      if (error.response) {
        throw Error(error.response.data.message);
      }
      throw error;
    });
  }
}

export default new Orders();
