<template>
  <div class="instagram-step__fourth">
    <div class="instagram-step__fourth-content">
      <div class="row">
        <div class="small-12 form-group">
          <woot-input
            v-model.trim="form.instagram_account"
            :label="
              $t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.INSTAGRAM_ACCOUNT.LABEL')
            "
            type="text"
            :class="{ error: $v.form.instagram_account.$error }"
            :placeholder="
              $t(
                'NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.INSTAGRAM_ACCOUNT.PLACEHOLDER',
              )
            "
            :error="
              $v.form.instagram_account.$error && form.instagram_account === ''
                ? $t(
                    'NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.INSTAGRAM_ACCOUNT.ERROR_REQUIRED',
                  )
                : $v.form.instagram_account.$error &&
                  form.instagram_account.length > 30
                ? $t(
                    'NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.INSTAGRAM_ACCOUNT.ERROR_MAXLENGTH',
                  )
                : ''
            "
            @blur="$v.form.instagram_account.$touch"
          />
        </div>
        <div
          v-for="(value, name) in onboarding.radio"
          :key="name"
          class="small-12 form-group"
        >
          <label>{{ onboarding.radio[name].text }}</label>
          <div class="row">
            <div class="small-12">
              <div class="row">
                <div
                  class="small-12 align-self-top"
                  :class="$v.form[name].onboarding_answer_id.$error && 'error'"
                >
                  <div class="form-wrapper">
                    <div
                      v-for="item in onboarding.radio[name].answers"
                      :key="item.id"
                      class="form-item"
                    >
                      <input
                        :id="'step-4-' + item.id"
                        type="radio"
                        :name="name"
                        :value="item.id"
                        @change="
                          onChangeRadioButton(
                            item,
                            onboarding.radio[name].id,
                            name,
                          )
                        "
                      />
                      <label :for="'step-4-' + item.id">{{
                        renderAnswer(item.answer)
                      }}</label>
                    </div>
                    <span
                      v-if="$v.form[name].onboarding_answer_id.$error"
                      class="message"
                    >
                      {{ $t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.ERROR') }}
                    </span>
                  </div>
                </div>
                <div v-if="condition[name]" class="small-12 align-self-top">
                  <woot-input
                    v-model.trim="form[name].free_text"
                    type="text"
                    :class="{ error: $v.form[name].free_text.$error }"
                    :error="
                      $v.form[name].free_text.$error
                        ? $t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.ERROR')
                        : ''
                    "
                    @blur="$v.form[name].free_text.$touch"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(value, name) in onboarding.dropdown"
          :key="name"
          class="small-12 form-group"
        >
          <label>{{ onboarding.dropdown[name].text }}</label>
          <div class="row">
            <div class="small-12">
              <div class="row">
                <div
                  class="small-12 align-self-top"
                  :class="$v.form[name].onboarding_answer_id.$error && 'error'"
                >
                  <dropdown
                    :options="renderDropdown(onboarding.dropdown[name].answers)"
                    :placeholder="
                      $t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.DROPDOWN')
                    "
                    @click="
                      id => {
                        onSelectDropdown(
                          id,
                          name,
                          onboarding.dropdown[name].id,
                        );
                      }
                    "
                  />
                  <span
                    v-if="$v.form[name].onboarding_answer_id.$error"
                    class="message"
                  >
                    {{ $t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.ERROR') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(value, name) in onboarding.checkbox"
          :key="name"
          class="small-12 form-group"
        >
          <label>{{ onboarding.checkbox[name].text }}</label>
          <div class="row">
            <div class="small-12">
              <div class="row">
                <div
                  class="small-12 align-self-top"
                  :class="$v.form[name].onboarding_answer_id.$error && 'error'"
                >
                  <div class="form-wrapper">
                    <div
                      v-for="item in onboarding.checkbox[name].answers"
                      :key="item.id"
                      class="form-item"
                    >
                      <input
                        :id="item.id"
                        v-model="form[name].onboarding_answer_id"
                        type="checkbox"
                        :name="item.id"
                        :value="item.id"
                        @change="
                          () => {
                            onChangeCheck(onboarding.checkbox[name].id, name);
                          }
                        "
                      />
                      <label :for="item.id">{{
                        renderAnswer(item.answer)
                      }}</label>
                    </div>
                    <span
                      v-if="$v.form[name].onboarding_answer_id.$error"
                      class="message"
                    >
                      {{ $t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.ERROR') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="~dashboard/assets/images/hero/instagram-fourth-step.png" />
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';

import Dropdown from 'dashboard/components/kata/dropdown/Dropdown';
import Onboarding from 'dashboard/api/onboarding';
import alertMixin from 'shared/mixins/alertMixin';

import { prepareToDisplay } from '../helper/helper';

function discoverCondition(value) {
  return !this.$data.condition.discover || required(value);
}

function intentionCondition(value) {
  return !this.$data.condition.intention || required(value);
}

function divisionCondition(value) {
  return !this.$data.condition.division || required(value);
}

export default {
  components: {
    Dropdown,
  },
  mixins: [alertMixin],
  data() {
    return {
      onboarding: {},
      form: {
        instagram_account: '',
        device: {
          onboarding_question_id: '',
          onboarding_answer_id: '',
        },
        direct_messages: {
          onboarding_question_id: '',
          onboarding_answer_id: [],
        },
        discover: {
          free_text: '',
          onboarding_question_id: '',
          onboarding_answer_id: '',
        },
        division: {
          free_text: '',
          onboarding_question_id: '',
          onboarding_answer_id: '',
        },
        intention: {
          free_text: '',
          onboarding_question_id: '',
          onboarding_answer_id: '',
        },
        monthly_conversation: {
          onboarding_question_id: '',
          onboarding_answer_id: '',
        },
      },
      condition: {
        discover: false,
        intention: false,
        division: false,
      },
      freeTextCategory: 'FREE_TEXT',
    };
  },
  validations: {
    form: {
      instagram_account: {
        required,
        maxLength: maxLength(30),
      },
      device: {
        onboarding_question_id: {
          required,
        },
        onboarding_answer_id: {
          required,
        },
      },
      direct_messages: {
        onboarding_question_id: {
          required,
        },
        onboarding_answer_id: {
          required,
        },
      },
      discover: {
        free_text: {
          required: discoverCondition,
        },
        onboarding_question_id: {
          required,
        },
        onboarding_answer_id: {
          required,
        },
      },
      division: {
        free_text: {
          required: divisionCondition,
        },
        onboarding_question_id: {
          required,
        },
        onboarding_answer_id: {
          required,
        },
      },
      intention: {
        onboarding_question_id: {
          required,
        },
        onboarding_answer_id: {
          required,
        },
        free_text: {
          required: intentionCondition,
        },
      },
      monthly_conversation: {
        onboarding_question_id: {
          required,
        },
        onboarding_answer_id: {
          required,
        },
      },
    },
  },
  created() {
    this.getQuestions();
  },
  methods: {
    validate() {
      const isValid = !this.$v.form.$invalid;
      this.$v.form.$touch();
      this.$emit('on-validate', { ...this.$data.form }, isValid);
      return isValid;
    },
    async getQuestions() {
      try {
        const response = await Onboarding.onboardingQuestions('1');
        const onboardMapping = prepareToDisplay(response);
        this.onboarding = onboardMapping;
      } catch (error) {
        let errorMessage = this.$t('NEW_CHANNEL.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      } finally {
        this.onboardingOnProgress = false;
      }
    },
    renderAnswer(answer) {
      if (answer === this.freeTextCategory) {
        return this.$t('NEW_CHANNEL.INSTAGRAM.FOURTH_STEP.FORM.OTHERS');
      }
      return answer;
    },
    renderDropdown(options) {
      return options.map(({ id, answer }) => {
        return {
          label: answer,
          value: id,
        };
      });
    },
    onSelectDropdown(id, field, questionId) {
      this.form[field].onboarding_question_id = questionId;
      this.form[field].onboarding_answer_id = id;
    },
    onChangeRadioButton({ id, answer }, questionId, field) {
      this.form[field].onboarding_question_id = questionId;
      this.form[field].onboarding_answer_id = id;
      if (answer === this.freeTextCategory) {
        this.condition[field] = true;
      } else {
        this.form[field].free_text = '';
        this.condition[field] = false;
      }
    },
    onChangeCheck(questionId, field) {
      this.form[field].onboarding_question_id = questionId;
    },
  },
};
</script>

<style lang="scss" scoped>
.instagram {
  &-step {
    &__fourth {
      display: flex;
      img {
        flex: 1;
        height: 100%;
      }
      &-content {
        flex: 1;
        text-align: left;
      }
    }
  }
}
.form {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    flex: 50%;
  }
}
.message {
  margin-top: 5px;
}
</style>
