<template>
  <div class="goal">
    <span class="onboarding-title">{{ $t('NEW_ONBOARDING.TELL.GOAL') }}</span>
    <div v-if="goalItem" class="goal-item__container">
      <div
        v-for="item in goalItem.answers"
        :key="item.id"
        class="goal-item__wrapper"
      >
        <goal-item :goal-key="item.id" />
        <div class="goal-item__description" v-html="item.answer" />
        <kata-button
          variant="outline"
          class="full-width"
          :class="computedClass(item.id)"
          @click="selectGoal(item)"
        >
          {{
            goal === item.id
              ? $t('NEW_ONBOARDING.GOAL.SELECTED')
              : $t('NEW_ONBOARDING.GOAL.SELECT')
          }}
        </kata-button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import KataButton from 'dashboard/components/buttons/Button';
import GoalItem from '../components/GoalItem.vue';

export default {
  components: {
    KataButton,
    GoalItem,
  },
  props: {
    goalItem: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      goal: null,
      goal_label: null,
    };
  },
  mounted() {
    this.$customGTMStep('Self-Onboarding', `Goals`, 2);
  },
  validations: {
    goal: {
      required,
      numeric,
    },
    form: ['goal'],
  },
  methods: {
    validate() {
      const isValid = !this.$v.form.$invalid;
      this.$v.form.$touch();
      this.$emit(
        'on-validate',
        {
          goal: {
            onboarding_question_id: this.goalItem.id,
            onboarding_answer_id: this.$data.goal,
            onboarding_answer_label: this.$data.goal_label,
          },
        },
        isValid,
      );
      return isValid;
    },
    selectGoal(goal) {
      this.goal = goal.id;
      this.goal_label = goal.answer;
      this.$parent.$parent.nextTab();
      this.$customGTMStep('Self-Onboarding', `your business account`, 3);
    },
    computedClass(goal) {
      if (this.goal === goal) {
        return 'selected';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.goal {
  &-item {
    &__container {
      display: flex;
    }

    &__wrapper {
      flex: 1;
      margin-right: 24px;
      padding: 24px;
      border: 1px solid var(--grey-04);
      border-radius: 8px;

      &:last-child {
        margin: 0;
      }

      button {
        &.selected {
          background: var(--grey-03);
        }
      }
    }

    &__description {
      margin: 16px 0;
      font-size: var(--font-size-micro);
    }
  }
}
</style>
