import TrialInfo from '../../../api/trialInfo';
import BillingInfo from '../../../api/billingInfo';
import types from '../../mutation-types';

export const actions = {
  get: async ({ commit }) => {
    try {
      commit(types.SET_SUBCRIPTION_UI_FLAG, { isFetching: true });
      const [planInfo, info] = await Promise.all([
        TrialInfo.getPlanInfo(),
        TrialInfo.get(),
      ]);
      commit(types.SET_SUBCRIPTION_INFO_PLAN, planInfo.data);
      commit(types.SET_SUBCRIPTION_INFO, info.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SUBCRIPTION_UI_FLAG, { isFetching: false });
    }
  },

  getBillingInfo: async ({ commit }) => {
    try {
      const response = await BillingInfo.get();
      commit(types.SET_BILLING_INFO, {
        ...response.data.billing_info,
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  addBillingInfo: async ({ commit }, data) => {
    try {
      const response = await BillingInfo.create(data);
      commit(types.SET_BILLING_INFO, {
        ...response.data.billing_info,
      });
    } catch (error) {
      throw new Error(error);
    }
  },
};
