<template>
  <div v-if="comment" class="comment-bubble">
    <div class="comment-bubble__content" v-html="comment.content" />
    <img
      v-tooltip.left-start="'Comment'"
      src="~dashboard/assets/icons/ic_chat.svg"
    />
  </div>
</template>

<script>
import ConversationApi from 'dashboard/api/inbox/conversation';

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    commentId: {
      type: [String, Number],
      default: '',
    },
    conversationId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      comment: null,
    };
  },
  created() {
    this.getCommentDetail();
  },
  methods: {
    async getCommentDetail() {
      try {
        this.isLoading = true;
        const res = await ConversationApi.getCommentDetail({
          conversationId: this.conversationId,
          commentId: this.commentId,
        });
        this.comment = res.data;
      } catch (err) {
        this.comment = null;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.comment {
  &-bubble {
    padding-top: 24px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 58px;

    &__content {
      padding-right: 8px;
      font-size: 1.4rem;
    }
  }
}
</style>
