export const INBOX_TYPES = {
  WEB: 'Channel::WebWidget',
  FB: 'Channel::FacebookPage',
  IG: 'Channel::InstagramPage',
  KATA_PLATFORM: 'Channel::KataPlatform',
  TWITTER: 'Channel::TwitterProfile',
  TWILIO: 'Channel::TwilioSms',
  API: 'Channel::Api',
  EMAIL: 'Channel::Email',
  WHATSAPP: 'Channel::Whatsapp',
};

export default {
  computed: {
    channelType() {
      return this.inbox ? this.inbox.channel_type : '';
    },
    isAPIInbox() {
      return this.channelType === INBOX_TYPES.API;
    },
    isATwitterInbox() {
      return this.channelType === INBOX_TYPES.TWITTER;
    },
    isAFacebookInbox() {
      return this.channelType === INBOX_TYPES.FB;
    },
    isAnInstagramInbox() {
      return this.channelType === INBOX_TYPES.IG;
    },
    isKataPlatformInbox() {
      return this.channelType === INBOX_TYPES.KATA_PLATFORM;
    },
    isWhatsappChannel() {
      return this.channelType === INBOX_TYPES.WHATSAPP;
    },
    isAWebWidgetInbox() {
      return this.channelType === INBOX_TYPES.WEB;
    },
    isATwilioChannel() {
      return this.channelType === INBOX_TYPES.TWILIO;
    },
    isAnEmailChannel() {
      return this.channelType === INBOX_TYPES.EMAIL;
    },
    isAnKataPlatformChannel() {
      return this.channelType === INBOX_TYPES.KATA_PLATFORM;
    },
    isATwilioSMSChannel() {
      if (this.inbox) {
        const { phone_number: phoneNumber = '' } = this.inbox;
        return this.isATwilioChannel && !phoneNumber.startsWith('whatsapp');
      }
      return this.isATwilioChannel;
    },
    isATwilioWhatsappChannel() {
      if (this.inbox) {
        const { phone_number: phoneNumber = '' } = this.inbox;
        return this.isATwilioChannel && phoneNumber.startsWith('whatsapp');
      }
      return this.isATwilioChannel;
    },
  },
};
