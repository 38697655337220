<template>
  <modal
    :show.sync="show"
    :on-close="closeModal"
    class="modal-alert"
    :show-close-button="false"
  >
    <div class="row flex-center">
      <inline-svg
        :src="require('../../../assets/icons/ic_warning_round_red.svg')"
      />
    </div>
    <div class="row align-center-middle message">
      <p>{{ message }}</p>
    </div>
    <div class="row justify-space-between button-action">
      <woot-button variant="clear" @click="closeModal">
        {{ rejectText }}
      </woot-button>
      <woot-button variant="alert" @click="onConfirm">
        {{ confirmText }}
      </woot-button>
    </div>
  </modal>
</template>

<script>
import Modal from '../../Modal';
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'AlertModal',
  components: {
    Modal,
    InlineSvg,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    rejectText: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('on-close');
    },
    onConfirm() {
      this.$emit('on-confirm');
    },
  },
};
</script>

<style lang="scss">
.modal-alert {
  .modal-container {
    width: 328px;
    padding: 24px;
    font-size: 14px;
  }

  .message {
    padding: 16px 0;
  }

  .button-action {
    gap: 8px;

    button {
      flex: 1;
      justify-content: center;
    }
  }
}
</style>
