/* eslint-disable global-require */
export const bankHelper = [
  {
    name: 'BCA',
    alias: 'BCA',
    imagePath: require('../assets/images/bank/bca-logo.png'),
  },
  {
    name: 'BNI',
    alias: 'BNI',
    imagePath: require('../assets/images/bank/bni-logo.png'),
  },
  {
    name: 'BRI',
    alias: 'BRI',
    imagePath: require('../assets/images/bank/bri-logo.png'),
  },
  {
    name: 'MANDIRI',
    alias: 'MANDIRI',
    imagePath: require('../assets/images/bank/mandiri-logo.png'),
  },
  {
    name: 'PERMATA',
    alias: 'Permata',
    imagePath: require('../assets/images/bank/permata-logo.png'),
  },
  {
    name: 'BSI',
    alias: 'BSI',
    imagePath: require('../assets/images/bank/bsi-logo.png'),
  },
  /* {
    name: 'CIMB',
    alias: 'CIMB',
    imagePath: require('../assets/images/bank/cimb-logo.png'),
  }, */
  {
    name: 'SAHABAT_SAMPOERNA',
    alias: 'Sampoerna',
    imagePath: require('../assets/images/bank/sampoerna-logo.png'),
  },
];

export const creditCardHelper = [
  {
    name: 'JBC',
    alias: 'jbc',
    imagePath: require('../assets/images/credit-card/jcb.svg'),
  },
  {
    name: 'AMERICA-EXPRESS',
    alias: 'america-express',
    imagePath: require('../assets/images/credit-card/american-express.svg'),
  },
  {
    name: 'MASTER-CARD',
    alias: 'master-card',
    imagePath: require('../assets/images/credit-card/master-card.svg'),
  },
  {
    name: 'VISA',
    alias: 'visa',
    imagePath: require('../assets/images/credit-card/visa.svg'),
  },
];
