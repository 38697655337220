var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medium-12 column"},[_c('div',{staticClass:"row align-center"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"medium-8 column login"},[_c('div',{staticClass:"row align-center"},[_c('div',{staticClass:"small-8 medium-6 login__hero align-self-top"},[_c('img',{staticClass:"hero--logo",attrs:{"src":_vm.globalConfig.logo,"alt":_vm.globalConfig.installationName}}),_vm._v(" "),_c('h2',{staticClass:"hero__title",domProps:{"innerHTML":_vm._s(_vm.$t('REGISTER.REGISTER_ACCOUNT'))}})])]),_vm._v(" "),_c('div',{staticClass:"row align-center"},[_c('div',{staticClass:"small-8 medium-6 column"},[_c('div',{staticClass:"row align-center"},[_c('div',{staticClass:"small-12 medium-12 large-12 column"},[_c('form',{staticClass:"signup--box login-box",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('woot-input',{class:{ error: _vm.$v.credentials.email.$error },attrs:{"type":"email","icon":"ic_message.svg","placeholder":_vm.$t('REGISTER.EMAIL.PLACEHOLDER'),"error":_vm.$v.credentials.email.$error
                      ? _vm.$t('REGISTER.EMAIL.ERROR')
                      : ''},on:{"blur":_vm.$v.credentials.email.$touch},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"credentials.email"}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.credentials.password.$error },attrs:{"type":"password","icon":"ic_lock.svg","placeholder":_vm.$t('REGISTER.PASSWORD.PLACEHOLDER'),"error":_vm.$v.credentials.password.$error
                      ? _vm.$t('REGISTER.PASSWORD.ERROR')
                      : ''},on:{"blur":_vm.$v.credentials.password.$touch},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"credentials.password"}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.credentials.confirmPassword.$error },attrs:{"type":"password","icon":"ic_lock.svg","placeholder":_vm.$t('REGISTER.CONFIRM_PASSWORD.PLACEHOLDER'),"error":_vm.$v.credentials.confirmPassword.$error
                      ? _vm.$t('REGISTER.CONFIRM_PASSWORD.ERROR')
                      : ''},on:{"blur":_vm.$v.credentials.confirmPassword.$touch},model:{value:(_vm.credentials.confirmPassword),callback:function ($$v) {_vm.$set(_vm.credentials, "confirmPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"credentials.confirmPassword"}}),_vm._v(" "),_c('woot-submit-button',{attrs:{"disabled":_vm.isSignupInProgress,"button-text":_vm.$t('REGISTER.SUBMIT'),"loading":_vm.isSignupInProgress,"button-class":"expanded"}}),_vm._v(" "),_c('p',{staticClass:"accept--terms",domProps:{"innerHTML":_vm._s(_vm.termsLink)}})],1),_vm._v(" "),_c('div',{staticClass:"column signin--footer"},[_c('span',[_vm._v(_vm._s(_vm.$t('REGISTER.HAVE_AN_ACCOUNT')))]),_vm._v(" "),_c('router-link',{attrs:{"to":"/app/login"}},[_vm._v("\n                  "+_vm._s(_vm.useInstallationName(
                      _vm.$t('REGISTER.AUTH_TITLE'),
                      _vm.globalConfig.installationName
                    ))+"\n                ")])],1)])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medium-4 column hero-container"},[_c('img',{attrs:{"src":require("dashboard/assets/images/hero/hero-signup.svg")}})])}]

export { render, staticRenderFns }