<template>
  <span>
    <vac :end-time="endTime">
      <span
        slot="process"
        slot-scope="{ timeObj: { d: day, h: hours, m: minutes, s: seconds } }"
      >
        <span v-if="day > 0">{{ day }} days</span>
        <span>{{ hours }}:{{ minutes }}:{{ seconds }}</span>
      </span>
      <span slot="finish">Expired!</span>
    </vac>
  </span>
</template>

<script>
import Vue from 'vue';
import vueAwesomeCountdown from 'vue-awesome-countdown';

Vue.use(vueAwesomeCountdown, 'vac');
export default {
  name: 'Countdown',
  props: ['endTime'],
};
</script>

<style lang="scss" scoped></style>
