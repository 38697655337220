<template>
  <span v-if="messagetype === 'cards'">
    {{ $t(`CONVERSATION.UNSUPPORTED.CAROUSEL`) }}
  </span>
  <span v-else>
    {{ $t(`CONVERSATION.UNSUPPORTED.MEDIA`) }}
  </span>
</template>

<script>
export default {
  props: {
    messagetype: String,
  },
};
</script>
