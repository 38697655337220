<template>
  <form-wizard
    title=""
    subtitle=""
    :class="stepPosition"
    @on-complete="onSubmit"
  >
    <template slot="step">
      {{ '' }}
    </template>
    <wizard-step v-if="stepPosition === 'top'" />
    <slot />
    <template slot="footer" slot-scope="state">
      <div class="wizard">
        <div
          v-if="hideFirstStep ? state.activeTabIndex !== 0 : true"
          class="wizard-footer"
        >
          <div class="wizard-footer-left">
            <kata-button
              v-if="state.activeTabIndex > 0"
              variant="outline"
              @click.native="state.prevTab()"
            >
              <img src="~dashboard/assets/icons/ic_chevron_left.svg" />
              {{ backButton }}
            </kata-button>
            <slot v-if="state.activeTabIndex === 0" name="custom-prev-button" />
          </div>
          <div v-if="stepPosition === 'bottom'" class="wizard-footer-center">
            <wizard-step />
          </div>
          <div class="wizard-footer-right">
            <div v-if="hideNextStep ? false : true">
              <kata-button
                v-if="state.isLastStep && hideLastStep ? false : true"
                @click.native="
                  state.nextTab();
                  renderStep(state.activeTabIndex + 2);
                "
              >
                {{ state.isLastStep ? finishButton : nextButton }}
              </kata-button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </form-wizard>
</template>

<script>
import { FormWizard } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import KataButton from 'dashboard/components/buttons/Button';
import WizardStep from './WizardStep.vue';

export default {
  components: {
    KataButton,
    FormWizard,
    WizardStep,
  },
  props: {
    backButton: {
      type: String,
      default: '',
    },
    nextButton: {
      type: String,
      default: '',
    },
    finishButton: {
      type: String,
      default: '',
    },
    hideFirstStep: {
      type: Boolean,
      required: true,
      default: false,
    },
    hideLastStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideNextStep: {
      type: Boolean,
      required: false,
      default: false,
    },
    stepPosition: {
      type: String,
      default: 'top',
    },
  },
  methods: {
    onSubmit() {
      this.$emit('on-submit');
    },
    renderStep(step) {
      if (
        this.$router.history.current.name === 'settings_inboxes_page_channel'
      ) {
        switch (step) {
          case 2:
            this.$customGTMStep('IGDM-connect', 'Is connected to page', 3);
            break;
          case 3:
            this.$customGTMStep('IGDM-connect', 'enable access', 4);
            break;
          case 4:
            this.$customGTMStep('IGDM-connect', 'survey', 5);
            break;
          case 5:
            this.$customGTMStep('IGDM-connect', 'connect account', 6);
            break;
          default:
            break;
        }
      }
      if (this.$router.history.current.name === 'onboarding') {
        switch (step) {
          case 3:
            this.$customGTMStep('Self-Onboarding', 'yourself', 4);
            break;
          case 4:
            this.$customGTMStep('Self-Onboarding', 'welcome onboard', 5);
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard {
  &-footer {
    border-top: 1px solid var(--grey-03);
    margin: 40px 0;
    padding: 24px 0;
    &-left {
      button {
        img {
          margin-right: 12px;
        }
      }
    }
  }
}

.vue-form-wizard {
  &.bottom {
    .wizard {
      &-footer {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        padding: 24px 48px;
        &-center {
          text-align: center;
        }
        &-right {
          float: none;
          text-align: right;
        }
        & > div {
          flex: 1;
        }
      }
    }
  }
}
</style>
