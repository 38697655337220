/* eslint no-console: 0 */
/* global axios */
/* eslint no-undef: "error" */

import endPoints from './endPoints';

export default {
  getAgentBot(accountId, limit, page) {
    const urlData = endPoints('getAgentBot');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .get(urlData.url(accountId), {
          params: {
            limit,
            page,
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  createAgentBot(accountId, creds) {
    const urlData = endPoints('createAgentBot');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .post(urlData.url(accountId), creds)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  updateAgentBot(accountId, agentBotId, creds) {
    const urlData = endPoints('updateAgentBot');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .patch(urlData.url(accountId, agentBotId), creds)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  verifyWebhook(accountId, creds) {
    const urlData = endPoints('agentBotVerifyWebhook');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .post(urlData.url(accountId), creds)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  setAgentBotInboxes(accountId, agentBotId, creds) {
    const urlData = endPoints('setAgentBotInboxes');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .post(urlData.url(accountId, agentBotId), creds)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  setAgentBotStatus(accountId, agentBotId, creds) {
    const urlData = endPoints('setAgentBotStatus');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .post(urlData.url(accountId, agentBotId), creds)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  viewAgentBot(accountId, agentBotId) {
    const urlData = endPoints('viewAgentBot');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .get(urlData.url(accountId, agentBotId))
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
  getAgentBotInboxes(accountId) {
    const urlData = endPoints('getAgentBotInboxes');
    const fetchPromise = new Promise((resolve, reject) => {
      axios
        .get(urlData.url(accountId))
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return fetchPromise;
  },
};
