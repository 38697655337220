<template>
  <div class="container-box">
    <BillingContactPreference />
  </div>
</template>

<script>
import BillingContactPreference from '../../../../../modules/billing/components/preference/BillingContactPreference';

export default {
  name: 'Preferences',
  components: {
    BillingContactPreference,
  },
};
</script>
<style scoped lang="scss">
.container-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
