var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-body"},[_c('h2',{staticClass:"text-center billing-title"},[_vm._v("\n    "+_vm._s(_vm.$t('BILLING_CREDITS.BILLING_INFO.MODAL_TITLE'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"modal-split"},[_c('woot-input',{staticClass:"medium-6 column mr-8",class:{ error: _vm.$v.first_name.$error },attrs:{"name":"first_name","label":_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.FIRST_NAME.LABEL'),"error":_vm.$v.first_name.$error
          ? !_vm.$v.first_name.alphaNum
            ? _vm.$t(
                'BILLING_CREDITS.BILLING_INFO.FORM.FIRST_NAME.ERROR_ALPHANUMERIC'
              )
            : _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.FIRST_NAME.ERROR')
          : ''},on:{"blur":_vm.$v.first_name.$touch},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_vm._v(" "),_c('woot-input',{staticClass:"medium-6 column",class:{ error: _vm.$v.last_name.$error },attrs:{"name":"last_name","label":_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.LAST_NAME.LABEL'),"error":_vm.$v.last_name.$error
          ? !_vm.$v.last_name.alphaNum
            ? _vm.$t(
                'BILLING_CREDITS.BILLING_INFO.FORM.LAST_NAME.ERROR_ALPHANUMERIC'
              )
            : _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.LAST_NAME.ERROR')
          : ''},on:{"blur":_vm.$v.last_name.$touch},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}})],1),_vm._v(" "),_c('woot-input',{staticClass:"medium-12 column",class:{ error: _vm.$v.email.$error || !_vm.$v.email.email },attrs:{"name":"email","label":_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.EMAIL.LABEL'),"error":_vm.$v.email.$error
        ? !_vm.$v.email.email
          ? _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.EMAIL.ERROR_FORMAT')
          : _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.EMAIL.ERROR')
        : ''},on:{"blur":_vm.$v.email.$touch},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('woot-input',{staticClass:"medium-12 column",class:{ error: !_vm.$v.additional_emails.multipleEmail },attrs:{"name":"additional_emails","label":_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.ADDITIONAL_EMAILS.LABEL'),"error":!_vm.$v.additional_emails.multipleEmail
        ? _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.ADDITIONAL_EMAILS.ERROR')
        : ''},on:{"blur":_vm.$v.additional_emails.$touch},model:{value:(_vm.additional_emails),callback:function ($$v) {_vm.additional_emails=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"additional_emails"}}),_vm._v(" "),(_vm.$v.additional_emails.multipleEmail)?_c('span',{staticClass:"info"},[_c('inline-svg',{staticClass:"icon",attrs:{"src":require('../../../../../assets/icons/ic_info_purple.svg')}}),_vm._v(" "),_c('span',{staticClass:"info-content"},[_vm._v("\n      "+_vm._s(_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.ADDITIONAL_EMAILS.INFO')))])],1):_vm._e(),_vm._v(" "),_c('woot-input',{staticClass:"medium-12 column",class:{ error: _vm.$v.company_name.$error },attrs:{"name":"company_name","label":_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.COMPANY.LABEL'),"error":_vm.$v.company_name.$error
        ? !_vm.$v.company_name.alphaNum
          ? _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.COMPANY.ERROR_ALPHANUMERIC')
          : _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.COMPANY.ERRPR')
        : ''},on:{"blur":_vm.$v.company_name.$touch},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}}),_vm._v(" "),_c('label',[_vm._v(_vm._s(_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.ADDRESS.LABEL')))]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],class:{ errorarea: _vm.$v.address.$error },attrs:{"name":"address","rows":"5","type":"text","placeholder":"Enter your company address"},domProps:{"value":(_vm.address)},on:{"blur":_vm.$v.address.$touch,"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"message"},[_vm._v(_vm._s(_vm.$v.address.$error
        ? !_vm.$v.address.alphaNum
          ? _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.ADDRESS.ERROR_ALPHANUMERIC')
          : _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.ADDRESS.ERROR')
        : '')+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"modal-split"},[_c('div',{staticClass:"medium-6 column mr-8 select-state"},[_c('label',[_vm._v(_vm._s(_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.STATE.LABEL')))]),_vm._v(" "),_c('multiselect',{attrs:{"options":_vm.stateOption,"value":_vm.state},on:{"select":_vm.$v.state.$touch},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_vm._v(" "),_c('woot-input',{staticClass:"medium-6 column mr-8 column input-zip",class:{ error: _vm.$v.zip_code.$error },attrs:{"name":"zip_code","label":_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.ZIP_CODE.LABEL'),"error":_vm.$v.zip_code.$error
          ? !_vm.$v.zip_code.minLength || !_vm.$v.zip_code.maxLength
            ? _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.ZIP_CODE.ERROR_LENGTH')
            : _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.ZIP_CODE.ERROR')
          : ''},on:{"blur":_vm.$v.zip_code.$touch},model:{value:(_vm.zip_code),callback:function ($$v) {_vm.zip_code=$$v},expression:"zip_code"}})],1),_vm._v(" "),_c('woot-input',{staticClass:"medium-12 column",class:{ error: _vm.$v.npwp.$error },attrs:{"name":"npwp","type":"number","label":_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.NPWP.LABEL'),"error":_vm.$v.npwp.$error
        ? !_vm.$v.npwp.minLength || !_vm.$v.npwp.maxLength
          ? _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.NPWP.ERROR_LENGTH')
          : _vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.NPWP.ERROR')
        : ''},on:{"blur":_vm.$v.npwp.$touch},model:{value:(_vm.npwp),callback:function ($$v) {_vm.npwp=$$v},expression:"npwp"}}),_vm._v(" "),(!_vm.$v.npwp.$error && _vm.$v.npwp.numeric)?_c('span',{staticClass:"info"},[_c('inline-svg',{staticClass:"icon",attrs:{"src":require('../../../../../assets/icons/ic_info_purple.svg')}}),_vm._v(" "),_c('span',{staticClass:"info-content"},[_vm._v("\n      "+_vm._s(_vm.$t('BILLING_CREDITS.BILLING_INFO.FORM.NPWP.INFO')))])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-split"},[_c('woot-button',{attrs:{"variant":"clear","size":"expanded"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("\n      Cancel\n    ")]),_vm._v(" "),_c('woot-button',{attrs:{"variant":"primary","size":"expanded","disabled":_vm.$v.$invalid},on:{"click":_vm.submitBillingInfo}},[_vm._v("\n      Save Detail\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }