/* global axios */
import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  get(inboxId) {
    const params = inboxId ? `?inbox_id=${inboxId}` : '';
    return axios.get(`${this.url}${params}`);
  }
}

export default new Agents();
