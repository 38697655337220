<template>
  <div class="bank-va-container">
    <div class="title">
      Virtual account
    </div>
    <div class="description">
      Automatic verification without uploading the proof of payment.
    </div>
    <div>
      <BankList @change="value => $emit('changeBank', value)" />
    </div>
  </div>
</template>

<script>
import BankList from './BankList';
export default {
  name: 'BankVirtualAccounts',
  components: {
    BankList,
  },
};
</script>

<style lang="scss" scoped>
.bank-va-container {
  font-size: 14px;
  .title {
    font-weight: 700;
    margin-bottom: 8px;
  }
  .description {
    margin-bottom: 14px;
  }
}
</style>
