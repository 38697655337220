/* global axios */
import ApiClient from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axios.post(`${this.apiVersion}/accounts`, data);
  }

  switchAccount(accountId) {
    return axios.post(
      `${this.apiVersion}/accounts/${accountId}/update_active_at`,
    );
  }
}

export default new AccountAPI();
