<template>
  <div class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.BUSINESS_HOURS.TITLE')"
      :sub-title="$t('INBOX_MGMT.BUSINESS_HOURS.SUBTITLE')"
    >
      <form @submit.prevent="updateInbox">
        <label for="toggle-business-hours" class="toggle-input-wrap">
          <input
            v-model="isBusinessHoursEnabled"
            type="checkbox"
            name="toggle-business-hours"
          />
          {{ $t('INBOX_MGMT.BUSINESS_HOURS.TOGGLE_AVAILABILITY') }}
        </label>
        <p>{{ $t('INBOX_MGMT.BUSINESS_HOURS.TOGGLE_HELP') }}</p>
        <div v-if="isBusinessHoursEnabled" class="business-hours-wrap">
          <label
            class="unavailable-input-wrap"
            :class="{ error: $v.unavailableMessage.$error }"
          >
            {{ $t('INBOX_MGMT.BUSINESS_HOURS.UNAVAILABLE_MESSAGE_LABEL') }}
            <textarea
              v-model="unavailableMessage"
              type="text"
              @input="handleUnavailableMessage"
            />
            <div class="message-wrapper">
              <span v-if="$v.unavailableMessage.$error" class="message">
                {{ $t('INBOX_MGMT.BUSINESS_HOURS.ERROR_MESSAGE') }}
              </span>
              <span :class="charLengthClass">
                {{ characterLengthWarning }}
              </span>
            </div>
          </label>
          <div class="timezone-input-wrap">
            <label>
              {{ $t('INBOX_MGMT.BUSINESS_HOURS.TIMEZONE_LABEL') }}
            </label>
            <multiselect
              v-model="timeZone"
              :options="timeZones"
              deselect-label=""
              select-label=""
              selected-label=""
              track-by="value"
              label="label"
              :close-on-select="true"
              :placeholder="$t('INBOX_MGMT.BUSINESS_HOURS.DAY.CHOOSE')"
              :allow-empty="false"
            />
          </div>
          <label>
            {{ $t('INBOX_MGMT.BUSINESS_HOURS.WEEKLY_TITLE') }}
          </label>
          <business-day
            v-for="timeSlot in timeSlots"
            :key="timeSlot.day"
            :day-name="dayNames[timeSlot.day]"
            :time-slot="timeSlot"
            @update="data => onSlotUpdate(timeSlot.day, data)"
          />
        </div>
        <woot-submit-button
          :button-text="$t('INBOX_MGMT.BUSINESS_HOURS.UPDATE')"
          :loading="uiFlags.isUpdatingInbox"
          :disabled="hasError"
        />
      </form>
    </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import alertMixin from 'shared/mixins/alertMixin';
import SettingsSection from 'dashboard/components/SettingsSection';
import BusinessDay from './BusinessDay';
import {
  timeSlotParse,
  timeSlotTransform,
  defaultTimeSlot,
  timeZoneOptions,
} from '../helpers/businessHour';
import { MESSAGE_MAX_LENGTH } from 'shared/helpers/MessageTypeHelper';
import { CHAR_LENGTH_WARNING } from 'dashboard/components/widgets/WootWriter/constants';

const DEFAULT_TIMEZONE = {
  label: 'Jakarta',
  value: 'Asia/Jakarta',
};

export default {
  components: {
    SettingsSection,
    BusinessDay,
  },
  validations: {
    unavailableMessage: {
      required,
    },
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isBusinessHoursEnabled: false,
      unavailableMessage: this.$t(
        'INBOX_MGMT.BUSINESS_HOURS.UNAVAILABLE_MESSAGE_DEFAULT',
      ),
      timeZone: DEFAULT_TIMEZONE,
      dayNames: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
      timeSlots: [...defaultTimeSlot.sort((a, b) => a.day - b.day)],
      maxLength: MESSAGE_MAX_LENGTH.INSTAGRAM,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
    hasError() {
      if (!this.isBusinessHoursEnabled) return false;
      return this.timeSlots.filter(slot => slot.from && !slot.valid).length > 0;
    },
    timeZones() {
      return [...timeZoneOptions()];
    },
    unavailableMessageRemain() {
      return this.maxLength - this.unavailableMessage.length;
    },
    characterLengthWarning() {
      return this.unavailableMessageRemain < 0
        ? `${-this.unavailableMessageRemain} ${CHAR_LENGTH_WARNING.NEGATIVE}`
        : `${this.unavailableMessageRemain} ${CHAR_LENGTH_WARNING.UNDER_50}`;
    },
    charLengthClass() {
      return this.unavailableMessageRemain < 0
        ? 'message-error'
        : 'message-length';
    },
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const {
        working_hours_enabled: isEnabled = false,
        out_of_office_message: unavailableMessage = this.$t(
          'INBOX_MGMT.BUSINESS_HOURS.UNAVAILABLE_MESSAGE_DEFAULT',
        ),
        working_hours: timeSlots = [],
        timezone: timeZone,
      } = this.inbox;
      const slots = timeSlotParse(timeSlots).length
        ? timeSlotParse(timeSlots)
        : defaultTimeSlot;
      this.isBusinessHoursEnabled = isEnabled;
      this.unavailableMessage =
        unavailableMessage ||
        this.$t('INBOX_MGMT.BUSINESS_HOURS.UNAVAILABLE_MESSAGE_DEFAULT');
      this.timeSlots = slots;
      this.timeZone =
        this.timeZones.find(item => timeZone === item.value) ||
        DEFAULT_TIMEZONE;
    },
    onSlotUpdate(slotIndex, slotData) {
      this.timeSlots = this.timeSlots.map(item =>
        item.day === slotIndex ? slotData : item,
      );
    },
    async updateInbox() {
      if (!this.$v.$error) {
        try {
          const payload = {
            id: this.inbox.id,
            formData: false,
            working_hours_enabled: this.isBusinessHoursEnabled,
            out_of_office_message: this.unavailableMessage,
            working_hours: timeSlotTransform(this.timeSlots),
            timezone: this.timeZone.value,
            channel: {},
          };
          await this.$store.dispatch('inboxes/updateInbox', payload);
          this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        } catch (error) {
          this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        }
      }
    },
    handleUnavailableMessage() {
      this.$v.unavailableMessage.$touch();
      if (this.unavailableMessageRemain < 0) {
        this.unavailableMessage = this.unavailableMessage.substr(
          0,
          this.maxLength,
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.timezone-input-wrap {
  max-width: 60rem;

  &::v-deep .multiselect {
    margin-top: var(--space-small);
  }
}

.unavailable-input-wrap {
  max-width: 60rem;

  textarea {
    min-height: var(--space-jumbo);
    margin-top: var(--space-small);
    margin-bottom: var(--space-small);
  }

  .message-wrapper {
    display: flex;
    span {
      font-size: var(--font-size-small);
      margin: 0;
      margin-bottom: var(--space-medium);
      flex: 1;

      &:last-child {
        text-align: right;
      }
    }
  }
}

.business-hours-wrap {
  margin-bottom: var(--space-medium);
}
</style>
