<template>
  <div class="small-8 medium-6 column reset-password-container">
    <p class="email-sent" v-html="emailSent" />
    <div class="description-password-success" v-html="description" />
    <a @click="setIsUnfinished">{{
      $t('RESET_PASSWORD.RESET_SUCCESS.RE_ENTER_EMAIL')
    }}</a>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  computed: {
    description() {
      return this.$t('RESET_PASSWORD.RESET_SUCCESS.DESCRIPTION');
    },
    emailSent() {
      return this.$t('RESET_PASSWORD.RESET_SUCCESS.EMAIL_SENT', {
        email: this.email,
      });
    },
  },
  methods: {
    setIsUnfinished() {
      this.$emit('set-is-unfinished', false);
    },
  },
};
</script>

<style scoped>
.reset-password-container {
  font-size: 14px;
  line-height: 24px;
}

.email-sent {
  margin-top: 24px;
}

.description-password-success {
  margin-top: 16px;
  margin-bottom: 24px;
}

.description-password-success >>> h5 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
  font-weight: 600;
}

.description-password-success >>> p {
  margin: 8px 0;
}
</style>
