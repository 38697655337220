<template>
  <div class="instagram-final-setup__wrapper">
    <div class="instagram-final-setup__wrapper-content">
      <p>{{ $t('NEW_CHANNEL.FINAL_STEP.DESCRIPTION') }}</p>
      <span class="title">{{
        $t('NEW_CHANNEL.FINAL_STEP.FACEBOOK_PAGES.SELECT')
      }}</span>
      <form class="row" @submit.prevent="finishSetup()">
        <div class="small-12 form-group">
          <div class="row">
            <div v-if="!isFetchingPages" class="small-12 form-group">
              <div class="row">
                <div
                  class="small-12 align-self-top dropdown"
                  :class="$v.form.instagram_id.$error && 'error'"
                >
                  <dropdown
                    :options="getPagesOptions"
                    :placeholder="
                      $t('NEW_CHANNEL.FINAL_STEP.FACEBOOK_PAGES.CHOOSE')
                    "
                    @click="onSelectDropdown"
                  />
                  <span v-if="$v.form.instagram_id.$error" class="message">
                    {{ $t('NEW_CHANNEL.FINAL_STEP.FACEBOOK_PAGES.ERROR') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="small-12 form-group">
              <woot-input
                v-model.trim="form.inbox_name"
                :label="$t('NEW_CHANNEL.FINAL_STEP.INBOX_NAME.LABEL')"
                type="text"
                :class="{ error: $v.form.inbox_name.$error }"
                :placeholder="$t('NEW_CHANNEL.FINAL_STEP.INBOX_NAME.LABEL')"
                :error="
                  $v.form.inbox_name.$error
                    ? $t('NEW_CHANNEL.FINAL_STEP.INBOX_NAME.ERROR')
                    : ''
                "
                @blur="
                  () => {
                    $customGTM(
                      'Event',
                      `IGDM - Create Inbox - Inbox Details - Inbox Name`,
                      'Text',
                      `Create Inbox - Inbox Details - Inbox Name`,
                    );
                    $v.form.inbox_name.$touch;
                  }
                "
              />
            </div>
            <div class="small-12 form-group">
              <button
                class="button nice full-width d-block"
                data-testid="submit_button"
                type="submit"
                :disabled="
                  $v.form.instagram_id.$invalid ||
                  $v.form.inbox_name.$invalid ||
                  isLoading
                "
              >
                {{ $t('NEW_CHANNEL.FINAL_STEP.CREATE_INBOX') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <img src="~dashboard/assets/images/hero/instagram-second-step.png" />
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import Dropdown from 'dashboard/components/kata/dropdown/Dropdown';
import alertMixin from 'shared/mixins/alertMixin';
import accountMixin from 'dashboard/mixins/account';
import ChannelApi from 'dashboard/api/channels';

export default {
  components: {
    Dropdown,
  },
  mixins: [accountMixin, alertMixin],
  props: {
    token: {
      type: String,
      default: '',
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {
        instagram_id: '',
        inbox_name: '',
        page_access_token: '',
      },
      pages: [],
      isFetchingPages: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    getPagesOptions() {
      return this.pages.map(item => ({
        label: item.name,
        value: item,
      }));
    },
  },
  validations: {
    form: {
      instagram_id: {
        required,
      },
      inbox_name: {
        required,
        // maxLength: maxLength(50),
      },
    },
  },
  created() {
    this.fetchPages();
  },
  methods: {
    async fetchPages() {
      this.isFetchingPages = true;
      try {
        const response = await ChannelApi.fetchInstagramPages(
          this.token,
          this.accountId,
        );
        const {
          data: { data },
        } = response;
        this.pages = data.page_details;
      } finally {
        this.isFetchingPages = false;
      }
    },
    onSelectDropdown(page) {
      this.$customGTM(
        'Event',
        `IGDM - Create Inbox - Inbox Details - Choose Page`,
        'Drop Down',
        `Create Inbox - Inbox Details - Choose Page`,
      );
      this.form.instagram_id = page.instagram_business_account.id;
      this.form.page_access_token = page.access_token;
    },
    finishSetup() {
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit('on-submit', this.form);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.instagram {
  &-final-setup {
    &__wrapper {
      display: flex;
      img {
        flex: 1;
        height: 100%;
      }
      &-content {
        flex: 1;
        text-align: left;
        p {
          margin-top: 46px;
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 24px;
          color: var(--grey-08);
        }
        span.title {
          display: block;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          color: var(--grey-09);
          margin-bottom: 24px;
        }
        button {
          display: block;
        }
      }
    }
    .dropdown {
      .message {
        margin-top: 5px;
      }
    }
  }
}
</style>
