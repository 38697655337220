<template>
  <BillingCard v-if="bank">
    <div class="payment-detail-container row flex-dir-column">
      <div class="row justify-space-between align-middle">
        <div class="details">
          <div class="name">
            Transferred to {{ bank.alias }} Virtual Account
          </div>
          <div class="description">
            {{ paidAt | moment('D MMMM YYYY, HH:mm:ss') }}
          </div>
        </div>
        <div><img class="badge-plan" :src="bank.imagePath" alt="bank" /></div>
      </div>
    </div>
  </BillingCard>
</template>

<script>
import BillingCard from '../../BillingCard';

export default {
  name: 'BankPaymentDetailPaid',
  components: { BillingCard },
  props: {
    bank: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    paidAt: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-detail-container {
  .details {
    .name {
      font-weight: 700;
      margin-bottom: 4px;
    }
  }
}
</style>
