<template>
  <div class="steps">
    <Step
      v-for="(step, index) in steps"
      :key="index"
      :active="currentIndex === index"
    >
      {{ step }}
    </Step>
  </div>
</template>

<script>
import Step from './Step';
export default {
  name: 'Stepper',
  components: { Step },
  props: {
    steps: {
      default: () => [],
      type: Array,
    },
    currentIndex: {
      default: 0,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  gap: 4px;
  flex: 1;
}
</style>
