<template>
  <transition-group
    name="toast-fade"
    tag="div"
    class="ui-snackbar-container"
    :duration="{ enter: 500, leave: 800 }"
  >
    <snackbar
      v-for="(message, index) in messages"
      :key="message.key"
      :message="message.message"
      :duration="duration"
      :index="index"
      @click.native="removeMessageClick(index)"
    />
  </transition-group>
</template>

<script>
import Snackbar from './Snackbar';
import constant from '../constants';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  components: {
    Snackbar,
  },
  props: {
    duration: {
      type: Number,
      default: constant.NOTIFICATON.DURATION,
    },
  },
  data() {
    return {
      messages: [],
    };
  },
  created() {
    bus.$on(BUS_EVENTS.CLOSE_NOTIFICATION, () => {
      this.removeMessage();
    });
  },
  mounted() {
    bus.$on('newToastMessage', message => {
      this.messages.push({ key: new Date().getTime(), message });
    });
  },
  methods: {
    removeMessage() {
      const latestIndex = this.messages.length - 1;
      this.onRemoveMessage(latestIndex);
    },
    removeMessageClick(index) {
      this.onRemoveMessage(index);
    },
    onRemoveMessage(index) {
      this.messages = this.messages.filter((_, idx) => idx !== index);
    },
  },
};
</script>
