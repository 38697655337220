<template>
  <div class="message--container">
    <li
      v-if="
        hasAttachments ||
        data.content ||
        isInstagramChannel ||
        isMediaUrl ||
        isLocation
      "
      :class="alignBubble"
      @mouseenter="isHoveredInstagramAction = true"
      @mouseleave="isHoveredInstagramAction = false"
    >
      <div :class="wrapClass" class="relative">
        <bubble-comment
          v-if="
            isInstagramChannel &&
            data.content_attributes &&
            data.content_attributes.in_reply_to
          "
          :data="data"
          :comment-id="data.content_attributes.in_reply_to"
          :conversation-id="data.conversation_id"
        />

        <div v-tooltip.top-start="sentByMessage" :class="bubbleClass">
          <div v-if="!isPending && hasAttachments">
            <div v-for="attachment in data.attachments" :key="attachment.id">
              <bubble-image
                v-if="attachment.file_type === 'image'"
                :url="attachment.data_url"
                :readable-time="readableTime"
              />
              <audio v-else-if="attachment.file_type === 'audio'" controls>
                <source :src="attachment.data_url" />
              </audio>
              <bubble-file
                v-else
                :url="attachment.data_url"
                :readable-time="readableTime"
              />
            </div>
          </div>
          <div v-else-if="isMediaUrl && data.content_attributes.items">
            <div v-for="item in data.content_attributes.items" :key="item.url">
              <bubble-image
                v-if="item.type === undefined || item.type === 'image'"
                :url="item.url"
                :readable-time="readableTime"
              />
            </div>
          </div>
          <div v-else-if="isLocation && data.content_attributes.items">
            <bubble-location
              :longitude="data.content_attributes.items[0].longitude"
              :latitude="data.content_attributes.items[0].latitude"
              :name="data.content_attributes.items[0].name"
              :address="data.content_attributes.items[0].address"
              :readable-time="readableTime"
            />
          </div>
          <div
            v-else-if="
              isInstagramChannel &&
              (!data.content ||
                (data.content_attributes &&
                  data.content_attributes.is_story_reply))
            "
          >
            <instagram-bubble
              :conversation-id="data.conversation_id"
              :source-id="data.source_id"
              :readable-time="readableTime"
            />
          </div>
          <bubble-text
            v-if="
              isLocation &&
              (data.content_attributes.items[0].name ||
                data.content_attributes.items[0].address)
            "
            :message="addressMessage"
            :is-email="isEmailContentType"
            :readable-time="readableTime"
          />
          <bubble-text
            v-else-if="data.content"
            :message="message"
            :is-email="isEmailContentType"
            :readable-time="readableTime"
          />
          <span
            v-if="isPending && hasAttachments"
            class="chat-bubble has-attachment agent"
          >
            {{ $t('CONVERSATION.UPLOADING_ATTACHMENTS') }}
          </span>
          <bubble-actions
            :id="data.id"
            :sender="data.sender"
            :is-a-tweet="isATweet"
            :is-email="isEmailContentType"
            :is-private="data.private"
            :is-wa-channel="isWAChannel"
            :is-wa-sent="isWASent"
            :is-wa-delivered="isWADelivered"
            :is-wa-read="isWARead"
            :message-type="data.message_type"
            :readable-time="readableTime"
            :source-id="data.source_id"
          />
        </div>

        <spinner v-if="isPending" size="tiny" />

        <bubble-instagram-actions
          v-if="isInstagramChannel"
          :id="data.id"
          :sender="data.sender"
          :message-type="data.message_type"
          :source-id="data.source_id"
          :data="data"
          :is-hovered-action="isHoveredInstagramAction"
          :additional-attributes="additionalAttributes"
        />
        <a
          v-if="isATweet && isIncoming && sender"
          class="sender--info"
          :href="twitterProfileLink"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <woot-thumbnail
            :src="sender.thumbnail"
            :username="sender.name"
            size="16px"
          />
          <div class="sender--available-name">
            {{ sender.name }}
          </div>
        </a>
      </div>
    </li>
    <li
      v-if="isInstagramChannel && isQuickActionReplied && getQuickReply"
      class="quick-items left"
    >
      <bubble-setting
        v-for="item in getQuickReply"
        :key="item"
        class="quick-contents"
        :read-only="true"
      >
        {{ item }}
      </bubble-setting>
    </li>
  </div>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import timeMixin from '../../../mixins/time';
import BubbleText from './bubble/Text';
import BubbleImage from './bubble/Image';
import BubbleLocation from './bubble/Location';
import InstagramBubble from './bubble/instagram/InstagramBubble';
import BubbleFile from './bubble/File';
import Spinner from 'shared/components/Spinner';

import contentTypeMixin from 'shared/mixins/contentTypeMixin';
import BubbleActions from './bubble/Actions';
import BubbleInstagramActions from './bubble/instagram/Actions';
import BubbleComment from './comment/BubbleComment';

import {
  MESSAGE_TYPE,
  MESSAGE_STATUS,
  WA_MESSAGE_STATUS,
} from 'shared/constants/messages';
import { generateBotMessageContent } from './helpers/botMessageContentHelper';
import BubbleSetting from '../../../routes/dashboard/settings/inbox/components/BubbleSetting.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BubbleActions,
    BubbleText,
    BubbleImage,
    BubbleLocation,
    BubbleFile,
    InstagramBubble,
    BubbleInstagramActions,
    Spinner,
    BubbleComment,
    BubbleSetting,
  },
  mixins: [timeMixin, messageFormatterMixin, contentTypeMixin],
  props: {
    channel: {
      type: String,
      required: false,
    },
    additionalAttributes: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      required: true,
    },
    isATweet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovered: false,
      isHoveredInstagramAction: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    message() {
      const botMessageContent = generateBotMessageContent(
        this.contentType,
        this.contentAttributes,
        this.$t('CONVERSATION.NO_RESPONSE'),
      );

      const {
        email: {
          html_content: { full: fullHTMLContent, reply: replyHTMLContent } = {},
        } = {},
      } = this.contentAttributes;

      if ((replyHTMLContent || fullHTMLContent) && this.isIncoming) {
        let parsedContent = new DOMParser().parseFromString(
          replyHTMLContent || fullHTMLContent || '',
          'text/html',
        );
        if (!parsedContent.getElementsByTagName('parsererror').length) {
          return parsedContent.body.innerHTML;
        }
      }
      return (
        this.formatMessage(
          this.data.content === 'carousel message'
            ? this.$t('CONVERSATION.UNSUPPORTED.CAROUSEL')
            : this.data.content,
          this.isATweet,
        ) + botMessageContent
      );
    },
    addressMessage() {
      const name = this.data.content_attributes.items[0].name;
      const address = this.data.content_attributes.items[0].address;
      return name + '-' + address;
    },
    contentAttributes() {
      return this.data.content_attributes || {};
    },
    sender() {
      return this.data.sender || {};
    },
    contentType() {
      const {
        data: { content_type: contentType },
      } = this;
      return contentType;
    },
    twitterProfileLink() {
      const additionalAttributes = this.sender.additional_attributes || {};
      const { screen_name: screenName } = additionalAttributes;
      return `https://twitter.com/${screenName}`;
    },
    alignBubble() {
      const { message_type: messageType } = this.data;
      if (messageType === MESSAGE_TYPE.ACTIVITY) {
        return 'center';
      }
      return !messageType ? 'left' : 'right';
    },
    readableTime() {
      return this.messageStamp(
        this.contentAttributes.external_created_at || this.data.created_at,
        'LLL d, h:mm a',
      );
    },
    isBubble() {
      return [0, 1, 3].includes(this.data.message_type);
    },
    isIncoming() {
      return this.data.message_type === MESSAGE_TYPE.INCOMING;
    },
    isOutgoing() {
      return this.data.message_type === MESSAGE_TYPE.OUTGOING;
    },
    isInstagramChannel() {
      return this.channel === 'Channel::InstagramPage';
    },
    isWAChannel() {
      return this.channel === 'Channel::Whatsapp';
    },
    isWASent() {
      return this.data.status === WA_MESSAGE_STATUS.SENT;
    },
    isWADelivered() {
      return this.data.status === WA_MESSAGE_STATUS.DELIVERED;
    },
    isWARead() {
      return this.data.status === WA_MESSAGE_STATUS.READ;
    },
    isMediaUrl() {
      return this.data.content_type === 'media_url';
    },
    isLocation() {
      return this.data.content_type === 'location';
    },
    getQuickReply() {
      return this.data.content_attributes.instagram_quick_replies;
    },
    hasAttachments() {
      return !!(this.data.attachments && this.data.attachments.length > 0);
    },
    hasImageAttachment() {
      if (this.hasAttachments && this.data.attachments.length > 0) {
        const { attachments = [{}] } = this.data;
        const { file_type: fileType } = attachments[0];
        return fileType === 'image';
      }
      return false;
    },
    hasText() {
      return !!this.data.content;
    },
    sentByMessage() {
      const { sender } = this;

      return this.data.message_type === 1 && !this.isHovered && sender
        ? {
            content: `${this.$t('CONVERSATION.SENT_BY')} ${sender.name}`,
            classes: 'top',
          }
        : false;
    },
    wrapClass() {
      return {
        'wa-container': this.isWAChannel,
        wrap: this.isBubble,
        'activity-wrap': !this.isBubble,
        'is-pending': !this.isWAChannel && this.isPending,
      };
    },
    bubbleClass() {
      return {
        bubble: this.isBubble,
        'is-private': this.data.private,
        'is-image': this.hasImageAttachment,
        'is-text': this.hasText,
        'is-from-bot': this.isSentByBot,
        'wa-container':
          this.isWAChannel && this.isOutgoing && !this.isSentByBot,
      };
    },
    isPending() {
      return this.data.status === MESSAGE_STATUS.PROGRESS;
    },
    isSentByBot() {
      if (this.isPending) return false;
      return !this.sender.type || this.sender.type === 'agent_bot';
    },
    isQuickActionReplied() {
      if (
        this.data.content_attributes &&
        this.data.content_attributes.instagram_quick_replies
      ) {
        const filter = this.currentChat.messages.findIndex(
          c => c.id === this.data.id,
        );
        const latestClient = this.currentChat.messages.findIndex(
          (item, i) =>
            item.message_type === MESSAGE_TYPE.INCOMING && i > filter,
        );
        return filter > latestClient;
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
.wrap {
  > .bubble {
    &.is-image {
      padding: 0;
      overflow: hidden;

      .image {
        max-width: 32rem;
        padding: var(--space-micro);

        > img {
          border-radius: var(--border-radius-medium);
        }
      }
    }

    &.is-image.is-text > .message-text__wrap {
      max-width: 32rem;
      padding: var(--space-small) var(--space-normal);
    }

    &.is-private .file.message-text__wrap {
      .ion-document-text {
        color: var(--text-color);
      }
      .text-block-title {
        color: #3c4858;
      }
      .download.button {
        color: var(--text-color);
      }
    }

    &.is-private.is-text > .message-text__wrap .link {
      color: var(--w-700);
    }
    &.is-private.is-text > .message-text__wrap .prosemirror-mention-node {
      font-weight: var(--font-weight-black);
      background: none;
      border-radius: var(--border-radius-small);
      padding: 0;
      color: var(--color-body);
      text-decoration: underline;
    }

    &.is-from-bot {
      background: var(--bubble-violet);
      border: 1px solid var(--bubble-violet-border);
      color: var(--greydark-02);
    }

    .message-text--metadata .time {
      color: var(--time-color);
    }
  }

  &.is-pending {
    position: relative;
    opacity: 0.8;

    .spinner {
      position: absolute;
      bottom: var(--space-smaller);
      right: var(--space-smaller);
    }

    > .is-image.is-text.bubble > .message-text__wrap {
      padding: 0;
    }
  }
}

.sender--info {
  align-items: center;
  color: var(--b-700);
  display: inline-flex;
  padding: var(--space-smaller) 0;

  .sender--available-name {
    font-size: var(--font-size-mini);
    margin-left: var(--space-smaller);
  }
}

.quick-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.quick-contents {
  background: white;
  margin-left: 1.6rem;
}

.message--container {
  margin: 0;
}
</style>
