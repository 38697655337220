<template>
  <div class="thumbnail-channel-wrapper">
    <img
      v-if="channelType === 'whatsapp'"
      id="badge"
      class="source-badge"
      :style="badgeStyle"
      src="~dashboard/assets/images/channels/whatsapp.png"
    />
    <img
      v-if="channelType === 'line'"
      id="badge"
      class="source-badge"
      :style="badgeStyle"
      src="~dashboard/assets/images/channels/line.png"
    />
    <img
      v-if="channelType === 'telegram'"
      id="badge"
      class="source-badge"
      :style="badgeStyle"
      src="~dashboard/assets/images/channels/telegram.png"
    />
    <img
      v-if="channelType === 'fbmessenger'"
      id="badge"
      class="source-badge"
      :style="badgeStyle"
      src="~dashboard/assets/images/channels/facebook_messenger.png"
    />
  </div>
</template>
<script>
export default {
  props: {
    channelType: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '40px',
    },
  },
  computed: {
    avatarSize() {
      return Number(this.size.replace(/\D+/g, ''));
    },
    badgeStyle() {
      const badgeSize = `${this.avatarSize / 2 - 2}px`;
      return { width: badgeSize, height: badgeSize };
    },
  },
};
</script>
<style lang="scss" scoped>
.thumbnail-channel-wrapper {
  bottom: -0.2rem;
  position: absolute;
  right: 0;
}
</style>
