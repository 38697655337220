<!-- eslint-disable prettier/prettier -->
<template>
  <div class="column box-content billing-credits">
    <div class="banner-wrapper">
      <banner-trial-warning
        v-if="
          getSubscriptionNotify.isTrial && getSubscriptionNotify.isInDueDays
        "
      />
      <banner-plan-warning
        v-if="
          !getSubscriptionNotify.isTrial &&
            (getSubscriptionNotify.isExpired ||
              getSubscriptionNotify.isInDueDays)
        "
      />
      <banner-mau-warning
        v-if="
          getCurrentSubscription.plan !== 'Trial' &&
            getSubscriptionNotify.isMauLimit
        "
      />
    </div>
    <div class="menus">
      <div class="primary button-group no-gaps custom-button-group">
        <button
          class="button"
          :class="selectedTab === 'subscriptions' && 'active'"
          @click="changeTab('subscriptions')"
        >
          Subscriptions
        </button>
        <button
          class="button"
          :class="selectedTab === 'invoices' && 'active'"
          @click="changeTab('invoices')"
        >
          Invoices
        </button>
        <button
          class="button"
          :class="selectedTab === 'preferences' && 'active'"
          @click="changeTab('preferences')"
        >
          Preferences
        </button>
      </div>
    </div>

    <div
      v-if="selectedTab === 'subscriptions'"
      class="row flex-dir-column card-container"
    >
      <woot-loading-state v-if="getSubscriptionGetUIFlags.isFetching" />
      <template v-else>
        <SubscriptionCardPlan />
        <SubscriptionCardMau
          v-if="
            !getSubscriptionNotify.isTrial && !getSubscriptionNotify.isExpired
          "
        />
      </template>
    </div>

    <div
      v-if="selectedTab === 'invoices'"
      class="row flex-dir-column card-container"
    >
      <invoice-list />
    </div>

    <div
      v-if="selectedTab === 'preferences'"
      class="row flex-dir-column card-container"
    >
      <Preferences />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SubscriptionCardMau from '../../../../modules/billing/components/subscription/SubscriptionCardMau';
import SubscriptionCardPlan from '../../../../modules/billing/components/subscription/SubscriptionCardPlan';
import BannerTrialWarning from '../../BannerTrialWarning.vue';
import Preferences from './preferences/Preferences.vue';
import InvoiceList from './invoices/InvoiceList.vue';
import BannerPlanWarning from '../../BannerPlanWarning.vue';
import BannerMauWarning from '../../BannerMauWarning.vue';

export default {
  name: 'BillingCredits',
  components: {
    SubscriptionCardMau,
    SubscriptionCardPlan,
    BannerTrialWarning,
    Preferences,
    InvoiceList,
    BannerPlanWarning,
    BannerMauWarning,
  },
  data() {
    return {
      trialStatus: {},
      selectedTab: 'subscriptions',
    };
  },
  computed: {
    ...mapGetters({
      isSubscriptionActive: 'subscription/isSubscriptionActive',
      getCurrentSubscription: 'subscription/getCurrentSubscription',
      getSubscriptionNotify: 'subscription/getSubscriptionNotify',
      getSubscriptionGetUIFlags: 'subscription/getUIFlags',
    }),
  },
  activated() {
    this.$store.dispatch('subscription/get');
  },
  methods: {
    changeTab(tab) {
      this.selectedTab = tab;
      if (tab === 'subscriptions') {
        this.$store.dispatch('subscription/get');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~widget/assets/scss/variables';

.box-content {
  overflow: auto;

  .badge-plan {
    margin-bottom: 8px;
  }

  &.billing-credits {
    padding: 60px 48px;

    .menus {
      margin-bottom: 36px;

      .custom-button-group {
        .button {
          &.active {
            color: #006fe6;
            border-color: #006fe6;
          }

          color: #6a7a8a;
          background-color: #f5f6f8;
        }
      }
    }
  }

  .card-container {
    row-gap: 24px;
  }
  .banner-wrapper {
    margin-top: -40px;
  }
}
</style>
