<template>
  <div class="getting-started__confirmation">
    <img src="~dashboard/assets/icons/ic_warning.svg" />
    <span
      v-html="$t('GETTING_STARTED.WELCOME.CONFIRMATION', { email: email })"
    ></span>
    &nbsp;
    <a v-if="!isLoading" @click="onResendEmail">Resend email</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import GetStarted from 'dashboard/api/getStarted';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    email() {
      return this.currentUser.email;
    },
  },
  mounted() {
    this.$customGTM(
      'Event',
      'Getting Started - Verify Email - Resend',
      'Button',
      'Resend Email',
    );
  },

  methods: {
    async onResendEmail() {
      this.isLoading = true;
      try {
        await GetStarted.sendEmailConfirmation();
        this.showAlert(
          this.$t('GETTING_STARTED.API.SUCCESS', {
            email: this.email,
          }),
        );
      } catch (error) {
        let errorMessage = this.$t('GETTING_STARTED.API.ERROR_MESSAGE');
        if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.showAlert(errorMessage);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.getting {
  &-started {
    &__confirmation {
      display: flex;
      align-items: center;
      background: var(--yellow-01);
      border: 1px solid var(--yellow-06);
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 16px 18px;
      font-size: 14px;
      line-height: 24px;
      img {
        margin-right: 20px;
      }
      a {
        text-decoration: underline;
      }
      b,
      strong {
        margin-left: 4px;
      }
    }
  }
}
</style>
