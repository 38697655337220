<template>
  <div class="small-8 medium-6 column edit-password-container">
    <div class="edit-password-success">
      <img src="~dashboard/assets/icons/ic_tick_rounded.svg" alt="" />
      {{ $t('SET_NEW_PASSWORD.PASSWORD_UPDATED_SUCCESS') }}
    </div>
    <div class="column signin--footer">
      <span>{{ $t('SET_NEW_PASSWORD.GET_BACK') }}</span>
      <router-link to="/app/login">
        {{
          useInstallationName(
            $t('REGISTER.AUTH_TITLE'),
            globalConfig.installationName,
          )
        }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { DEFAULT_REDIRECT_URL } from '../../../constants';

export default {
  mixins: [globalConfigMixin],
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  created() {
    setTimeout(() => {
      window.location = DEFAULT_REDIRECT_URL;
    }, 2000);
  },
};
</script>

<style scoped lang="scss">
.edit-password-success {
  margin: 24px 0;
  font-size: 1rem;
  background: var(--green-01);
  color: var(--grey-08);
  padding: 16px 18px;
  display: flex;
  align-items: center;

  img {
    margin-right: 26px;
  }
}
</style>
