import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import InboxesAPI from '../../api/inboxes';
import QuickReplyAPI from '../../api/inbox/quickReplies';
import WebChannel from '../../api/channel/webChannel';
import FBChannel from '../../api/channel/fbChannel';
import TwilioChannel from '../../api/channel/twilioChannel';
import InstagramChannel from '../../api/channel/instagramChannel';
import WhatsappChannel from '../../api/channel/whatsappChannel';
import moment from 'moment';

const buildInboxData = inboxParams => {
  const formData = new FormData();
  const { channel = {}, ...inboxProperties } = inboxParams;
  Object.keys(inboxProperties).forEach(key => {
    formData.append(key, inboxProperties[key]);
  });
  const { selectedFeatureFlags = [], ...channelParams } = channel;
  if (selectedFeatureFlags.length) {
    selectedFeatureFlags.forEach(featureFlag => {
      formData.append(`channel[selected_feature_flags][]`, featureFlag);
    });
  } else {
    formData.append('channel[selected_feature_flags][]', '');
  }
  Object.keys(channelParams).forEach(key => {
    formData.append(`channel[${key}]`, channel[key]);
  });
  return formData;
};

const getSelectedInboxes = ({ records, id }) =>
  records.filter(inbox => inbox.id === id);

export const state = {
  quickReply: [],
  records: [],
  credits: {
    whatsapp_credit: {},
  },
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isUpdating: false,
    isUpdatingAutoAssignment: false,
    isDeleting: false,
  },
};

export const getters = {
  getQuickReply($state) {
    return $state.quickReply;
  },
  getInboxes($state) {
    return $state.records;
  },
  getNewConversationInboxes($state) {
    return $state.records.filter(inbox => {
      const {
        channel_type: channelType,
        phone_number: phoneNumber = '',
      } = inbox;

      const isEmailChannel = channelType === INBOX_TYPES.EMAIL;
      const isSmsChannel =
        channelType === INBOX_TYPES.TWILIO &&
        phoneNumber.startsWith('whatsapp');
      return isEmailChannel || isSmsChannel;
    });
  },
  getInbox: $state => inboxId => {
    const [inbox] = $state.records.filter(
      record => record.id === Number(inboxId),
    );
    return inbox || {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getWebsiteInboxes($state) {
    return $state.records.filter(
      item => item.channel_type === 'Channel::WebWidget',
    );
  },
  getInboxesWhatsappCredit($state) {
    const {
      credits: {
        whatsapp_credit: {
          credit = 0,
          free_credit = 0,
          is_conversation_expired = false,
        },
        whatsapp_credit,
      },
    } = $state;
    const limit = 2500;
    const expiredDate = moment(whatsapp_credit.expired);
    const dateNow = moment();
    const daysRemaining = expiredDate.diff(dateNow, 'days');
    return {
      ...whatsapp_credit,
      isWarned:
        daysRemaining < 0 ||
        (credit < limit && free_credit <= 0 && is_conversation_expired),
    };
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    try {
      const response = await InboxesAPI.get();
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_INBOXES, response.data.payload);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  createChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createWebsiteChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(buildInboxData(params));
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createTwilioChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await TwilioChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createInstagramChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await InstagramChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error.response.data.message);
    }
  },
  createWhatsappChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WhatsappChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw error;
    }
  },
  createFBChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await FBChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  updateInbox: async ({ commit }, { id, formData = true, ...inboxParams }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, {
      isUpdatingAutoAssignment: true,
    });
    try {
      const response = await InboxesAPI.update(
        id,
        formData ? buildInboxData(inboxParams) : inboxParams,
      );
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, {
        isUpdatingAutoAssignment: false,
      });
      throw new Error(error);
    }
  },
  delete: async ({ commit }, inboxId) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: true });
    try {
      await InboxesAPI.delete(inboxId);
      commit(types.default.DELETE_INBOXES, inboxId);
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
      throw new Error(error);
    }
  },
  reauthorizeFacebookPage: async ({ commit }, params) => {
    try {
      const response = await FBChannel.reauthorizeFacebookPage(params);
      commit(types.default.EDIT_INBOXES, response.data);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  getInstagramCommentActivation: async ({ commit }, { inboxId }) => {
    try {
      const response = await InboxesAPI.getCommentActivation(inboxId);
      commit(types.default.GET_INSTAGRAM_COMMENT, {
        id: inboxId,
        data: response.data.comment_enabled,
      });
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  handleInstagramCommentActivation: async ({ commit }, { inboxId, value }) => {
    try {
      const response = await InboxesAPI.setCommentActivation({
        inboxId,
        data: value,
      });
      commit(types.default.ACTIVATION_INSTAGRAM_COMMENT, response);
    } catch (error) {
      throw new Error(error);
    }
  },
  getQuickReplyConversationAction: async ({ commit }, inboxId) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    try {
      const response = await QuickReplyAPI.get(inboxId);
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_QUICK_REPLIES_CONVERSATION, {
        id: inboxId,
        data: response.data.quick_replies,
      });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  getQuickReplyAction: async ({ commit }, inboxId) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    try {
      const response = await QuickReplyAPI.get(inboxId);
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_QUICK_REPLIES, response.data.quick_replies);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  addQuickReplyAction: async ({ commit, state: _state }, params) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    const temp = [..._state.quickReply];
    temp.push({
      name: params.name,
      contents: params.contents,
    });
    try {
      const response = await QuickReplyAPI.modify(params.inboxId, temp);
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_QUICK_REPLIES, response.data.quick_replies);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  editQuickReplyAction: async ({ commit, state: _state }, params) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    const temp = [..._state.quickReply];
    temp[params.index] = {
      name: params.name,
      contents: params.contents,
    };
    try {
      const response = await QuickReplyAPI.modify(params.inboxId, temp);
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_QUICK_REPLIES, response.data.quick_replies);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  deleteQuickReplyAction: async ({ commit, state: _state }, params) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    const temp = [..._state.quickReply];
    temp.splice(params.index, 1);
    try {
      const response = await QuickReplyAPI.modify(params.inboxId, temp);
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_QUICK_REPLIES, response.data.quick_replies);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      throw new Error(error);
    }
  },
  deleteAllQuickReplyAction: async ({ commit }, inboxId) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: true });
    try {
      await QuickReplyAPI.delete(inboxId);
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
      commit(types.default.SET_QUICK_REPLIES, []);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
      throw new Error(error);
    }
  },
  getWhatsappCredits: async ({ commit }, { inboxId, params = {} }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    try {
      if (!inboxId) throw Error('inbox id not found');
      const response = await InboxesAPI.getWhatsappCredits(inboxId, params);
      commit(types.default.SET_WHATSAPP_CREDIT, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.default.SET_INBOXES_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.default.SET_INBOXES]: MutationHelpers.set,
  [types.default.SET_INBOXES_ITEM]: MutationHelpers.setSingleRecord,
  [types.default.ADD_INBOXES]: MutationHelpers.create,
  [types.default.EDIT_INBOXES]: MutationHelpers.update,
  [types.default.DELETE_INBOXES]: MutationHelpers.destroy,
  [types.default.ACTIVATION_INSTAGRAM_COMMENT](_state, { id, data }) {
    const { records } = _state;
    const [inbox] = getSelectedInboxes({
      records,
      id,
    });
    if (!inbox) return;
    const inboxIndex = records.findIndex(m => m.id === id);
    if (inboxIndex !== -1) {
      let newInbox = [...records];
      newInbox[inboxIndex] = {
        ...newInbox[inboxIndex],
        ...{
          comment_reply: data,
        },
      };
      _state.records = newInbox;
    }
  },
  [types.default.GET_INSTAGRAM_COMMENT](_state, { id, data }) {
    const { records } = _state;
    const [inbox] = getSelectedInboxes({
      records,
      id,
    });
    if (!inbox) return;
    const inboxIndex = records.findIndex(m => m.id === id);
    if (inboxIndex !== -1) {
      let newInbox = [...records];
      newInbox[inboxIndex] = {
        ...newInbox[inboxIndex],
        ...{
          comment_reply: data,
        },
      };
      _state.records = newInbox;
    }
  },
  [types.default.SET_QUICK_REPLIES_CONVERSATION](_state, { id, data }) {
    const { records } = _state;
    const [inbox] = getSelectedInboxes({
      records,
      id,
    });
    if (!inbox) return;
    const inboxIndex = records.findIndex(m => m.id === id);
    if (inboxIndex !== -1) {
      let newInbox = [...records];
      newInbox[inboxIndex] = {
        ...newInbox[inboxIndex],
        ...{
          quick_reply: data,
        },
      };
      _state.records = newInbox;
    }
  },

  [types.default.SET_QUICK_REPLIES]($state, data) {
    $state.quickReply = data;
  },
  [types.default.ADD_QUICK_REPLIES]($state, data) {
    $state.quickReply.push(data);
  },
  [types.default.SET_WHATSAPP_CREDIT]($state, data) {
    $state.credits = {
      ...$state.credits,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
