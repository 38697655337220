<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div class="getting-started__warning">
    <img src="~dashboard/assets/icons/ic_warning.svg" />
    <div>
      <div
        v-if="getCurrentSubscription.days_left < 1"
        class="getting-started__title"
      >
        {{ $t('BANNER.PLAN_WARNING.EXPIRED') }}
      </div>
      <div v-else class="getting-started__title">
        <span> {{ $t('BANNER.PLAN_WARNING.TITLE') }} </span>
        <span> {{ expiredIn }} on </span>
        <span>
          {{
            getCurrentSubscription.expired_date | moment('MMM DD, YYYY')
          }}</span
        >
      </div>
      <div v-if="getCurrentSubscription.days_left < 1">
        {{ $t('BANNER.PLAN_WARNING.DESCRIPTION_EXPIRED') }}
      </div>
      <div v-else>
        {{ $t('BANNER.PLAN_WARNING.DESCRIPTION') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      getCurrentSubscription: 'subscription/getCurrentSubscription',
    }),
    expiredIn() {
      const dayLeft = this.getCurrentSubscription.days_left;
      if (dayLeft === 1) {
        return 'Tomorrow';
      }
      return `${dayLeft} days`;
    },
  },
};
</script>

<style lang="scss" scoped>
.getting {
  &-started {
    &__title {
      font-weight: bold;
      margin-bottom: 8px;
    }
    &__warning {
      display: flex;
      align-items: center;
      background: var(--yellow-02);
      border: 1px solid var(--yellow-07);
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 16px 18px;
      font-size: 14px;
      line-height: 24px;
      height: auto;
      img {
        margin-right: 20px;
      }
      a {
        text-decoration: underline;
      }
      b,
      strong {
        margin-left: 4px;
      }
    }
  }
}
</style>
