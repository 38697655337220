<template>
  <BillingCardPreference>
    <BillingContactFilled
      v-if="billingData && Object.keys(billingData).length > 0"
      :data="billingData"
      :editable="editable"
    />
    <BillingContactEmpty v-else />
  </BillingCardPreference>
</template>

<script>
import BillingContactFilled from './BillingContactFilled';
import BillingContactEmpty from './BillingContactEmpty';
import BillingCardPreference from '../BillingCardPreference';

export default {
  name: 'BillingContact',
  components: {
    BillingCardPreference,
    BillingContactEmpty,
    BillingContactFilled,
  },
  props: {
    billingData: {
      type: Object,
      default() {
        return {};
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
