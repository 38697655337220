<template>
  <div class="instagram-step__fifth">
    <div class="instagram-step__fifth-content">
      <h3>{{ $t('NEW_CHANNEL.INSTAGRAM.FIFTH_STEP.TITLE') }}</h3>
      <p>{{ $t('NEW_CHANNEL.INSTAGRAM.FIFTH_STEP.CONTENT') }}</p>
      <div class="facebook">
        <kata-button :disabled="isLoading" @click="startLogin()">
          {{ $t('NEW_CHANNEL.INSTAGRAM.FIFTH_STEP.CREATE') }}
        </kata-button>
      </div>
    </div>
    <img src="~dashboard/assets/images/hero/instagram-fifth-step.png" />
  </div>
</template>

<script>
/* eslint-disable no-multi-assign */
/* global FB */
import KataButton from 'dashboard/components/buttons/Button';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    KataButton,
  },
  mixins: [alertMixin],
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    this.initFB();
    this.loadFBsdk();
  },
  mounted() {
    this.initFB();
  },
  methods: {
    showError(error) {
      let errorMessage = this.$t('NEW_CHANNEL.API.ERROR_MESSAGE');
      if (error.response && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      this.showAlert(errorMessage);
    },
    async setAccessToken(token) {
      try {
        this.$emit('on-change-access-token', token, true);
        this.$emit('on-change-final-step');
      } catch (error) {
        this.showError(error);
      }
    },
    startLogin() {
      this.isLoading = true;
      this.tryFBlogin();
    },
    initFB() {
      if (window.fbSDKLoaded === undefined) {
        window.fbAsyncInit = () => {
          FB.init({
            appId: window.kataOmnichatConfig.fbAppId,
            xfbml: true,
            version: 'v8.0',
            status: true,
          });
          window.fbSDKLoaded = true;
          FB.AppEvents.logPageView();
        };
      }
    },
    loadFBsdk() {
      ((d, s, id) => {
        let js;
        const fjs = (js = d.getElementsByTagName(s)[0]);
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = '//connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    },
    tryFBlogin() {
      FB.login(
        response => {
          if (response.status === 'connected') {
            this.setAccessToken(response.authResponse.accessToken);
          } else if (response.status === 'not_authorized') {
            this.showError(response.status);
            this.isLoading = false;
          } else {
            this.isLoading = false;
          }
        },
        {
          scope:
            'pages_manage_metadata,instagram_basic,instagram_manage_messages,pages_read_engagement,pages_show_list',
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.instagram {
  &-step {
    &__fifth {
      display: flex;
      img {
        flex: 1;
        height: 100%;
      }
      &-content {
        flex: 1;
        text-align: left;
        h3 {
          display: block;
          font-size: 20px;
          font-weight: bold;
          color: var(--grey-09);
          margin-bottom: 12px;
          line-height: 28px;
        }
        p {
          margin: 24px 0;
          font-size: 14px;
          line-height: 24px;
          color: var(--grey-08);
        }
        .facebook {
          margin: 16px 0;
        }
      }
    }
  }
}
</style>
