<template>
  <div class="medium-12 column">
    <div class="row">
      <div class="small-12 align-self-top">
        <span class="onboarding-title">{{
          $t('NEW_ONBOARDING.TELL.YOURSELF')
        }}</span>
      </div>
    </div>
    <div class="row">
      <div class="small-12 form-group">
        <woot-input
          v-model.trim="form.user_full_name"
          :label="$t('NEW_ONBOARDING.YOURSELF.NAME.LABEL')"
          type="text"
          :class="{ error: $v.form.user_full_name.$error }"
          :placeholder="$t('NEW_ONBOARDING.YOURSELF.NAME.PLACEHOLDER')"
          :error="
            $v.form.user_full_name.$error
              ? $t('NEW_ONBOARDING.YOURSELF.NAME.ERROR')
              : ''
          "
          @blur="
            $v.form.user_full_name.$touch;
            $customGTM(
              'Event',
              'Onboarding - User Profile - Name',
              'Text',
              'User - Name',
            );
          "
        />
      </div>
      <div class="small-12 form-group">
        <label>{{ $t('NEW_ONBOARDING.YOURSELF.PHONE.LABEL') }}</label>
        <div class="row phone">
          <div class="small-2 form-group">
            <dropdown
              value="+62"
              :with-flag="true"
              :options="codes"
              :placeholder="
                $t('NEW_ONBOARDING.YOURSELF.PHONE_CODE.PLACEHOLDER')
              "
              @click="selectPhoneCode"
            />
          </div>
          <div class="small-10 form-group">
            <woot-input
              v-model.trim="form.phone_number"
              type="text"
              :class="{ error: $v.form.phone_number.$error }"
              :placeholder="$t('NEW_ONBOARDING.YOURSELF.PHONE.PLACEHOLDER')"
              :error="
                $v.form.phone_number.$error
                  ? $t('NEW_ONBOARDING.YOURSELF.PHONE.ERROR')
                  : ''
              "
              @blur="
                $v.form.phone_number.$touch;
                $customGTM(
                  'Event',
                  'Onboarding - User Profile - Phone',
                  'Text',
                  'User - Phone',
                );
              "
            />
            <span v-if="!$v.form.phone_number.$error" class="info">
              <inline-svg
                class="icon"
                :src="require('../../../assets/icons/ic_info_purple.svg')"
              />
              <span class="info-content">
                {{ $t('NEW_ONBOARDING.YOURSELF.PHONE.INFO') }}</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, numeric, minLength } from 'vuelidate/lib/validators';
import Dropdown from 'dashboard/components/kata/dropdown/Dropdown';
import InlineSvg from 'vue-inline-svg';
import codes from 'shared/constants/codes.json';

export default {
  components: {
    Dropdown,
    InlineSvg,
  },
  data() {
    return {
      form: {
        user_full_name: '',
        phone_number: '',
        phone_number_country_code: '',
      },
      codes: [],
    };
  },
  validations: {
    form: {
      user_full_name: {
        required,
      },
      phone_number: {
        required,
        numeric,
        minLength: minLength(8),
      },
    },
  },
  created() {
    this.codes = codes.map(item => ({
      label: item.dial_code,
      value: item.dial_code,
      flag: item.code,
    }));
  },
  methods: {
    validate() {
      const isValid = !this.$v.form.$invalid;
      this.$v.form.$touch();
      this.$emit('on-validate', { yourself: this.$data.form }, isValid);
      return isValid;
    },
    selectPhoneCode(code) {
      this.form.phone_number_country_code = code;
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  margin-top: 10px !important;
}
.phone {
  & > div {
    &:first-child {
      padding-right: 4px;
    }
    &:last-child {
      padding-left: 4px;
    }
  }
}
.info {
  display: flex;
  margin-top: -8px;
  margin-bottom: 16px;
  align-content: center;
}
.info-content {
  color: #6e4ee4;
  font-size: var(--font-size-mini);
  font-weight: 400;
  width: 100%;
}

.icon {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 8px;
}
</style>
