<template>
  <div class="small-8 medium-6 column">
    <form
      class="login-box medium-12 column align-self-middle"
      @submit.prevent="editPassword()"
    >
      <div class="column log-in-form">
        <woot-input
          v-model.trim="credentials.password"
          type="password"
          icon="ic_lock.svg"
          :class="{ error: $v.credentials.password.$error }"
          :placeholder="$t('SET_NEW_PASSWORD.PASSWORD.PLACEHOLDER')"
          :error="
            $v.credentials.password.$error
              ? $t('SET_NEW_PASSWORD.PASSWORD.ERROR')
              : ''
          "
          @blur="$v.credentials.password.$touch"
        />
        <woot-input
          v-model.trim="credentials.confirmPassword"
          type="password"
          icon="ic_lock.svg"
          :class="{ error: $v.credentials.confirmPassword.$error }"
          :placeholder="$t('SET_NEW_PASSWORD.CONFIRM_PASSWORD.PLACEHOLDER')"
          :error="
            $v.credentials.confirmPassword.$error
              ? $t('SET_NEW_PASSWORD.CONFIRM_PASSWORD.ERROR')
              : ''
          "
          @blur="$v.credentials.confirmPassword.$touch"
        />
        <woot-submit-button
          :disabled="
            $v.credentials.password.$invalid ||
              $v.credentials.confirmPassword.$invalid ||
              newPasswordAPI.showLoading
          "
          :button-text="$t('SET_NEW_PASSWORD.SUBMIT')"
          :loading="newPasswordAPI.showLoading"
          button-class="expanded"
        >
        </woot-submit-button>
      </div>
    </form>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import { mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import Auth from '../../../api/auth';

import WootSubmitButton from '../../../components/buttons/FormSubmitButton';
import { DEFAULT_REDIRECT_URL } from '../../../constants';

export default {
  components: {
    WootSubmitButton,
  },
  props: {
    resetPasswordToken: String,
    redirectUrl: String,
    config: String,
    setIsFinished: Function,
  },
  data() {
    return {
      // We need to initialize the component with any
      // properties that will be used in it
      credentials: {
        confirmPassword: '',
        password: '',
      },
      newPasswordAPI: {
        message: '',
        showLoading: false,
      },
      error: '',
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
  },
  mounted() {
    // If url opened without token
    // redirect to login
    if (!this.resetPasswordToken) {
      window.location = DEFAULT_REDIRECT_URL;
    }
  },
  validations: {
    credentials: {
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        minLength: minLength(6),
        isEqPassword(value) {
          if (value !== this.credentials.password) {
            return false;
          }
          return true;
        },
      },
    },
  },
  methods: {
    showAlert(message) {
      // Reset loading, current selected agent
      this.newPasswordAPI.showLoading = false;
      bus.$emit('newToastMessage', message);
    },
    editPassword() {
      this.newPasswordAPI.showLoading = true;
      const credentials = {
        confirmPassword: this.credentials.confirmPassword,
        password: this.credentials.password,
        resetPasswordToken: this.resetPasswordToken,
      };
      Auth.setNewPassword(credentials)
        .then(res => {
          if (res.status === 200) {
            this.$emit('set-is-finished', true);
            window.location = DEFAULT_REDIRECT_URL;
          }
        })
        .catch(error => {
          let errorMessage = this.$t('SET_NEW_PASSWORD.API.ERROR_MESSAGE');
          if (error?.data?.message) {
            errorMessage = error.data.message;
          }
          this.showAlert(errorMessage);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.description {
  margin-top: 24px;
}
</style>
